import React from 'react';
import { Face1, Face2, Face3 } from '../components/animations/instructions';
import { router } from '../utilis/router/router';

class Instructions extends React.Component {
    constructor() {
        super();
        const first = {id:0, component: <Face1 />, text: "",active:true }
        this.state = {
            steps: [first
                , {is:1, component: <Face2 />, text: "" }],
            currect: first
        };
        this.on = {
           
            click:(item)=>{
                const steps = [...this.state.steps];
                steps.forEach(el=>{
                    el.active = el.id === item.id ? true : false;
                })
                this.setState({currect:item,steps:steps})
            }
        }
    }
    /* border-orange-500 text-orange-500 */

    render() {
        return (<section class="text-gray-700 body-font">
            <div class="container px-5 py-5 mx-auto flex flex-wrap flex-col">
                <div class="flex mx-auto flex-wrap mb-20">
                    {
                        this.state.steps.map((item, index) => {
                            return <span onClick={()=>{this.on.click(item)}} key={index} class={(item.active ? "border-orange-500 text-orange-500 " : "") + "sm:px-6 py-3 w-1/2 sm:w-auto justify-center sm:justify-start border-b-2 title-font font-medium bg-gray-100 inline-flex items-center leading-none  tracking-wider rounded-t"}>
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5 mr-3" viewBox="0 0 24 24">
                                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                </svg>STEP {index + 1}
                            </span>
                        })
                    }

                </div>
                <div class="xl:w-1/4 lg:w-1/3 md:w-1/2 w-2/3 block mx-auto mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600">
                    {this.state.currect.component}

                </div>
                <div class="flex flex-col text-center w-full">
                    <h1 class="text-xl font-medium title-font mb-4 text-gray-900">{this.state.currect.text}
                    </h1>
                </div>
            </div>
            <div class="z-10 xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
            {
                this.state.currect.id === 0 ? <a  onClick={()=>{router.dispatch('instructions')}} class="mx-auto flashing relative z-10 font-semibold text-lg hover:bg-orange-400 bg-orange-600  text-white py-3 px-12 rounded-full">Next</a> : 
                <a  onClick={()=>{router.dispatch('instructions')}} class="mx-auto flashing relative z-10 font-semibold text-lg hover:bg-orange-400 bg-orange-600  text-white py-3 px-12 rounded-full">APPLY</a>
            }
            
            </div>

        </section>);
    }


}

export default Instructions;
