import React from 'react';
import Buildings from '../components/buildings';
import { Face1, Face2, Face3 } from '../components/animations/instructions';
import Privacy from './privacy.visitor';
import UploadImage from './uploadImage';
import Slide from 'react-reveal/Slide';
import Welcome from './Welcome';
import { GetUrlVars, ResetUrl, isLocalhost } from '../services/data.service';
import { Full as Loader } from '../components/loaders/full';
import { Config } from '../app.config';

class Visitor extends React.Component {
    constructor() {
        super();
        this.on = {
            click: (item, isDirty) => {
                if (isDirty && !item.dirty) return;
                const steps = [...this.state.steps];
                steps.forEach(el => {
                    el.active = el.id === item.id ? true : false;
                    if (el.active) {
                        el.dirty = true;
                    }
                })
                this.setState({ currect: item, steps: steps }, () => {
                    const element = document.getElementById('stepper-facelabs');
                    if (element) {
                        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }
                })
            },
            sendForm: () => {
                const state = this.state;
                const isValid = [state.hash, state.email, state.code, state.photo].filter(v => {
                    return !v;
                });
                if (isValid.length === 0) {
                    this.setState({
                        loader: true
                    })
                    const data = {
                        hash: state.hash,
                        email: state.email,
                        code: state.code,
                        photo: state.photo
                    }
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    fetch(Config.routes.register, {
                        method: 'post',
                        headers: myHeaders,
                        body: JSON.stringify(data)
                    }).then((res) => {
                        this.setState({
                            loader: false,

                        })
                        if (res.status === 200) {
                            this.on.click('5');
                            this.setState({
                                currect: thanks,
                                msg:""
                            })
                        }else{
                            return res.json();
                        }
                       
                    }, (result) => {
                        if (result && typeof result.msg === "string") {
                            this.setState({
                                msg: result.msg
                            })
                        }

                    }).then(result => {
                        if (result && typeof result.msg === "string") {
                            this.setState({
                                msg: result.msg
                            })
                        }
                    })
                }
            }
        }
        const welcome = {
            id: 0, component: <Welcome />, text: "", btnTxt: "LET'S GO", onClick: () => {
                this.on.click(this.state.steps[1])
            }
        }
        const thanks = {
            id: 5, component: <Face3 />, text: <div className="flex flex-col text-center w-full mt-20 "><h1 style={{ maxHeight: '70px' }} className="sm:text-3xl text-2xl font-medium title-font text-gray-900">Thank you!</h1><h2 class="text-xs text-orange-500 tracking-widest font-medium title-font"><a style={{ color: "inherit" }} href="https://proptech.ai">Learn more at www.proptech.ai</a></h2></div>
        }
        const privacy = {
            id: 1, dirty: true, component: <Privacy />, btnTxt: "I AGREE", onClick: () => {
                this.on.click(this.state.steps[2])
            }, active: true
        }
        this.state = {
            steps: [welcome, privacy, {
                id: 2, component: <Face1 />, btnTxt: "NEXT", onClick: () => {
                    this.on.click(this.state.steps[3])
                }
            }
                , {
                    id: 3, component: <Face2 />, btnTxt: "NEXT", onClick: () => {
                        this.on.click(this.state.steps[4])
                    }
                }
                , {
                    id: 4, component: <UploadImage onChange={(photo) => {
                        this.setState({
                            photo: photo
                        })
                    }} onSubmit={this.on.sendForm} />, onClick: () => { }
                }
            ],
            currect: welcome
        };

    }

    render() {
        return (
            <main class="bg-white font-open-sans ">
                <section class="text-gray-700 body-font">
                    {/* <PlianHeader /> */}
                    <div class="text-center mx-auto px-6 sm:px-12 flex flex-col-reverse sm:flex-row relative">

                        <div style={{ minHeight: '90vh' }} class="sm:w-12/12 xl:w-12/12 flex flex-col items-center sm:items-center sm:text-center mx-auto  sm:mt-0 relative z-10  mb-16 sm:mb-0">
                            {
                                this.state.currect.text ? <div>{this.state.currect.text}</div> : null
                            }
                            <div className="mt-5">
                                {this.state.currect.component}
                            </div>
                            <Slide bottom>
                                <div>
                                    {
                                        this.state.currect.btnTxt ? <button onClick={this.state.currect.onClick} class=" flashing sm:w-12/12 ml-4 block text-white bg-orange-500 border-0 py-2 px-6 focus:outline-none hover:bg-orange-300 rounded text-lg">{this.state.currect.btnTxt}</button> : null
                                    }
                                </div>
                            </Slide>
                            {
                                this.state.msg ? <h1 style={{color:'red'}}  className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Error:{this.state.msg}</h1>
                                    : null
                            }
                        </div>
                        <Buildings />
                    </div>
                </section>
                {
                    this.state.loader ? <Loader /> : null
                }

            </main>);
    }

    componentDidMount() {
        const params = GetUrlVars();
        let isValid = true;
        const _params = {
            hash: params.hash,
            code: params.code,
            email: params.email
        }
        if (params.contact_mail) {
            window.contact_mail = params.contact_mail;
        }
        if (params.address) {
            window.address = params.address;
        }
        for (const key in _params) {
            if (_params.hasOwnProperty(key)) {
                const element = _params[key];
                if (!element) {
                    isValid = false;
                }
            }
        }
        if (isValid) {
            this.setState({
                hash: _params.hash,
                code: _params.code,
                email: _params.email
            })
        }

        if (!isLocalhost())
            ResetUrl('/visitor');
    }
}

export default Visitor;
