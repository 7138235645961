import React from "react";
import Styled from './full.module.css';
export const Full = (props) => {
    return <div className={Styled.Cover}>
        <div className={Styled.Loader}>
            <div className={Styled.Text}>
            Verifying photo
            </div>
        </div>
    </div>
}