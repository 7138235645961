import React from 'react';
export const ResetUrl = (url) => {
    if (typeof window === "object")
        window.history.replaceState({}, document.title, url);
}

export const hasJsonContent = (headers) => {
    //TODO: comment
    if (headers && typeof headers.get === "function") {
        const contentType = headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return true;
        }
    }
    return false;
}

export const GetUrlVars = () => {
    var vars = {};
    if (typeof window === "object") {
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
            vars[key] = value;
        });
    }
    return vars;
}
export const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    return (false)
}


export const SendForm = async (data) => {
    fetch("https://enrollment.proptech.ai/visitor/post_data_for_meeting/", {
        method: 'POST',
        headers: {
        },
        body: data
    })
        .then(function (data) {
            console.log('Request success: ', data);
        })
        .catch(function (error) {
            console.log('Request failure: ', error);
        });
}

export const CameraIcon = ()=>{
    return <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M15 3H7c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1-.55 0-1 .45-1 1v9c0 .55.45 1 1 1h14c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM6 5H2V4h4v1zm4.5 7C8.56 12 7 10.44 7 8.5S8.56 5 10.5 5 14 6.56 14 8.5 12.44 12 10.5 12zM13 8.5c0 1.38-1.13 2.5-2.5 2.5S8 9.87 8 8.5 9.13 6 10.5 6 13 7.13 13 8.5z"></path></svg>
}
export const isLocalhost = () =>(window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")