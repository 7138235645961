import React, { useState, useEffect } from "react";
import Style from '../css/privacyStyle/privacy.module.css';
import { GetUrlVars, ResetUrl } from '../services/data.service';

//https://enrollment.proptech.ai/privacy?site=Facelabs&address=FaceLabs%20Offices
function Privacy() {
    const [site, setSite] = useState("");
    const [address, setAddress] = useState("");
    const [contact_mail, setContantEmail] = useState("");

    useEffect(() => {
        const params = GetUrlVars();
        ResetUrl('/register_privacy');
        if (params.contact_mail) {
            setContantEmail(params.contact_mail)
        }
        if (params.site) {
            setSite(decodeURIComponent(params.site))
        }
        if (params.address) {
            setAddress(decodeURIComponent(params.address))
        }
        return () => {
            // Clean up the subscription
        };
    });

    return (
        <>
           <h2> Data Privacy Statement - {site}
            </h2>
                <ul className={Style.Ul}>
                    <li className={Style.Li}>
                        Your privacy is important to us. <br /> Please note the following about the data privacy policy applied to the {address} building access system which uses facial recognition.
                    </li>
                    <li className={Style.Li}>
                        Preregistration in is on opt-in basis and for convenience of entering the building only. <br /> Those who do not register can continue using other means of access.                     </li>
                    <li className={Style.Li}>
                        Registration process requires users to provide privacy consent.<br />
The personal data used consists of name, email address, card number and selfie photograph.                     </li>
                    <li className={Style.Li}>
                        The personal data is used exclusively to grant access to the building. <br /> It will not be used for any other purposes, resold or shared otherwise with any 3rd parties.<br />
The personal data is permanently deleted within three months from the termination of the user’s building access control card.                  </li>
                    <li className={Style.Li}>
                        To opt-out, users should email to the building management {contact_mail}, who will perform the permanent record deletion within 5 business days. <br />
Deleted records cannot be recovered.                   </li>
                    <li className={Style.Li}>
                        The personal data is securely stored on a server, which is physically located inside the building.                     </li>
                </ul>
        </>
    );
}

export default Privacy;
