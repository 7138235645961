import React from 'react';
import { router } from '../utilis/router/router';
import Visitor from './visitor';
import Privacy from './register_privacy';
import Instructions from './instructions';
import Register from './register';

class Master extends React.Component {
  constructor() {
    super();
    router.routes = {
      visitor: { component: <Visitor /> },
      register: { component: <Register /> },
      register_privacy: { component: <Privacy /> },
      instructions: { component: <Instructions /> },

    }
  }

  render() {
    return <div id="master-app--proptech"></div>;
  }
  componentDidMount() {
    router.connectWith("master-app--proptech");
    const cacheName = '__version__';
    const version = "v1"
    localStorage.setItem(cacheName, version);
    if ('serviceWorker' in navigator) {
      caches.keys().then(function (cacheNames) {
        cacheNames.forEach(function (cacheName) {
          caches.delete(cacheName);
        });
      });
    }
  }
}

export default Master;
