import React from 'react';
import { GetUrlVars } from '../services/data.service';

class Welcome extends React.Component {
  constructor() {
    super();
    this.state = {

    }
  }

  render() {
    return (<section class="z-10 text-gray-700 body-font">
      <div class="relative z-10 container px-5 mt-4 mx-auto">
        <div class="z-10 xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <h2 class="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Welcome!  &rlm;</h2>
          <div class="w-full mb-4"><div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div></div>
          <p class="leading-relaxed text-lg text-left">
          You have been invited to register for hands-free access control system operated at {this.state.address}. Please follow these guidelines to upload a photo of yourself.
          </p>

        </div>
      </div>
      <br/>
      <br/>
    </section>);
  }
componentDidMount(){
  const params = GetUrlVars();
  if (params.address) {
    this.setState({
      address: decodeURIComponent(params.address)
    })
  }
}

}

export default Welcome;
