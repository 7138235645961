import React from 'react';
import './App.css';
import Master from './pages/master';

function App() {
  return (
    <Master/>
  );
}

export default App;
