import React from 'react';
import Compressor from '../imagesHelper/compressor';

class UploadImage extends React.Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            isReady: false,
            src: '',
            uploaded:false
        };
        this.on = {
            change: (event) => {
                this.setState({
                    isLoading: true,
                });
                const file = event.target.files[0];
                if (file) { 
                    const self= this;
                    new Compressor(file,{
                     quality:1,
                     maxWidth:500,
                     maxHeight:500,
                     mineType:'auto',
                     convertSize:5000000,
                     success (result){
                         const reader = new FileReader();
                         reader.readAsDataURL(result);
                         reader.onload = (e) => {
                            var file = e.target.result.split(',')[1];
                            self.setState({ isReady: true });
                            self.setState({
                                uploaded:true
                               },()=>{
                                self.props.onChange(file, e.target.result);
                               })
                         };
                         reader.onerror = error => {
                             console.log(error)
                         };
                     }
                    })
                }
            },
            click: () => {
                var upload = document.getElementById('upload-photo');
                if (upload)
                    upload.click();
            }
        }
    }

    render() {
        const state = this.state;
        return <>
            <button className={(state.uploaded ? " bg-gray-200 hover:bg-gray-400 text-black" : "bg-orange-500 hover:bg-orange-600 text-white")+ " inline-flex   border-0 py-2 px-6 focus:outline-none  rounded text-lg"}  onClick={this.on.click} variant="contained">{state.isReady ? 'RETAKE PHOTO' : 'UPLOAD PHOTO'}</button>
            {
                state.src ? <img style={{ margin: 'auto',width:'auto',height:'200px' }}  src={state.src} alt="Preview" /> : null
            }
            <input onChange={this.on.change} hidden id="upload-photo" accept="image/*" type="file"></input>
        </>;
    }

    componentDidMount() {

    }
}

export default UploadImage;
