import React from 'react';
import Upload from '../utilis/imagesHelper/uploader';
import Avatar from '../images/avatar.png';
class UploadImage extends React.Component {
    constructor() {
        super();
        this.state = {
            src: Avatar
        };
        this.on = {
            change: (file,src) => {
                this.setState({
                    src: src,
                    uploaded:true
                },()=>{
                    if(typeof this.props.onChange === "function"){
                        this.props.onChange(file)
                    }
                })
            }
        }
    }

    render() {
        return <section class="text-gray-700 body-font">
            <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div style={{ background: '#fff' }} class="lg:max-w-lg lg:w-full md:w-1/2 w-4/6 mb-10 md:mb-0">
                    <img style={{maxWidth:'100%'}} class=" object-cover object-center rounded" alt="hero" src={this.state.src} />
                </div>
                <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
                    
                    <div class="flex justify-center">
                        <Upload onChange={this.on.change} />
                        {/* <button class="inline-flex text-white bg-orange-500 border-0 py-2 px-6 focus:outline-none hover:bg-orange-600 rounded text-lg"></button> */}
                        
                        {
                            this.state.uploaded ? <button onClick={this.props.onSubmit} class="sm:w-12/12 ml-4 block text-white bg-orange-500 border-0 py-2 px-6 focus:outline-none hover:bg-orange-300 rounded text-lg">SUBMIT</button> : null
                        }
                    </div>
                </div>
            </div>
        </section>;
    }
}

export default UploadImage;
