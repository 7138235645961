import React from 'react';
import { GetUrlVars } from '../services/data.service';

class Privacy extends React.Component {
  constructor() {
    super();
    this.state = {

    }
  }

  render() {
    return (<section class="z-10 text-gray-700 body-font">
      <div class="relative z-10 container px-5 mt-4 mx-auto">
        <div class="z-10 xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <h2 class="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Privacy Consent - {this.state.address} </h2>
          <div class="w-full mb-4"><div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div></div>
          <p class="leading-relaxed text-sm text-left">
            By providing my name, email address and facial photograph I give my explicit and voluntary consent
            to use this data strictly for the purposes of expediting my access to the building at {this.state.address} using facial recognition system.<br />I understand that the data will not be used for any other purposes, 
          resold or shared with any 3rd parties.

          <br />The data will be permanently deleted upon termination of my access control credentials.

           <br />I can also request data deletion at any time by sending a request to <a class="bold" href={`mailto:${this.state.contact_mail}`}>
           {this.state.contact_mail}
            </a>
            <br />
            <br />
          </p>

        </div>
      </div>
    </section>);
  }
  componentDidMount() {
    const params = GetUrlVars();
    const address = params.address || window.address;
    this.setState({
      address: decodeURIComponent(address)
    })
    const email =  params.contact_mail || window.contact_mail;
      this.setState({
        contact_mail: decodeURIComponent(email)
      })
    
  }

}

export default Privacy;
