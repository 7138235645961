import React from 'react';
import { GetUrlVars } from '../services/data.service';

class Privacy extends React.Component {
  constructor() {
    super();
    this.state = {
      address:"",
      contact_mail:""
    }
  }
  render() {
    return (<section class="z-10 text-gray-700 body-font">
      <div class="relative z-10 container px-5 mt-4 mx-auto">
        <div class="z-10 xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
          <h2 class="w-full my-2 text-2xl font-bold leading-tight text-center text-gray-800">Privacy Consent - {this.state.address} </h2>
          <div class="w-full mb-4"><div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div></div>
          <p class="leading-relaxed text-sm text-left">
            By using this site and by providing my facial photograph I give my explicit and voluntary
            consent to use this data strictly for the purposes of expediting my access to the building listed in the invitation I received.
            <br />
            I understand that the data will not be used for any other purposes, resold or shared with any
             3rd parties, and that it will be available to the building face-recognition
              based access control system only located at {this.state.address} during the date(s) of my anticipated visit.
               <br /> The data will be permanently deleted at midnight of the last date of my visit.
            I can request deletion of the data prior to the date of my visit by sending a request to <a class="bold" href={`mailto:${this.state.contact_mail}`}>
               {this.state.contact_mail}
            </a>
            <br />
            <br />
          </p>

        </div>
      </div>
    </section>);
  }

  componentDidMount() {

    const params = GetUrlVars();
    const address = params.address || window.address;
    this.setState({
      address: decodeURIComponent(address)
    })
    const email =  params.contact_mail || window.contact_mail;
      this.setState({
        contact_mail: decodeURIComponent(email)
      })
    
  }
}

export default Privacy;
