import EventEmitter from '../eventEmitter/eventEmitter';
import ReactDOM from 'react-dom';


class Router {
    EM = new EventEmitter();
    route = "";
    routes = {}
    onChange = [];
    constructor() {
        window.onpopstate = (event) => {
            this.EM.emit('routing');
        };
        this.EM.on('routing', () => {
            const route = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
            if (route in this.routes) {
                this.loadModule(this.routes[route].component);
            } else {
                for (const key in this.routes) {
                    if (this.routes.hasOwnProperty(key)) {
                        const route = this.routes[key];
                        if ('default' in route) {
                            this.loadModule(route.component);
                        }
                    }
                }
            }
        });
    }
    connectWith(container) {
        if(typeof container === "string"){
            this.container = document.getElementById(container);
        }
        if(!this.container){
            console.error('error')
            return;
        }
        this.EM.emit('routing');
    }
    onChangeRoute(method) {
        if (typeof method === "function") {
        this.onChange.push(method);
        }
    }
    loadModule(component) {
        if (this.route === component) {
            return
        }
        if (this.container !== null) {
            ReactDOM.unmountComponentAtNode(this.container);
            ReactDOM.render(component, this.container);
            this.route = component;
            this.onChange.forEach(func=>{
                func();
            })
        }
    }

    dispatch(route, data, quaryString) {
        window.history.pushState(data || {
            page: route
        }, route, route + (typeof quaryString !== 'undefined' ? `?${quaryString}` : ''));
        this.EM.emit('routing');

    }
}

export const router = new Router();