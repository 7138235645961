
import React from "react";


export const Face1 = () =>{
    setTimeout(() => {
        const faceAnim1 = new window.window.TimelineMax({repeat:-1, repeatDelay:3})
.to('#hand', 1, {transformOrigin:'100% 60%', rotation:11.2}, 'hand')
.to('#hand >*:nth-child(2)', 1, {transformOrigin:'100% 80%', rotation:-13.5}, 'hand')
.to('#hand >*:nth-child(1)', 1, {transformOrigin:'100% 60%', rotation:-1}, 'hand')
for (let i = 1; i < 13; i++) {
    faceAnim1.from(`#lines >*:nth-child(${i})`, 0.06, {opacity:0})
}
faceAnim1
.from('#text >*:nth-child(2)', 0.3, {opacity:0, ease:window.Power0.easeNone}, 'text+=0.2')
.from('#text >*:nth-child(1)', 0.3, {opacity:0, ease:window.Power0.easeNone}, 'text')
    }, 800);
return <div>
   <svg style={{minHeight:'450px',maxWidth:'100%',height:'450px',marginBottom:'1rem'}} version="1.1" id="face1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 257.79 284.33" enableBackground="new 0 0 257.79 284.33" xmlSpace="preserve">
<g id="alert2">
	<g>
		<defs>
			<path id="SVGID_1_" d="M184.81-14.04c22.06,0,49,26.34,60.2,58.78s-10.94,52.65-33,52.65s-35.76-20.21-46.93-52.69
				S162.75-14.04,184.81-14.04z"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlinkHref="#SVGID_1_"  overflow="visible"/>
		</clipPath>
		<g clip-path="url(#SVGID_2_)">
			<g>
				<g>
					<path fill="#2B4057" d="M215.58,126.01c-0.25-2.99-0.82-5.95-1.68-8.82c0-0.18,0.29-2.63,0.77-6.12l-3-27.28h-3.86h-6.53
						c0,0.32,3.53,63.32,3.53,63.32l0.26,0.19c0.91,0.62,1.93,1.07,3,1.34c2.45,0.57,5.02-0.06,6.93-1.69
						C215,146.95,216,131.22,215.58,126.01z"/>
					<path fill="#456584" d="M226.94,124.53c-0.13-0.52,1.4-7.27,1.43-13.81c-0.08-8.63-0.75-17.24-2-25.78l-5.61-0.48l-2.21-0.76
						l-6.86,0.06l0.41,3.76l-4.52-0.77c0,0,0.52,6.77,1.46,9.11s3.36,1.38,4.56,5.37l1.07,9.81l5,45.75
						c0.22,0.24,0.47,0.44,0.75,0.61c1.03,0.54,2.19,0.77,3.35,0.68c1.45-0.08,2.89-0.34,4.28-0.79l0.53-0.18
						C230.58,128.7,227.05,125.05,226.94,124.53z"/>
					<path fill="#5B230D" d="M223.77,158.11c-1.16,0.09-2.32-0.14-3.35-0.68l0,0l-0.85,2.65l-0.39,1.2c0,0-1.46,4.14-0.81,6.9
						c0.16,1.02,0.86,1.88,1.83,2.24c2.61,0.88,5.29-0.93,6.72-2.15c0.64-0.58,1.01-1.41,1-2.28l0.1-8.7
						C226.64,157.74,225.22,158.02,223.77,158.11z"/>
					<path fill="#5B230D" d="M215.06,147.03c-1.91,1.63-4.48,2.26-6.93,1.69c-1.07-0.27-2.09-0.72-3-1.34l0,0
						c-0.69,2.08-3.08,4.29-5.06,5.74c-1.51,1.1-3.73,2.4-4.41,3.5c-0.2,0.27-0.25,0.62-0.13,0.94c0.58,1.22,2.89,1.86,7.43,0.85
						c2.57-0.49,4.89-1.85,6.58-3.85c1.33,0.75,4.86-0.64,5.56-1.76S215.06,147.03,215.06,147.03z"/>
				</g>
				<path clipRule="evenodd" clipRule="evenodd" fill="#E3A87F" d="M220.2,32.79c0,2.53-0.27,5.12-0.27,7.61
					c0,2-2.14,2.81-3.23,3.37c-4.47,2.6-6.18-0.71-5.95-2.1c0.21-1.22,0.52-2.42,0.92-3.59c0.43-1.23,2.81-1,2.81-2.31L220.2,32.79z
					"/>
				<g>
					<path fill="#354F6B" d="M202.17,40.79c-1.32,3.92-1.72,9.11-1.6,17.87c0.17,10.74,0.33,33.41,0.33,33.41l4,2.73
						c0,0,0-3.26,0-7.63c0-5.77,0-13.47,0.12-18.17l0,0c-1-7.7-0.64-24.74-0.64-24.74l2.08-1.17L205,41.7l0,0
						c1.11-1.64,2.67-2.92,4.49-3.69l2.2-0.91c-1-0.46-5.73-2.53-8,0.57c-0.32,0.54-0.61,1.09-0.87,1.66
						c-0.03,0.04-0.05,0.09-0.07,0.14c-0.04,0.12-0.09,0.24-0.15,0.35C202.42,40.11,202.29,40.44,202.17,40.79z"/>
					<path fill="#456584" d="M204.35,44.26c0,0-0.35,17.04,0.65,24.74l0,0c0-0.71,0-1.36,0.07-1.92c0.32-7,0.53-11.91,2.21-17.69
						c0.68-2.3,1.53-4.73,2.32-6.84c1-2.7,1.91-4.84,2.15-5.43l-0.14-0.07l-2.2,0.91c-1.8,0.79-3.33,2.09-4.41,3.74l0,0l1.48,1.44
						L204.35,44.26z"/>
					<path fill="#FADCEB" d="M204.91,83.29v3.76c3.04,0.94,6.23,1.35,9.41,1.22c-0.1-1.21-0.17-2.4-0.22-3.57
						c-0.08-1.63-0.1-3.23-0.09-4.8c-0.64-4.67-0.83-9.39-0.57-14.1c0.45-6.57,2.34-12.97,5.56-18.72c0.97-1.88,1.62-3.91,1.93-6
						v0.08l0.16-0.91c-1.22,1.56-5.61,3.87-8.33,3.24c-3.35-0.78-3-0.9-3-0.9l0,0c-0.79,2.11-1.65,4.54-2.32,6.84
						c-1.68,5.78-1.89,10.68-2.21,17.69c0,0.56,0,1.21-0.07,1.92C204.92,72.7,204.9,78.27,204.91,83.29z"/>
					<path fill="#456584" d="M220.88,41.04c-0.3,2.09-0.96,4.12-1.93,6c-3.2,5.76-5.08,12.15-5.51,18.72
						c-0.27,4.72-0.09,9.45,0.56,14.13c0.03-3.41,0.29-6.81,0.78-10.19c1-5.86,3.47-11.36,7.19-16l-1.9-3.21l2.93,1.67
						c0,0,1.85-7.91,1.26-10.16c-0.11-0.41-0.29-0.79-0.53-1.14c-0.66-0.91-1.58-1.61-2.63-2C221.07,39.59,221,40.32,220.88,41.04z"
						/>
					<path fill="#354F6B" d="M214.68,92.01c0.28,2.39,0.63,4.89,1.06,7.53c3,0.84,6.17,0.9,9.2,0.16c4.65-1.28,4.16-2,4.16-2
						S227,85.1,226.6,82s9.14-30.64,6.36-34.55s-9.2-6.63-9.2-6.63l0,0c0.24,0.35,0.42,0.73,0.53,1.14
						c0.59,2.25-1.26,10.16-1.26,10.16l-2.91-1.77L222,53.6c-3.72,4.64-6.19,10.14-7.19,16c-0.5,3.41-0.77,6.85-0.81,10.29
						C213.98,83.94,214.21,87.99,214.68,92.01z"/>
					<g>
						<path fill="#F0B387" d="M229.36,93.92c-0.62,0.45-1.18,0.98-1.67,1.56c-0.87,1-2.11,5.62-0.55,6
							c0.64,0.16,1.45-2.91,1.72-2.85c1.12,0.27-1.54,6.92-0.39,7.3s4.66-3.52,5.42-4.72s1.74-8.12,1.74-8.12L229.36,93.92z"/>
						<path fill="#456584" d="M234.11,49.07c-0.86-2.53-3.62-3.88-6.15-3.01c-1.11,0.38-2.05,1.15-2.63,2.17
							c-0.92,1.77-1.1,3.84-0.5,5.74c2,7.41,5.35,17.14,5.27,18.74s-1,18.56-1.24,20.31c-0.26,2,6.23,1.84,7.18,0.82
							s2-20.55,2-24.11C238,67.33,236.28,55.28,234.11,49.07z"/>
					</g>
					<g>
						<path fill="#FAEDF4" d="M220.35,37.92c-0.03-0.1-0.06-0.21-0.08-0.32l0.35,0.08l0.46,1.21c0,0,0.28,4.88-6.31,7.84L213,42.52
							C213,42.52,218,41,220.35,37.92z"/>
						<path fill="#FAEDF4" d="M210.77,41.95l-1.84,2.38c0.1-1.16,0.35-2.31,0.72-3.41c0.49-1,0.84-1.19,0.84-1.19L210.77,41.95z"/>
						<path fill="#F298C0" d="M213,42.52l-2.2-0.57l-0.73,0.94l0.47,0.87c-2.9,10.89-4.51,22.08-4.8,33.34l1.76,2.77l2.69-2.62
							c0,0-0.47-10.38,0.08-18.42s1.68-14.69,1.68-14.69l1.55-0.44L213,42.52z"/>
					</g>
					<path fill="#0C163F" d="M204.91,83.29v3.76c0.4,0.12,0.84,0.24,1.31,0.36c1.72,0.44,3.48,0.73,5.26,0.84
						c0.95,0.07,1.89,0.07,2.84,0l0,0c0-0.35-0.05-0.7-0.08-1c0-0.51-0.03-1.01-0.09-1.5l-0.06-1l0,0c-0.87,0.06-1.75,0.06-2.62,0
						c-1.78-0.13-3.54-0.44-5.26-0.94C205.75,83.58,205.32,83.44,204.91,83.29z"/>
					<path fill="#E3A000" d="M206.22,83.7v3.7c1.72,0.44,3.48,0.73,5.26,0.84V84.7C209.7,84.55,207.93,84.22,206.22,83.7z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<path clipRule="evenodd" clipRule="evenodd" fill="#FF1D25" d="M209.49,100.37c-26.68,0-48.3-21.62-48.3-48.3
			c0-26.68,21.62-48.3,48.3-48.3s48.3,21.62,48.3,48.3C257.76,78.73,236.15,100.34,209.49,100.37z M209.49,7.86
			c-24.42,0-44.21,19.79-44.21,44.21s19.79,44.21,44.21,44.21s44.21-19.79,44.21-44.21v0C253.68,27.66,233.9,7.88,209.49,7.86z"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#FF1D25" points="168.97,75.05 241.38,20.75 244.68,24.05 171.49,78.19 		
			"/>
	</g>
	<path clipRule="evenodd" clipRule="evenodd" fill="#456584" d="M203.21,38.79c2.74-4,1.32-1.79-5.08-4.38
		c-3.54-0.79-6.96-2-10.21-3.61C184.5,27.52,173,20.47,167.2,20.28c-2.23-0.08-4.64,2.06-4,4c0.5,1.15,16.71,12.9,20,15l17.8,6.31
		l0.81-3.62C202.07,40.83,202.55,39.75,203.21,38.79z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M208.87,20.27c-0.45,1.27-1.06,2.49-1.81,3.61
		c-1,1.29,0.73,2.26-1.21,3.91c-0.56,1.88-0.81,3.83-0.74,5.79c0,1.23-0.07,3,0.75,4c2,2.45,5.46,0.74,8.29,0.6s3.44-3.23,4.86-5.11
		c2.54-3.38,3.06-9-0.65-11.91c-1.95-1.5-4.32-2.34-6.78-2.4C210.83,18.79,209.2,18.91,208.87,20.27z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#B08080" d="M215.68,28.48c0.57,0.35,0.85,1.03,0.7,1.68
		c0,0.84,0.5,1.06-0.11,2.61c-0.08,0.39,3.47,0.31,2.67,3c4-1.89,7.33-9.93,5.56-12.69c-4.79-1.05-6.16-1.07-8.72,1
		C215,25.31,214.89,27.77,215.68,28.48z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M218.14,16.55C211.06,15.78,206,18.3,208,21.48
		c0.68,1.09,6.18-2.15,7,2.92c2-1.69,4.24-2.47,9.52-1.32C228.14,18.55,220,16.75,218.14,16.55z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M204.93,31.83c0.36-0.51,1.07-0.64,1.59-0.3
		c1.48,1.04,2.62,2.49,3.28,4.17c0.58,2.39,3.44,1.48,4.85,0.76c0.54-0.11,1.78,0.16,1.69,0.52c-2,2.8-7.19,2.69-10.64,1.72
		C203.66,38.13,203.8,32.9,204.93,31.83z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M206.68,32.51c0.64,1,0.74,1.21,1.27,2s-0.23,1.18-0.74,0.46
		c-0.07-0.1-0.56-1.69-0.83-1.54s-0.41,0.24-0.74-0.08C205,32.7,205.59,32.34,206.68,32.51z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M219.68,33.82c-0.94,1.21-2.13,1.86-2.66,1.45
		c-0.7-0.94-0.65-2.25,0.14-3.12c0.93-1.21,2.72-1.68,3.25-1.27S220.62,32.61,219.68,33.82z"/>
	<g>
		<path clipRule="evenodd" clipRule="evenodd" fill="#BEBFC0" d="M164.81,9.01l5.28,4.44c0.11,0.08,0.16,0.21,0.13,0.34l-2,10.67
			c0,0.06-0.05,0.12-0.11,0.14c-0.06,0.03-0.12,0.03-0.18,0l-5.28-4.33c-0.1-0.08-0.15-0.22-0.13-0.35l1.74-10.8l0.38-0.13
			C164.71,8.93,164.76,8.96,164.81,9.01z"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#D1D3D4" d="M169.31,12.78l0.79,0.66c0.1,0.09,0.15,0.22,0.12,0.35l-2,10.67
			c-0.01,0.06-0.06,0.12-0.12,0.14l-0.36,0.13l-0.61-0.72c-0.09-0.08-0.13-0.19-0.13-0.31l2-10.79c0-0.06,0.05-0.12,0.11-0.14
			C169.17,12.73,169.25,12.74,169.31,12.78z"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#CECECE" points="164.43,9.11 169.74,13.57 170.1,13.45 164.79,8.99 		"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#909090" d="M164.46,9.14l5.28,4.44c0.1,0.08,0.14,0.21,0.12,0.34l-2,10.67
			c-0.01,0.06-0.06,0.12-0.12,0.14c-0.06,0.03-0.12,0.03-0.18,0l-5.25-4.47c-0.1-0.08-0.15-0.22-0.13-0.35l2-10.65
			c0.01-0.07,0.06-0.12,0.12-0.14C164.35,9.06,164.4,9.09,164.46,9.14z"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#D1D3D4" points="170.22,13.8 169.86,13.91 167.88,24.58 168.23,24.47 		
			"/>
		<g>
			<path clipRule="evenodd" clipRule="evenodd" fill="#909090" d="M166.94,13.05c0.14-0.08,0.15-0.34,0-0.58s-0.32-0.35-0.46-0.26
				s-0.14,0.34,0,0.58S166.81,13.14,166.94,13.05z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#323C59" d="M166.89,12.96c0.11-0.07,0.12-0.27,0-0.45s-0.24-0.27-0.35-0.21
				s-0.11,0.27,0,0.45S166.79,13.02,166.89,12.96z"/>
		</g>
	</g>
	<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M163.41,23.18c-2.38-3.09-2.23-4.17-2.4-5.92
		c0.23-0.62,0.63-1.16,1.16-1.56c0.82-0.71,1.55-1.26,2.38-1.92c0.3-0.24,0.58-0.43,0.86-0.7c0.17-0.14,0.35-0.24,0.55-0.32
		c0.12,0.13,0.44,0.3-0.06,1.08c0.12-0.1,0.9-1.08,1.13-0.7c0.31,0.54-0.59,1.78-0.59,1.78c0.27-0.27,0.74-0.73,0.91-0.67
		s0.29,0.7-0.08,1.18c-0.18,0.28-1,1.2-1.21,1.48c-0.34,0.47-0.58,0.99-0.73,1.55c0.18-0.25,0.39-0.5,0.63-0.79
		c0.54-0.69,1-1.62,1.29-1.32c0.62,0.76-1.14,2.94-1.91,4.57S163.71,23.56,163.41,23.18z"/>
</g>
<g id="alert1">
	<g>
		<defs>
			<path id="SVGID_3_" d="M23.92-15.3C42.86-15.3,66,8.82,75.61,38.53s0.53,56.2-18.41,55.75c-51.92-1.26-42.41-25.3-52-55
				S5-15.3,23.92-15.3z"/>
		</defs>
		<clipPath id="SVGID_4_">
			<use xlinkHref="#SVGID_3_"  overflow="visible"/>
		</clipPath>
		<g clip-path="url(#SVGID_4_)">
			<g>
				<g>
					<path clipRule="evenodd" clipRule="evenodd" fill="#456584" d="M46.1,31.7c4.32,2.78,2.31,6.9-1.39,9.25
						c-0.21,0.08-1.93,5.45-10.51,10.33c-2,2-20.74,13-22,13c0-1-4.52-1.57-2-5c4.11-5.61,15-11,18-14.79
						c2.82-2.29,5.22-5.06,7.09-8.18C38.7,29.78,42,26.6,46.1,31.7z"/>
					<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M50.15,29.02c-1.86,0.82-3.09,2.64-3.15,4.68
						c-0.25,2.1-0.16,4.22,0.26,6.29c0-0.27,3.83-3.59,7-5.9c0.34-0.26,3.77-2.81,0-4.56L50.15,29.02z"/>
					<path clipRule="evenodd" clipRule="evenodd" fill="#E3A87F" d="M56.2,23.6c0,2.37-0.26,4.81-0.26,7.15c0,1.85-2,2.64-3,3.17
						c-4.21,2.45-5.81-0.67-5.59-2c0.19-1.14,0.48-2.27,0.86-3.37c0.4-1.16,2.64-1,2.64-2.18L56.2,23.6z"/>
				</g>
				<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M62.81,80.88c-0.47-0.59-2.57-2.39-3-1.35
					c-0.43,1.59-1.82,6-1.89,6.21c-0.11,0.38-0.19,0.76-0.25,1.15c-0.04,0.66,0.11,1.32,0.42,1.9c0.47,1,0.91,1.78,1.4,2.72
					c0.17,0.34,0.36,0.63,0.5,1c0.1,0.19,0.23,0.37,0.38,0.52c0.16-0.07,0.52-0.09,0.38-1c0.07,0.13,0.38,1.35,0.74,1.09
					s0.19-1.86,0.19-1.86c0.14,0.35,0.38,1,0.56,1s0.54-0.52,0.41-1.11c0-0.33-0.44-1.5-0.5-1.85c-0.11-0.57-0.11-1.14,0-1.71
					c0.07,0.3,0.16,0.62,0.25,1c0.21,0.85,0.29,1.91,0.65,1.73c0.87-0.44,0.15-3.15,0.1-5S63.57,81.83,62.81,80.88z"/>
			</g>
			<g>
				<g>
					<path fill="#2B4057" d="M51.58,119.01c-0.25-2.99-0.82-5.95-1.68-8.82c0-0.18,0.29-2.63,0.77-6.12l-3-27.28h-3.86h-6.53
						c0,0.32,3.53,63.32,3.53,63.32l0.26,0.19c0.91,0.62,1.93,1.07,3,1.34c2.45,0.57,5.02-0.06,6.93-1.69
						C51,139.95,52,124.22,51.58,119.01z"/>
					<path fill="#456584" d="M62.94,117.53c-0.13-0.52,1.4-7.27,1.43-13.81c-0.08-8.63-0.75-17.24-2-25.78l-5.61-0.48l-2.21-0.76
						l-6.86,0.06l0.41,3.76l-4.52-0.77c0,0,0.52,6.77,1.46,9.11s3.36,1.38,4.56,5.37l1.07,9.81l5,45.75
						c0.22,0.24,0.47,0.44,0.75,0.61c1.03,0.54,2.19,0.77,3.35,0.68c1.45-0.08,2.89-0.34,4.28-0.79l0.53-0.18
						C66.58,121.7,63.05,118.05,62.94,117.53z"/>
					<path fill="#5B230D" d="M59.77,151.11c-1.16,0.09-2.32-0.14-3.35-0.68l0,0l-0.85,2.65l-0.39,1.2c0,0-1.46,4.14-0.81,6.9
						c0.16,1.02,0.86,1.88,1.83,2.24c2.61,0.88,5.29-0.93,6.72-2.15c0.66-0.56,1.06-1.38,1.08-2.25l0.1-8.7
						C62.7,150.77,61.24,151.04,59.77,151.11z"/>
					<path fill="#5B230D" d="M51.06,140.03c-1.91,1.62-4.49,2.24-6.93,1.67c-1.07-0.27-2.09-0.72-3-1.34l0,0
						c-0.74,2.11-3.13,4.34-5.13,5.77c-1.51,1.1-3.73,2.4-4.41,3.5c-0.2,0.27-0.25,0.62-0.13,0.94c0.58,1.22,2.89,1.86,7.43,0.85
						c2.57-0.49,4.89-1.85,6.58-3.85c1.33,0.75,4.86-0.64,5.56-1.76S51.06,140.03,51.06,140.03z"/>
				</g>
				<g>
					<path fill="#354F6B" d="M38.17,33.79c-1.32,3.91-1.72,9.11-1.6,17.91c0.17,10.74,0.33,33.41,0.33,33.41L41,87.8
						c0,0,0-3.26,0-7.63C41,74.4,41,66.7,41.12,62l0,0c-1-7.7-0.64-24.74-0.64-24.74l2.08-1.17L41,34.7l0,0
						c1.11-1.64,2.67-2.92,4.49-3.69l2.2-0.91c-1-0.46-5.73-2.53-8,0.57c-0.32,0.54-0.61,1.09-0.87,1.66l-0.07,0.14
						c-0.04,0.12-0.09,0.24-0.15,0.35C38.42,33.11,38.29,33.44,38.17,33.79z"/>
					<path fill="#456584" d="M40.35,37.26c0,0-0.35,17.04,0.65,24.74l0,0c0-0.71,0-1.36,0.07-1.92c0.32-7,0.53-11.91,2.21-17.69
						c0.68-2.3,1.53-4.73,2.32-6.84c1-2.7,1.91-4.84,2.15-5.43l-0.14-0.07l-2.2,0.91c-1.8,0.79-3.33,2.09-4.41,3.74l0,0l1.48,1.44
						L40.35,37.26z"/>
					<path fill="#FADCEB" d="M40.91,76.29v3.76c3.04,0.94,6.23,1.35,9.41,1.22c-0.1-1.21-0.17-2.4-0.22-3.57
						C50,76.07,50,74.47,50,72.9c-0.64-4.67-0.83-9.39-0.57-14.1c0.45-6.58,2.35-12.97,5.57-18.72c0.97-1.88,1.62-3.91,1.93-6
						l-0.05,0.08l0.16-0.91c-1.22,1.56-5.61,3.87-8.33,3.24c-3.35-0.78-3-0.9-3-0.9l0,0c-0.87,2.11-1.71,4.5-2.4,6.8
						c-1.68,5.78-1.89,10.68-2.21,17.69c0,0.56,0,1.21-0.07,1.92C40.92,65.7,40.9,71.27,40.91,76.29z"/>
					<path fill="#456584" d="M56.88,34.04c-0.3,2.09-0.96,4.12-1.93,6c-3.2,5.77-5.09,12.18-5.51,18.76
						c-0.27,4.7-0.08,9.42,0.56,14.09c0.03-3.41,0.29-6.81,0.78-10.19c0.99-5.9,3.48-11.44,7.22-16.1l-1.9-3.21l2.9,1.77
						c0,0,1.85-7.91,1.26-10.16c-0.11-0.41-0.29-0.79-0.53-1.14c-0.66-0.91-1.58-1.61-2.63-2C57.07,32.59,57,33.32,56.88,34.04z"/>
					<path fill="#354F6B" d="M50.68,85.01c0.28,2.39,0.63,4.89,1.06,7.53c3,0.84,6.17,0.9,9.2,0.16c4.65-1.28,4.16-2,4.16-2
						S63,78.1,62.6,75s9.14-30.64,6.4-34.55s-9.2-6.63-9.2-6.63l0,0c0.24,0.35,0.42,0.73,0.53,1.14c0.5,2.29-1.33,10.2-1.33,10.2
						l-2.91-1.77L58,46.6c-3.74,4.66-6.23,10.2-7.22,16.1c-0.49,3.38-0.75,6.78-0.78,10.19C49.98,76.94,50.21,80.99,50.68,85.01z"/>
					<g>
						<path fill="#F0B387" d="M65.36,86.92c-0.62,0.45-1.18,0.98-1.67,1.56c-0.87,1-2.11,5.62-0.55,6c0.64,0.16,1.45-2.91,1.72-2.85
							c1.12,0.27-1.54,6.92-0.39,7.3s4.66-3.52,5.42-4.72s1.74-8.12,1.74-8.12L65.36,86.92z"/>
						<path fill="#456584" d="M70.11,42.07c-0.86-2.53-3.62-3.88-6.15-3.01c-1.11,0.38-2.05,1.15-2.63,2.17
							c-0.92,1.77-1.1,3.84-0.5,5.74c2,7.41,5.35,17.14,5.27,18.74s-1,18.56-1.24,20.31c-0.26,2,6.23,1.84,7.18,0.82
							S74,66.29,74,62.7C74,60.33,72.28,48.28,70.11,42.07z"/>
					</g>
					<g>
						<path fill="#FAEDF4" d="M56.35,30.92c-0.03-0.1-0.06-0.21-0.08-0.32l0.35,0.08l0.46,1.21c0,0,0.28,4.88-6.31,7.84L49,35.52
							C49,35.52,54,34,56.35,30.92z"/>
						<path fill="#FAEDF4" d="M46.77,34.95l-1.84,2.38c0.1-1.16,0.35-2.31,0.72-3.41c0.49-1,0.84-1.19,0.84-1.19L46.77,34.95z"/>
						<path fill="#F298C0" d="M49,35.52l-2.2-0.57l-0.73,0.94l0.47,0.87c-2.9,10.89-4.51,22.08-4.8,33.34l1.76,2.77l2.69-2.62
							c0,0-0.47-10.38,0.08-18.42s1.68-14.69,1.68-14.69l1.55-0.44L49,35.52z"/>
					</g>
					<path fill="#0C163F" d="M40.91,76.29v3.76c0.4,0.12,0.84,0.24,1.31,0.36c1.72,0.44,3.48,0.73,5.26,0.84
						c0.95,0.07,1.89,0.07,2.84,0l0,0c0-0.35,0-0.7-0.08-1c0-0.51-0.03-1.01-0.09-1.5l-0.06-1l0,0c-0.87,0.06-1.75,0.06-2.62,0
						c-1.78-0.17-3.54-0.52-5.25-1.05C41.75,76.58,41.32,76.44,40.91,76.29z"/>
					<path fill="#E3A000" d="M42.22,76.7v3.7c1.72,0.44,3.48,0.73,5.26,0.84V77.7C45.7,77.55,43.93,77.22,42.22,76.7z"/>
				</g>
			</g>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M39.65,16.96c0.2,1.32,0.25,2.67,0.14,4
				c-0.24,1.6,1.72,1.63,0.81,4c0.41,1.92,1.13,3.75,2.13,5.44c0.58,1.08,1.37,2.65,2.56,3.12c3,1.17,5.15-2,7.56-3.44
				c2.41-1.44,1.47-4.49,1.81-6.82c0.62-4.19-1.63-9.38-6.27-10.14c-2.43-0.38-4.91,0.02-7.1,1.14
				C40.65,14.7,39.28,15.62,39.65,16.96z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#B08080" d="M49.56,20.91c0.8,0.19,1,0.23,1.42,1.13
				c0.42,0.9,1,0.69,1.15,2.35c0.12,0.38,3.19-1.4,3.78,1.35c2.6-3.57,1.67-12.23-1.2-13.81c-4.71,1.38-5.92,2-7.17,5.07
				C47.48,18.43,48.52,20.7,49.56,20.91z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M46,9.26c-6.59,2.71-9.8,7.34-6.52,9.18
				c1.12,0.62,4.39-4.86,7.51-0.78c1-2.45,2.53-4.2,7.72-5.73C55.73,6.22,47.71,8.55,46,9.26z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M41.74,29c0.17-0.51,0.27-1,1.24-1c1.8,0.19,3.5,0.91,4.88,2.08
				c1.65,1.82,3.72-0.35,4.62-1.66c0.42-0.35,1.64-0.71,1.73-0.35c-0.43,3.42-5,5.81-8.52,6.61C43.63,35.14,41.25,30.48,41.74,29z"
				/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M43.59,28.75c1,0.57,1.23,0.71,2.09,1.18s0.37,1.14-0.43,0.76
				c-0.11-0.06-1.3-1.22-1.47-1s-0.24,0.4-0.69,0.28C42.18,29.76,42.55,29.13,43.59,28.75z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M55.63,23.7c-0.24,1.52-1,2.66-1.64,2.55
				c-1.07-0.48-1.65-1.66-1.37-2.8c0.23-1.51,1.58-2.78,2.24-2.67S55.87,22.16,55.63,23.7z"/>
		</g>
	</g>
	<g>
		<path clipRule="evenodd" clipRule="evenodd" fill="#FF1D25" d="M48.29,96.59c-26.68,0-48.3-21.62-48.3-48.3
			s21.62-48.3,48.3-48.3s48.3,21.62,48.3,48.3c0,0,0,0.01,0,0.01C96.56,74.96,74.95,96.56,48.29,96.59z M48.29,4.09
			C23.87,4.09,4.08,23.88,4.08,48.3s19.79,44.21,44.21,44.21S92.5,72.72,92.5,48.3l0,0C92.47,23.89,72.7,4.12,48.29,4.09z"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#FF1D25" points="7.77,71.27 80.18,16.97 83.48,20.27 10.29,74.41 		"/>
	</g>
	<g>
		<path clipRule="evenodd" clipRule="evenodd" fill="#BEBFC0" d="M0.52,53.81l6.88-0.59c0.13-0.01,0.26,0.04,0.33,0.15l6.13,9
			c0.03,0.06,0.03,0.12,0,0.18c-0.03,0.06-0.09,0.1-0.15,0.1l-6.79,0.67c-0.13,0.01-0.26-0.05-0.34-0.16L0.2,54.25l0.17-0.36
			C0.4,53.84,0.46,53.81,0.52,53.81z"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#D1D3D4" d="M6.37,53.3l1-0.09c0.13-0.01,0.26,0.05,0.33,0.16l6.14,9
			c0.03,0.06,0.03,0.12,0,0.18l-0.16,0.35l-0.94-0.08c-0.13,0.01-0.25-0.05-0.33-0.16l-6.22-9c-0.03-0.06-0.03-0.13,0-0.19
			C6.25,53.32,6.3,53.31,6.37,53.3z"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#CECECE" points="0.33,54.16 7.24,53.56 7.41,53.21 0.5,53.81 		"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#909090" d="M0.36,54.15l6.88-0.59c0.13-0.01,0.26,0.05,0.33,0.16l6.13,9
			c0.03,0.06,0.03,0.12,0,0.18c-0.03,0.06-0.08,0.1-0.15,0.11l-6.88,0.54c-0.13,0.02-0.26-0.04-0.33-0.15l-6.11-8.98
			c-0.03-0.06-0.03-0.13,0-0.19C0.24,54.17,0.3,54.16,0.36,54.15z"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#D1D3D4" points="7.74,53.38 7.56,53.71 13.7,62.66 13.87,62.33 		"/>
		<g>
			<path clipRule="evenodd" clipRule="evenodd" fill="#909090" d="M4.89,55.17c0-0.16-0.14-0.35-0.39-0.43S4,54.7,4,54.87
				s0.14,0.35,0.39,0.43S4.85,55.32,4.89,55.17z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#323C59" d="M4.78,55.13c0-0.12-0.1-0.27-0.29-0.33s-0.37,0-0.4,0.11
				s0.11,0.26,0.3,0.33S4.76,55.25,4.78,55.13z"/>
		</g>
	</g>
	<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M11.92,63.28c-3.74,1.1-4.69,0.56-6.39,0.08
		c-0.49-0.44-0.85-1.02-1.03-1.66c-0.36-1-0.6-1.9-0.91-2.92c-0.12-0.36-0.19-0.7-0.34-1c-0.07-0.21-0.1-0.42-0.09-0.64
		c0.16-0.06,0.43-0.3,1,0.45c0-0.14-0.68-1.23-0.24-1.29c0.62-0.1,1.44,1.19,1.44,1.19c-0.15-0.35-0.41-1-0.29-1.09s0.75,0,1.07,0.5
		c0.2,0.27,0.75,1.38,0.94,1.67c0.31,0.48,0.71,0.9,1.17,1.24c-0.16-0.25-0.32-0.54-0.51-0.86c-0.44-0.76-1.12-1.57-0.75-1.69
		c0.93-0.3,2.32,2.13,3.55,3.45S12.39,63.15,11.92,63.28z"/>
	<path clipRule="evenodd" clipRule="evenodd" fill="#5A5A5A" d="M49.3,88.27h-0.1 M49.15,88.32v1 M49.15,89.37h0.1
		c0.03,0,0.05-0.02,0.05-0.05l0,0L49.15,89.37z"/>
</g>
<g id="text">
	<g>
		<g>
			<path fill="#323236" d="M80.94,141.98l-0.54-1.36h-3l-0.54,1.36H76l2.45-6.1h0.94l2.46,6.1H80.94z M78.94,136.67l-1.3,3.28h2.59
				L78.94,136.67z"/>
			<path fill="#323236" d="M82.58,141.98v-4.42h0.69v0.72c0.33-0.49,0.88-0.79,1.47-0.81v0.71c-0.09-0.01-0.19-0.01-0.28,0
				c-0.48,0.04-0.92,0.3-1.19,0.7v3.13L82.58,141.98z"/>
			<path fill="#323236" d="M90.92,141.98v-3c0-0.56-0.24-0.91-0.81-0.91c-0.47,0.03-0.9,0.28-1.17,0.67v3.24h-0.68v-3
				c0-0.56-0.24-0.91-0.82-0.91c-0.47,0.04-0.89,0.29-1.16,0.68v3.23H85.6v-4.42h0.68v0.64c0.35-0.45,0.87-0.72,1.43-0.75
				c0.54-0.04,1.04,0.3,1.19,0.83c0.33-0.5,0.88-0.81,1.48-0.83c0.8,0,1.23,0.45,1.23,1.34v3.19L90.92,141.98z"/>
			<path fill="#323236" d="M92.6,141.39l0.36-0.49c0.38,0.4,0.9,0.63,1.45,0.63c0.68,0,1-0.32,1-0.76c0-1.07-2.73-0.41-2.73-2
				c0-0.68,0.59-1.27,1.64-1.27c0.61-0.01,1.2,0.22,1.64,0.64l-0.32,0.48c-0.34-0.37-0.82-0.57-1.32-0.56c-0.6,0-1,0.3-1,0.7
				c0,1,2.73,0.32,2.73,2c0,0.74-0.6,1.34-1.74,1.34C93.67,142.11,93.05,141.85,92.6,141.39z"/>
			<path fill="#323236" d="M99.26,141.12l0.44-0.58c0.49,0.55,1.19,0.87,1.92,0.87c1.09,0,1.44-0.58,1.44-1.05
				c0-1.57-3.61-0.7-3.61-2.88c0-1,0.89-1.69,2.09-1.69c0.81-0.04,1.59,0.27,2.16,0.85l-0.45,0.56c-0.46-0.48-1.1-0.75-1.77-0.73
				c-0.73,0-1.25,0.39-1.25,1c0,1.37,3.61,0.58,3.61,2.86c0,0.88-0.6,1.79-2.25,1.79C100.7,142.15,99.85,141.78,99.26,141.12z"/>
			<path fill="#323236" d="M105.09,141.05v-2.88h-0.73v-0.61h0.73v-1.2h0.68v1.2h0.9v0.61h-0.9v2.74c0,0.33,0.15,0.56,0.45,0.56
				c0.17,0,0.34-0.06,0.47-0.18l0.2,0.51c-0.23,0.21-0.53,0.31-0.83,0.29C105.42,142.09,105.09,141.7,105.09,141.05z"/>
			<path fill="#323236" d="M107.64,141.98v-4.42h0.68v0.72c0.34-0.49,0.88-0.79,1.47-0.81v0.71c-0.09-0.01-0.18-0.01-0.27,0
				c-0.48,0.04-0.92,0.3-1.2,0.7v3.13L107.64,141.98z"/>
			<path fill="#323236" d="M110.4,139.77c-0.06-1.22,0.88-2.26,2.1-2.32c0.02,0,0.05,0,0.07,0c1.19,0.01,2.14,0.98,2.13,2.17
				c0,0.07,0,0.13-0.01,0.2v0.17h-3.57c0.02,0.86,0.73,1.55,1.59,1.53c0,0,0,0,0,0c0.51,0,1-0.2,1.36-0.56l0.33,0.45
				c-0.47,0.46-1.1,0.7-1.75,0.68c-1.21,0.04-2.22-0.92-2.25-2.13C110.4,139.9,110.4,139.83,110.4,139.77z M112.56,138.02
				c-0.8,0.02-1.43,0.67-1.44,1.47H114c0.02-0.79-0.6-1.45-1.39-1.47C112.59,138.02,112.58,138.02,112.56,138.02z"/>
			<path fill="#323236" d="M115.54,139.77c-0.07-1.21,0.85-2.24,2.06-2.32c0.05,0,0.1,0,0.15,0c0.63-0.03,1.24,0.26,1.61,0.77
				l-0.45,0.42c-0.25-0.37-0.68-0.59-1.13-0.57c-0.94,0.1-1.62,0.95-1.52,1.88c0.09,0.8,0.72,1.43,1.52,1.52
				c0.45,0.01,0.87-0.2,1.13-0.57l0.45,0.42c-0.37,0.51-0.98,0.8-1.61,0.77c-1.21,0.01-2.2-0.96-2.21-2.17
				C115.54,139.87,115.54,139.82,115.54,139.77z"/>
			<path fill="#323236" d="M123.31,141.98v-2.91c0-0.78-0.4-1-1-1c-0.51,0.03-0.99,0.27-1.31,0.68v3.23h-0.68v-6.1H121v2.32
				c0.4-0.46,0.97-0.73,1.58-0.75c0.68-0.09,1.32,0.38,1.41,1.07c0.02,0.11,0.02,0.23,0,0.34v3.12L123.31,141.98z"/>
			<path fill="#323236" d="M125.12,139.77c-0.06-1.22,0.88-2.26,2.1-2.32c0.02,0,0.05,0,0.07,0c1.19,0.01,2.14,0.98,2.13,2.17
				c0,0.07,0,0.13-0.01,0.2v0.17h-3.57c0.01,0.86,0.71,1.54,1.57,1.53c0.01,0,0.01,0,0.02,0c0.51,0,1-0.2,1.36-0.56l0.33,0.45
				c-0.47,0.46-1.11,0.7-1.76,0.68c-1.21,0.03-2.21-0.93-2.24-2.14C125.12,139.89,125.12,139.83,125.12,139.77z M127.28,138.02
				c-0.8,0.02-1.44,0.67-1.44,1.47h2.89c0.03-0.78-0.59-1.44-1.37-1.47C127.33,138.02,127.31,138.02,127.28,138.02z"/>
			<path fill="#323236" d="M133.72,141.98v-0.66c-0.35,0.48-0.9,0.76-1.49,0.77c-1.16,0-2-0.88-2-2.32
				c-0.15-1.12,0.64-2.15,1.76-2.3c0.08-0.01,0.16-0.02,0.24-0.02c0.59,0.01,1.14,0.3,1.49,0.78v-2.35h0.68v6.1L133.72,141.98z
				 M133.72,140.78v-2c-0.31-0.43-0.8-0.7-1.33-0.71c-0.89,0-1.42,0.73-1.42,1.7s0.53,1.7,1.42,1.7
				C132.92,141.46,133.41,141.21,133.72,140.78z"/>
		</g>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#535359" points="74.66,146.03 77.52,143.16 77.52,145.1 91.88,145.1 
			91.88,146.95 77.52,146.95 77.52,148.9 		"/>
		<polygon clipRule="evenodd" clipRule="evenodd" fill="#535359" points="133.63,146.95 118.91,146.95 118.91,145.1 133.63,145.1 
			133.63,143.16 136.49,146.03 133.63,148.9 		"/>
	</g>
	<g>
		<path fill="#FF1D25" d="M83,85.48c-0.05-1.81,1.38-3.32,3.19-3.37c0.07,0,0.15,0,0.22,0c1.07-0.03,2.08,0.54,2.61,1.47l-1,0.49
			c-0.35-0.58-0.97-0.93-1.64-0.94c-1.3,0-2.35,1.05-2.35,2.35c0,1.3,1.05,2.35,2.35,2.35c0.68,0,1.3-0.37,1.64-0.95l1,0.5
			c-0.54,0.93-1.54,1.49-2.61,1.46c-1.81,0.07-3.34-1.34-3.41-3.15C83,85.62,83,85.55,83,85.48z"/>
		<path fill="#FF1D25" d="M92.81,88.7v-0.5c-0.39,0.41-0.95,0.64-1.52,0.62c-0.86,0.04-1.59-0.62-1.64-1.48c0-0.03,0-0.05,0-0.08
			c-0.01-0.84,0.67-1.53,1.51-1.53c0.04,0,0.09,0,0.13,0c0.57-0.02,1.12,0.2,1.52,0.61V85.7c0-0.54-0.45-0.87-1.1-0.87
			c-0.53,0-1.03,0.21-1.4,0.59L89.9,84.7c0.54-0.51,1.26-0.79,2-0.76c1,0,2,0.44,2,1.7v3.06H92.81z M92.81,87.63v-0.71
			c-0.28-0.33-0.69-0.51-1.12-0.49c-0.47-0.09-0.92,0.22-1,0.69c-0.09,0.47,0.22,0.92,0.69,1c0.11,0.02,0.21,0.02,0.32,0
			c0.42,0.04,0.83-0.12,1.12-0.42V87.63z"/>
		<path fill="#FF1D25" d="M100.92,88.7v-3c0-0.5-0.22-0.84-0.76-0.84c-0.44,0.03-0.85,0.26-1.1,0.62v3.22H98v-3
			c0-0.5-0.22-0.84-0.78-0.84c-0.44,0.03-0.84,0.26-1.08,0.63v3.21h-1V84h1v0.7c0.37-0.47,0.93-0.75,1.53-0.76
			c0.58-0.05,1.12,0.31,1.29,0.87c0.36-0.53,0.95-0.85,1.59-0.87c0.68-0.07,1.29,0.42,1.37,1.1c0.01,0.11,0.01,0.22-0.01,0.32v3.34
			H100.92z"/>
		<path fill="#FF1D25" d="M103,86.37c-0.05-1.31,0.97-2.42,2.29-2.47c0.03,0,0.06,0,0.09,0c1.29,0,2.33,1.04,2.33,2.33
			c0,0.08,0,0.15-0.01,0.23v0.24h-3.63c0.06,0.77,0.73,1.34,1.5,1.29c0.49,0,0.96-0.18,1.33-0.51l0.47,0.67
			c-0.53,0.46-1.21,0.71-1.91,0.68c-1.3,0.06-2.4-0.95-2.46-2.26C103,86.51,103,86.44,103,86.37z M105.38,84.74
			c-0.71-0.01-1.29,0.54-1.32,1.25h2.65c0.02-0.7-0.53-1.27-1.23-1.29c-0.04,0-0.08,0-0.12,0L105.38,84.74z"/>
		<path fill="#FF1D25" d="M108.69,88.7V84h1v0.7c0.36-0.48,0.93-0.78,1.53-0.8v1c-0.11-0.01-0.21-0.01-0.32,0
			c-0.48,0.02-0.92,0.25-1.21,0.63v3.17H108.69z"/>
		<path fill="#FF1D25" d="M115,88.7v-0.5c-0.39,0.41-0.95,0.64-1.52,0.62c-0.86,0.04-1.59-0.62-1.64-1.48c0-0.03,0-0.05,0-0.08
			c-0.01-0.84,0.67-1.53,1.51-1.53c0.04,0,0.09,0,0.13,0c0.57-0.02,1.12,0.2,1.52,0.61V85.7c0-0.54-0.45-0.87-1.1-0.87
			c-0.53,0-1.03,0.21-1.4,0.59L112,84.7c0.54-0.51,1.26-0.78,2-0.76c1,0,2,0.44,2,1.7v3.06H115z M115,87.63v-0.71
			c-0.28-0.33-0.69-0.51-1.12-0.49c-0.47-0.09-0.92,0.22-1,0.69c-0.09,0.47,0.22,0.92,0.69,1c0.11,0.02,0.21,0.02,0.32,0
			c0.42,0.04,0.83-0.12,1.12-0.42V87.63z"/>
		<path fill="#FF1D25" d="M122.67,88.7v-0.5c-0.39,0.41-0.95,0.64-1.52,0.62c-0.86,0.04-1.59-0.62-1.64-1.48c0-0.03,0-0.05,0-0.08
			c-0.01-0.84,0.67-1.53,1.51-1.53c0.04,0,0.09,0,0.13,0c0.57-0.03,1.13,0.19,1.52,0.61V85.7c0-0.54-0.44-0.87-1.1-0.87
			c-0.52,0-1.03,0.21-1.39,0.59l-0.42-0.71c0.54-0.51,1.26-0.78,2-0.76c1,0,2,0.44,2,1.7v3.05H122.67z M122.67,87.63v-0.71
			c-0.28-0.33-0.69-0.51-1.12-0.49c-0.47-0.09-0.92,0.22-1,0.69c-0.09,0.47,0.22,0.92,0.69,1c0.11,0.02,0.21,0.02,0.32,0
			c0.42,0.04,0.83-0.12,1.12-0.42V87.63z"/>
		<path fill="#FF1D25" d="M125.22,87.63v-2.72h-0.78v-0.89h0.78V82.7h1v1.29h1v0.89h-1v2.47c0,0.32,0.15,0.56,0.44,0.56
			c0.17,0.01,0.33-0.06,0.46-0.17l0.25,0.77c-0.28,0.23-0.64,0.34-1,0.3c-0.6,0.04-1.12-0.42-1.15-1.02
			C125.21,87.73,125.22,87.68,125.22,87.63z"/>
		<path fill="#FF1D25" d="M130.66,88.7v-6.5h4.45v1h-3.31v1.68h3.2v1h-3.2v1.82h3.31v1H130.66z"/>
		<path fill="#FF1D25" d="M136.2,89.7c0.12,0.05,0.26,0.08,0.39,0.08c0.29,0.03,0.57-0.14,0.67-0.41l0.24-0.54l-1.94-4.76h1.1
			l1.37,3.53l1.38-3.53h1.1l-2.27,5.55c-0.23,0.68-0.9,1.12-1.62,1.06c-0.19,0-0.39-0.02-0.58-0.06L136.2,89.7z"/>
		<path fill="#FF1D25" d="M140.85,86.37c-0.05-1.31,0.97-2.42,2.29-2.47c0.03,0,0.07,0,0.1,0c1.29,0,2.33,1.04,2.33,2.33
			c0,0.08,0,0.15-0.01,0.23v0.24h-3.64c0.07,0.77,0.74,1.34,1.51,1.29c0.49,0,0.96-0.18,1.33-0.51l0.47,0.67
			c-0.53,0.46-1.21,0.71-1.91,0.68c-1.31,0.06-2.41-0.96-2.47-2.27C140.85,86.5,140.85,86.43,140.85,86.37z M143.24,84.74
			c-0.71-0.02-1.3,0.54-1.33,1.25h2.66c0.02-0.7-0.53-1.27-1.23-1.29c-0.03,0-0.07,0-0.1,0V84.74z"/>
		<path fill="#FF1D25" d="M149.1,88.7v-6.5h1.15v5.5h2.86v1H149.1z"/>
		<path fill="#FF1D25" d="M153.82,86.37c-0.05-1.31,0.97-2.42,2.29-2.47c0.03,0,0.07,0,0.1,0c1.29,0,2.33,1.04,2.33,2.33
			c0,0.08,0,0.15-0.01,0.23v0.24h-3.64c0.07,0.76,0.73,1.34,1.5,1.29c0.49,0,0.97-0.18,1.34-0.51l0.46,0.67
			c-0.53,0.46-1.21,0.71-1.91,0.68c-1.3,0.06-2.4-0.95-2.46-2.26C153.82,86.51,153.82,86.44,153.82,86.37z M156.21,84.74
			c-0.71-0.02-1.3,0.54-1.33,1.25h2.65c0.02-0.7-0.53-1.27-1.23-1.29c-0.03,0-0.06,0-0.09,0V84.74z"/>
		<path fill="#FF1D25" d="M160.77,88.7l-1.92-4.7H160l1.37,3.53l1.37-3.53h1.11l-1.92,4.7H160.77z"/>
		<path fill="#FF1D25" d="M164.14,86.37c-0.05-1.31,0.97-2.42,2.29-2.47c0.03,0,0.07,0,0.1,0c1.29,0,2.33,1.04,2.33,2.33
			c0,0.08,0,0.15-0.01,0.23v0.24h-3.64c0.07,0.77,0.74,1.34,1.51,1.29c0.49,0,0.96-0.18,1.33-0.51l0.47,0.67
			c-0.53,0.46-1.21,0.71-1.91,0.68c-1.3,0.06-2.41-0.94-2.47-2.25C164.14,86.51,164.14,86.44,164.14,86.37z M166.53,84.74
			c-0.71-0.02-1.3,0.54-1.33,1.25h2.66c0.02-0.7-0.53-1.27-1.23-1.29c-0.03,0-0.07,0-0.1,0V84.74z"/>
		<path fill="#FF1D25" d="M169.86,88.7v-6.5h1v6.5H169.86z"/>
	</g>
</g>
<g>
	<ellipse opacity="0.19" enableBackground="new    " cx="149.01" cy="275.52" rx="44.06" ry="8.81"/>
	<g>
		<path clipRule="evenodd" clipRule="evenodd" fill="#1A0B17" d="M149.32,279.15h-13.68c-0.18,0-0.34-0.09-0.43-0.24
			c-0.28-0.48-0.41-1.03-0.36-1.58l27,0.61v0.76l-2.06,0.19L149.32,279.15z"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#632800" d="M148.18,269l-5,3.15l-4,1.31l-2.87,0.83c0,0-1.77,2.39-1.46,3.22
			s1.62,0.78,1.62,0.78h25.31l-0.86-9.31L148.18,269z"/>
		<path clipRule="evenodd" clipRule="evenodd" d="M159.6,270.03c0,0-2,3.23-2.8,3.61s-6.06,2-6.06,2s-2,1.32-1.9,3.16l9.56,0.44
			l6-0.38l1.77-0.44l1-0.38h3.55l1.14-0.44c0.59-1.94,0.74-3.99,0.44-6c-0.63-2.59-7.91-2.4-7.91-2.4L159.6,270.03z"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#732E00" d="M159.73,269.29c0,0-2,3.22-2.8,3.6s-6.06,2-6.06,2
			s-2,1.33-1.9,3.16l9.56,0.45l6-0.38l1.77-0.45l1-0.38h3.54l1.14-0.44c0.6-1.94,0.75-3.99,0.44-6c-0.63-2.6-7.91-2.41-7.91-2.41
			L159.73,269.29z"/>
		<g>
			<path d="M150.58,271.22c-0.18,0.01-0.34-0.1-0.39-0.27c-0.09-0.33-0.85-0.51-1.17-0.54c-0.21-0.02-0.36-0.21-0.34-0.42
				c0,0,0,0,0,0c0.02-0.2,0.19-0.36,0.4-0.34c0,0,0.01,0,0.01,0c0.16-0.01,1.57,0.17,1.84,1.08c0.06,0.2-0.06,0.41-0.26,0.47
				L150.58,271.22z"/>
			<path d="M149.45,272.13c-0.18,0.01-0.35-0.1-0.4-0.27c-0.08-0.29-0.75-0.5-1.17-0.54c-0.21-0.02-0.37-0.2-0.35-0.42
				c0.02-0.21,0.2-0.37,0.42-0.35c0.16-0.01,1.58,0.18,1.84,1.09c0.06,0.2-0.06,0.41-0.26,0.47L149.45,272.13z"/>
			<path d="M148.28,272.93c-0.18,0.01-0.35-0.1-0.4-0.27c-0.09-0.33-0.84-0.51-1.17-0.55c-0.2-0.01-0.36-0.19-0.34-0.4
				c0-0.01,0-0.01,0-0.02c0.03-0.21,0.21-0.36,0.42-0.34c0.15-0.01,1.57,0.18,1.83,1.09c0.05,0.2-0.06,0.41-0.26,0.47L148.28,272.93
				z"/>
		</g>
		<path d="M160.81,272.12c-0.11,0-0.21-0.04-0.28-0.12c-0.56-0.45-1.3-0.61-2-0.43c-0.21,0.03-0.4-0.11-0.44-0.32
			c-0.03-0.21,0.11-0.4,0.32-0.43c0.95-0.23,1.95,0.01,2.69,0.66c0.14,0.15,0.14,0.39-0.02,0.54c-0.07,0.07-0.17,0.1-0.26,0.1
			L160.81,272.12z"/>
		<path d="M159.77,273.13c-0.1,0-0.2-0.05-0.27-0.13c-0.57-0.44-1.3-0.6-2-0.42c-0.21,0.03-0.4-0.11-0.43-0.32
			c-0.04-0.21,0.1-0.4,0.31-0.44c0,0,0.01,0,0.01,0c0.95-0.23,1.96,0.02,2.69,0.67c0.15,0.15,0.15,0.39,0,0.54c0,0,0,0,0,0
			C160,273.11,159.88,273.14,159.77,273.13z"/>
		<path d="M158.77,274.1c-0.11,0-0.21-0.04-0.28-0.12c-0.56-0.45-1.3-0.61-2-0.43c-0.21,0.03-0.4-0.11-0.44-0.32
			c-0.03-0.21,0.11-0.4,0.32-0.43c0.95-0.23,1.95,0.01,2.69,0.66c0.14,0.15,0.14,0.39-0.02,0.54c-0.07,0.07-0.17,0.1-0.26,0.1
			L158.77,274.1z"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#2B4057" d="M138.2,175.18c0,0,1.82,35.86,3.1,41s4.12,13.17,4.12,13.17
			l1.53,19l1.14,12.35l-0.19,7.17c0,0-0.18,1.37,2,1.63l7.19,0.85l3-0.43l2.34-2.47l-2.62-1.63c0,0-1.59-12.05-1.59-13.41
			s-0.73-21.49-0.73-21.49l-5.25-36.84l-2-11.41l-2-7.27L138.2,175.18z"/>
		<path clipRule="evenodd" clipRule="evenodd" fill="#456584" d="M167.22,175.03c0,0,0.58,7,0.15,9.09s-0.91,7-0.91,7l0.76,23.09
			l1,7.74l1.9,6.94l1.31,15.61l0.55,10.37l0.29,6.94l1.31,7.21l-0.14,2.8l-15-1.33l0.15-10.14v-11.33l-4.05-20.16L148,192.7
			l-2.54-8.94l-5-5l-1.16-3.57l21.88-1.61l6.13,0.21L167.22,175.03z"/>
		<g id="hand">
			<path clipRule="evenodd" clipRule="evenodd" fill="#456584" d="M128.05,121.26l-6.61,1.14c-5.98-0.2-7.39,10.68-2.89,11.63
				c1.44-0.17,2.4-0.31,2.55-0.4c0.31-0.18,1.96-0.09,1.95-0.45l4.87-1.12l14.21-2.79l4.07-11.99L128.05,121.26z"/>
			<g>
				<g>
					<polygon clipRule="evenodd" clipRule="evenodd" fill="#2C306D" points="90.91,103.92 92.7,104 91.86,122.88 89.95,122.8 
											"/>
					<g>
						<path clipRule="evenodd" clipRule="evenodd" fill="#5A5A5A" d="M93.02,106.02c0-0.04-0.03-0.07-0.07-0.07l-0.13,0
							c-0.04,0-0.07,0.03-0.07,0.07l-0.04,1.1c0,0.04,0.03,0.07,0.07,0.07l0.14,0c0.03,0,0.06-0.03,0.06-0.07L93.02,106.02z"/>
						<path clipRule="evenodd" clipRule="evenodd" fill="#C8C8C8" d="M92.9,105.79c0-0.04-0.02-0.07-0.06-0.07l-0.14,0
							c-0.04,0-0.07,0.03-0.07,0.07l-0.05,1.68c0,0.04,0.03,0.07,0.07,0.07l0.13,0c0.04,0,0.07-0.03,0.07-0.07L92.9,105.79z"/>
					</g>
				</g>
				<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M115.8,123.93l-21.4-1.18l-0.41-1.95l-0.45-2.18
					c0,0-0.06-0.9-0.87-0.69c-0.81,0.21-0.74,2.54-0.74,2.54l-0.62,1.13c0,0-1.11,0.05-1.19-0.36c-0.08-0.76-0.01-1.53,0.22-2.25
					c0.45-0.61,1.08-1.06,1.8-1.28c0.54-0.09,1.27-0.26,1.27-0.26c0.04-0.37-0.08-0.74-0.34-1.01c-0.23-0.18-0.71-0.02-1.25-0.04
					c-0.74-0.07-1.46,0.27-1.88,0.88c0,0-0.13-0.74,0.25-0.99c0.32-0.22,0.61-0.49,0.86-0.8c0,0,0.48-0.98,0.03-0.92
					c-0.56-0.07-1.13,0.01-1.65,0.24c-0.68,0.54-1.25,1.2-1.68,1.95l-0.47,1.09l-0.32,1.99c0,0,0.3,2.25,0.36,2.61
					c0.17,0.83,0.48,1.63,0.92,2.36c0.55,0.73,1.32,1.26,2.19,1.52c0.63,0.09,2.06,0.26,2.06,0.26l2.83,1.26l2.98,0.78l5.97,1.09
					l5.4,1.34l6.06,1.05L115.8,123.93z"/>
				<path clipRule="evenodd" clipRule="evenodd" fill="#456584" d="M121.36,122.62l-25.31-0.39c-0.09,0.85-0.21,1.69-0.38,2.52
					l0.23,4.01c0.18,0.88,0.87,1.56,1.74,1.74c0.8,0.1,15.34,2.54,21,3.36C124.56,130.43,121.36,122.62,121.36,122.62z"/>
			</g>
		</g>
	</g>
	<g id="lines">
		<path fill="#632800" d="M137.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S137.97,103.05,137.83,103.05z"/>
		<path fill="#632800" d="M133.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S133.97,103.05,133.83,103.05z"/>
		<path fill="#632800" d="M129.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S129.97,103.05,129.83,103.05z"/>
		<path fill="#632800" d="M125.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S125.97,103.05,125.83,103.05z"/>
		<path fill="#632800" d="M121.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S121.97,103.05,121.83,103.05z"/>
		<path fill="#632800" d="M117.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S117.97,103.05,117.83,103.05z"/>
		<path fill="#632800" d="M113.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S113.97,103.05,113.83,103.05z"/>
		<path fill="#632800" d="M109.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S109.97,103.05,109.83,103.05z"/>
		<path fill="#632800" d="M105.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S105.97,103.05,105.83,103.05z"/>
		<path fill="#632800" d="M101.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S101.97,103.05,101.83,103.05z"/>
		<path fill="#632800" d="M97.83,103.05h-2c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h2c0.14,0,0.25,0.11,0.25,0.25
			S97.97,103.05,97.83,103.05z"/>
		<path fill="#632800" d="M93.83,103.05h-0.69c-0.14,0-0.25-0.11-0.25-0.25s0.11-0.25,0.25-0.25h0.69c0.14,0,0.25,0.11,0.25,0.25
			S93.97,103.05,93.83,103.05z"/>
	</g>
	<g>
		<path fill="#2D445C" d="M137.05,163.23c0,0-0.72,7.27-0.72,12.83c0,3.1,0,7.13-0.05,10.08c-0.03,2.48,1.71,4.64,4.14,5.14
			c1.25,0.27,2.52,0.43,3.8,0.46l-4.47-27.65C139.75,164.09,137.76,160.7,137.05,163.23z"/>
		<path fill="#354F6B" d="M158.14,113.7c1.09,0.57,2.08,1.31,2.94,2.19c0.75,0.99,1.42,2.03,2,3.13c0,0,8.08,9.31,8.27,10.26
			s-4.57,30.88-4.57,30.88l5.42,28.32c0,0-8.74,4.84-15.39,5.13c-4.69,0.2-11.73-1.3-15.48-2.2c-1.54-0.37-2.68-1.68-2.82-3.26
			c-0.37-4-1.06-11.9-1.46-19.44c-0.47-8.73,0.85-26,1.8-32.11s3.9-14.35,6.18-17.77S153.58,111.93,158.14,113.7z"/>
		<g>
			<path fill="#263238" d="M163.08,119.05c-0.58-1.1-1.25-2.14-2-3.13c-0.86-0.88-1.85-1.62-2.94-2.19
				c-3.79-1.5-8.75,0.68-11.63,3.42c-1.92,4.31-3.53,8.76-4.81,13.3c-0.96,4.43-1.41,8.96-1.33,13.5c0,0,5-6.46,11.88-14.07
				c3.41-3.8,7.14-7.3,11.14-10.47C163.19,119.19,163.08,119.05,163.08,119.05z"/>
			<g>
				<defs>
					<path id="SVGID_5_" d="M163.08,119.05c-0.58-1.1-1.25-2.14-2-3.13c-0.86-0.88-1.85-1.62-2.94-2.19
						c-3.79-1.5-8.75,0.68-11.63,3.42c-1.92,4.31-3.53,8.76-4.81,13.3c-0.96,4.43-1.41,8.96-1.33,13.5c0,0,5-6.46,11.88-14.07
						c3.41-3.8,7.14-7.3,11.14-10.47C163.19,119.19,163.08,119.05,163.08,119.05z"/>
				</defs>
				<clipPath id="SVGID_6_">
					<use xlinkHref="#SVGID_5_"  overflow="visible"/>
				</clipPath>
				<g clip-path="url(#SVGID_6_)">
					<path fill="#456584" d="M163.08,119.05c-0.58-1.1-1.25-2.14-2-3.13c-0.86-0.88-1.85-1.62-2.94-2.19
						c-3.79-1.5-8.75,0.68-11.63,3.42c-1.92,4.31-3.53,8.76-4.81,13.3c-0.96,4.43-1.41,8.96-1.33,13.5c0,0,5-6.46,11.88-14.07
						c3.41-3.8,7.14-7.3,11.14-10.47C163.19,119.19,163.08,119.05,163.08,119.05z"/>
				</g>
			</g>
		</g>
		<g>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M145.59,112.47c0,0,0.83,4.81,0.61,5.81s-1,4-1,4v3l-1.06,2.6
				c0,0,2.11,0.77,2.77,0s5.74-7.57,6.29-7.6s3-3,3-3l2.15-1.62l-2.2-0.88l-1-3.85l-0.11-4.07l-0.55-3.75c0,0-6.82,9.14-8.91,9.36
				L145.59,112.47z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M147.65,113.7v3.06c0,0-3.34,5.18-2.45,6.53s0.38,3.55,2,3
				s7-8,7-8l3-2l0.68-1.2l-0.64-0.95l-1.56-8.15l-2,1.5l-2,1.74l-3.27,2.26L147.65,113.7z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M140.61,97.23c-0.28,1.42-0.46,2.86-0.53,4.31
				c0,0.5,0.19,0.97,0.53,1.34l-1,2.5l-0.44,1.51l0.89,0.63l2.59,7.64c0,0,0.5,0.86,3.57,0.5c2.4-0.47,4.69-1.37,6.77-2.65
				l0.83-1.93l4.16-8.14c0,0-1.48-8.77-8.41-8.77S142.42,94.58,140.61,97.23z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#B08080" d="M150,102.13c0.79,0.37,1,0.45,1.2,1.41
				c0.18,0.81,0.81,0.89,0.61,2.53c0,0.39,3.67-0.61,3.6,2.17c3.59-2.85,4.78-11.39,2.18-13.57c-5.24,0.25-6.66,0.58-8.72,3.23
				C148.44,99.27,148.98,101.65,150,102.13z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M149.17,90.09c-7.54,1.11-12,4.82-9.07,7.34
				c1,0.86,5.78-3.66,8,1c1.62-2.14,3.68-3.46,9.46-3.74c2.48-5.27-6.44-4.86-8.4-4.58L149.17,90.09z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M141.16,111.96c-0.11-0.78,0-1.4,0.87-0.45
				c0.52,0.72,1.21,1.3,2,1.7c1.87,1.18,3.77,0,4.83-1c0.45-0.22,1.59-0.29,1.59,0c-1.09,2.91-5.32,3.7-6.69,3.77
				c-1.91,0.09-2.29-1.77-2.58-4.08L141.16,111.96z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M151.87,103.32c0,0,1.85-2.86,3.07-1.5s1.07,1.8,0.07,3.72
				c-0.59,1.14-1.26,2.23-2,3.28L151.87,103.32z"/>
			<path clipRule="evenodd" clipRule="evenodd" fill="#896363" d="M142.64,101.75c0,0,1.61-0.87,2.56-0.51
				c0.62,0.25,1.18,0.61,1.67,1.07C145.59,101.58,144.07,101.38,142.64,101.75z"/>
		</g>
		<path fill="#FADCEB" d="M145.88,121.55c0,0-2.56,8.05-3.35,10.33c-0.52,1.9-0.81,3.87-0.85,5.84c0,0,9.62-14.39,12-16.81
			s5.41-6.2,5.41-6.2l-2.06-0.57L145.88,121.55z"/>
		<g>
			<defs>
				<path id="SVGID_7_" d="M145.88,121.55c0,0-2.56,8.05-3.35,10.33c-0.52,1.9-0.81,3.87-0.85,5.84c0,0,9.62-14.39,12-16.81
					s5.41-6.2,5.41-6.2l-2.06-0.57L145.88,121.55z"/>
			</defs>
			<clipPath id="SVGID_8_">
				<use xlinkHref="#SVGID_7_"  overflow="visible"/>
			</clipPath>
			<g clipPath="url(#SVGID_8_)">
				<polygon fill="#F298C0" points="145.2,126.27 147.2,127.27 146.2,131.27 141.2,138.27 142.2,133.27 				"/>
			</g>
		</g>
		<path fill="#FAEDF4" d="M146.2,117.28c0,0-1.75,5.69-1.75,5.91s-0.28,3.42-0.28,3.42l4.06-5.49L146.2,117.28z"/>
		<polygon fill="#FAEDF4" points="157.93,111.93 146.81,120.98 148.52,125.96 159.92,114.35 		"/>
		<path fillRule="evenodd" clipRule="evenodd" fill="#F2B387" d="M165.91,159.21l0.78,0.93c0.47,0.55,0.65,1.29,0.5,2l-4.94,21.78
			c0,0-0.42,2.26-0.66,3.58c-0.12,0.61-0.37,1.18-0.74,1.68l-2.74,3.66l-0.2,2.91c0,0,0,2.23-0.65,1.86s-0.34-2.73-0.34-2.73
			l-0.59-2.55c-0.42,0.17-0.73,0.55-0.8,1c-0.21,0.92-0.5,1.83-0.87,2.7l-0.39,2.79l0,0c0.04,0.09,0.04,0.2,0,0.29
			c-0.06,0.08-0.16,0.14-0.26,0.14h-0.42c-0.19,0-0.35-0.16-0.36-0.35c0-0.89-0.08-3.15-0.09-3.74c-0.02-0.09-0.02-0.18,0-0.27
			c0.12-0.5,0.57-2.29,0.57-2.29l0.18-1.5l-1.89,3.69v3.32c0,0,0,0.46-0.06,0.8c-0.02,0.21-0.21,0.36-0.42,0.33
			c-0.04,0-0.08-0.02-0.12-0.03c-0.26-0.13-0.56-0.31-0.57-0.63c0-0.61-0.35-2.48-0.35-2.48l0.23-2.45l0.69-1.85l-0.92,0.47
			c-0.24,0.12-0.39,0.36-0.41,0.63l-0.12,2.37c0,0,0,0.5,0,1c0,0.14-0.08,0.27-0.2,0.33c-0.12,0.07-0.27,0.07-0.39,0l-0.31-0.22
			l-0.28-3.39c0.03-0.6,0.22-1.19,0.53-1.71c0.47-0.62,1.25-1.51,1.25-1.51l2-1.14l2.47-1.85l1.11-1.52l1.52-2.82l1.35-12.68
			l-0.07-5.19l5.78-6.68L165.91,159.21z"/>
		<g>
			<path fill="#456584" d="M161.56,132.59c-3.71,2.85-5.32,8-5.32,8l1.14,22.23l-2.28,22l7.22,1.9l7.6-27.94l1.43-29.55l0,0
				c-2.5-1.49-5.7-1.05-7.7,1.07L161.56,132.59z"/>
			<path fill="#FFFFFF" d="M158.51,131.83c-0.57,1.4-1.06,2.84-1.47,4.3c-0.42,1.47-0.69,2.97-0.8,4.49
				c0.03-1.53,0.22-3.06,0.56-4.55c0.33-1.5,0.75-2.97,1.25-4.42c0.05-0.13,0.19-0.2,0.32-0.16c0.13,0.05,0.2,0.19,0.15,0.32l0,0
				L158.51,131.83z"/>
			<path fill="#FFFFFF" d="M160.11,134.22c-0.67,1.12-1.31,2.27-1.88,3.44c-0.61,1.15-1.07,2.37-1.37,3.63
				c-0.07,0.64-0.07,1.29,0,1.93l0.07,2l0.13,3.95l0.52,15.78l0,0l-2.37,17.68l2.19-17.71l0,0l-0.68-15.79l-0.18-3.94l-0.08-2
				c-0.07-0.66-0.07-1.34,0-2c0.29-1.31,0.75-2.57,1.36-3.76c0.57-1.2,1.2-2.36,1.88-3.5c0.07-0.12,0.22-0.16,0.34-0.09
				c0.12,0.07,0.16,0.23,0.09,0.35L160.11,134.22z"/>
		</g>
	</g>
	<polygon fill="#E38FB4" points="145.46,124.86 145.2,126.27 147.2,127.27 148.2,125.27 147.29,122.39 	"/>
</g>
</svg>

</div>


}
export const Face2 = () => {
setTimeout(() => {
    window.TweenMax.from('#smile', 0.8, {transformOrigin:'50% 50%', scale:0, repeat:-1, repeatDelay:1})
window.TweenMax.fromTo('#glasses', 0.7, {y:0}, {y:1.5, repeat:-1, repeatDelay:0.3, ease:window.Power0.easeNone, yoyo:true})
window.TweenMax.to('#ears >*:nth-child(1)', 1, {transformOrigin: '85% 0%', rotation:2, scale:0.95, repeat:-1, yoyo:true, ease:window.Power0.easeNone})
window.TweenMax.to('#ears >*:nth-child(2)', 1, {transformOrigin: '85% 0%', rotation:2, scale:0.95, repeat:-1, yoyo:true, ease:window.Power0.easeNone})

let del = 0
const stars = new window.TimelineMax({repeat:-1, repeatDelay:1})
for (let i = 1; i < 5; i++) {
    stars.to(`#stars >*:nth-child(${i})`, 0.6, {transformOrigin:'50% 50%', scale:0.5, repeat:-1, yoyo:true}, `stars+=${del}`)
    del += 0.4
}
}, 800);

    return <div >
        <svg  style={{minHeight:'450px',maxWidth:'100%',height:'450px',marginBottom:'1rem'}} version="1.1" id="face2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 81.4 312.9" enableBackground="new 0 0 81.4 312.9" xmlSpace="preserve">
<g>
	<g>
		<path fill="#F2B387" d="M26.7,133.6c-0.2-0.5-1.4-1.8-2.4-1s0.3,3.3,1.4,4.9s0.7,2.6,0.8,3.5s1,2.8,2.5,2.4s-0.9-7.4-0.9-7.4
			L26.7,133.6z"/>
		<path fill="#F2B387" d="M57.4,133.6c0.2-0.5,1.4-1.8,2.5-1s-0.3,3.3-1.4,4.9c-0.8,1.2-1.2,2.6-1.2,4c-0.1,1-0.6,2-2.1,1.9
			s0.9-7.4,0.9-7.4L57.4,133.6z"/>
		<path fill="#F2B387" d="M54.5,117.6L42,117.7l-12.4-0.1l-2.3,17.8l0.9,2.5c0.5,2.3,0.8,6.5,1,8.3s3.1,5.4,5.2,8.4s3.6,3.2,4.6,3.5
			c1,0.1,2,0.1,3,0c1,0.1,2,0.1,3,0c1-0.3,2.5-0.5,4.6-3.5s4.9-6.5,5.2-8.4s0.6-6,1-8.3l0.9-2.5L54.5,117.6z"/>
	</g>
	<g>
		<path fill="#F2B387" d="M57.6,119.9c-1-5-3.9-11.7-15.6-11.7s-14.6,6.7-15.6,11.7s0.1,10.9,0.9,15.6l14.8,0.1l14.7-0.1
			C57.5,130.8,58.6,124.8,57.6,119.9z"/>
		<path fill="#569700" d="M42,107.6c-6.2,0-9.9,2.2-12.2,5c3.8-0.4,7,4.4,12.2,4.1s8.2-4.7,12.2-4.1C51.9,109.8,48.2,107.6,42,107.6
			z"/>
		<path fill="#437800" d="M36.1,115c0.5,0.3,1.1,0.6,1.7,0.9c0.2-2,0.2-5.1,0.2-5.1C36.9,111.4,36.1,115,36.1,115z"/>
	</g>
	<g>
		<path fill="#DBC882" d="M20.1,141.2l0.3,0.5c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.5,0.3,0.8c0.2,0.7,0.5,1.3,0.6,2
			c0.1,0.4,0.2,0.8,0.3,1.2s0.1,0.8,0.2,1.2v0.6v0.6c0,0.4,0,0.8-0.1,1.2c0,0.4-0.1,0.7-0.2,1.1c-0.1,0.3-0.2,0.7-0.3,1
			c-0.1,0.3-0.2,0.5-0.4,0.8c-0.1,0.2-0.2,0.4-0.4,0.5l-0.4,0.4l0.4-0.4c0.1-0.2,0.2-0.4,0.4-0.5c0.1-0.2,0.2-0.5,0.3-0.8
			c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.4,0.1-0.7,0.1-1.1c0-0.4,0-0.8,0-1.2c0-0.2,0-0.4,0-0.6l-0.1-0.6c0-0.4-0.1-0.8-0.2-1.2
			s-0.2-0.8-0.3-1.1c-0.2-0.7-0.4-1.3-0.7-1.9c-0.1-0.3-0.2-0.5-0.3-0.7s-0.2-0.4-0.3-0.6l-0.3-0.5L20.1,141.2z"/>
		<path fill="#DBC882" d="M22.9,147.5v0.5c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5-0.1,0.8c-0.1,0.6-0.2,1.2-0.3,1.8c-0.1,0.3-0.2,0.7-0.3,1
			s-0.2,0.7-0.4,1c-0.1,0.3-0.3,0.7-0.4,1c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.4,0.5-0.6,0.8c-0.2,0.2-0.4,0.4-0.6,0.6
			c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.3,0.2-0.5,0.3l-0.5,0.2l0.5-0.2c0.2-0.1,0.3-0.2,0.5-0.3s0.4-0.3,0.6-0.5s0.4-0.4,0.6-0.6
			c0.2-0.3,0.4-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.5-0.9s0.3-0.6,0.4-1s0.2-0.7,0.3-1s0.2-0.7,0.2-1c0.1-0.6,0.2-1.2,0.2-1.8
			c0-0.2,0-0.5,0-0.7s0-0.4,0-0.6c0-0.3,0-0.5,0-0.5H22.9z"/>
		<path fill="#DBC882" d="M28.4,153.4v0.5c0,0.2,0,0.4,0,0.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.6-0.2,1.2-0.4,1.8c-0.1,0.3-0.2,0.7-0.3,1
			s-0.2,0.7-0.4,1c-0.1,0.3-0.3,0.7-0.4,1c-0.2,0.3-0.3,0.6-0.5,0.9c-0.2,0.3-0.4,0.5-0.6,0.8c-0.2,0.2-0.4,0.4-0.6,0.6
			c-0.2,0.2-0.4,0.3-0.6,0.4s-0.4,0.2-0.5,0.3l-0.5,0.2L24,163c0.1-0.1,0.3-0.2,0.5-0.3s0.4-0.3,0.6-0.5s0.4-0.4,0.6-0.6
			c0.2-0.3,0.4-0.5,0.5-0.8c0.2-0.3,0.3-0.6,0.5-0.9s0.3-0.6,0.4-1s0.2-0.7,0.3-1s0.2-0.7,0.2-1c0.1-0.6,0.2-1.2,0.2-1.8
			c0-0.3,0-0.5,0-0.7s0-0.4,0-0.6c0-0.3,0-0.5,0-0.5L28.4,153.4z"/>
		<path fill="#DBC882" d="M30.3,151.6l0.1,0.5c0,0.2,0,0.4,0,0.6s0,0.5,0,0.8c0,0.6,0,1.2-0.1,1.9c0,0.3-0.1,0.7-0.1,1
			s-0.1,0.7-0.3,1.1s-0.2,0.7-0.3,1c-0.1,0.3-0.3,0.7-0.4,1s-0.3,0.6-0.5,0.8s-0.3,0.5-0.5,0.7s-0.3,0.3-0.5,0.5
			c-0.2,0.1-0.3,0.2-0.5,0.3l-0.5,0.2l0.4-0.2c0.2-0.1,0.3-0.2,0.5-0.3c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.3-0.5,0.5-0.7
			c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.6,0.4-1c0.1-0.3,0.2-0.7,0.3-1c0.1-0.3,0.1-0.7,0.2-1s0.1-0.7,0.1-1c0-0.6,0-1.2,0-1.8
			c0-0.2,0-0.5-0.1-0.7c0-0.2,0-0.4-0.1-0.6l-0.1-0.5L30.3,151.6z"/>
		<path fill="#DBC882" d="M31,150.4l0.1,0.5c0,0.1,0,0.3,0,0.6c0,0.2,0,0.5,0,0.8c0,0.6,0,1.2-0.1,1.8c0,0.3-0.1,0.7-0.1,1
			s-0.1,0.7-0.3,1.1s-0.2,0.7-0.3,1c-0.1,0.3-0.3,0.7-0.4,1s-0.3,0.6-0.5,0.8s-0.3,0.5-0.5,0.7s-0.3,0.3-0.5,0.5
			c-0.1,0.1-0.3,0.2-0.5,0.3l-0.5,0.2l0.4-0.2c0.1-0.1,0.3-0.2,0.5-0.3s0.3-0.3,0.5-0.5s0.3-0.5,0.5-0.7c0.2-0.3,0.3-0.6,0.4-0.9
			c0.1-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.2-0.7,0.3-1c0.1-0.3,0.1-0.7,0.2-1s0.1-0.7,0.1-1c0-0.6,0-1.2,0-1.8c0-0.2,0-0.5-0.1-0.7
			c0-0.2,0-0.4-0.1-0.6s-0.1-0.5-0.1-0.5L31,150.4z"/>
		<path fill="#DBC882" d="M25.5,152.6c0,0,0,0.2,0.1,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0,0.5,0,0.8c0,0.6,0,1.2-0.1,1.9
			c0,0.3-0.1,0.7-0.1,1s-0.1,0.7-0.2,1s-0.2,0.7-0.3,1c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.3-0.3,0.6-0.5,0.9c-0.2,0.2-0.3,0.5-0.5,0.7
			s-0.3,0.4-0.5,0.5s-0.3,0.2-0.5,0.3l-0.5,0.2l0.4-0.2c0.1-0.1,0.3-0.2,0.5-0.3c0.2-0.2,0.3-0.3,0.5-0.5s0.3-0.5,0.5-0.7
			c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.3,0.3-0.7,0.4-1s0.2-0.7,0.3-1c0.1-0.3,0.1-0.7,0.2-1s0.1-0.7,0.1-1c0-0.6,0-1.2,0-1.8
			c0-0.3,0-0.5-0.1-0.7c0-0.2,0-0.4-0.1-0.6l-0.1-0.5L25.5,152.6z"/>
		<path fill="#DBC882" d="M24.3,153.8c0,0,0,0.2-0.1,0.5s-0.1,0.3-0.2,0.6c-0.1,0.2-0.2,0.5-0.3,0.7c-0.2,0.6-0.5,1.1-0.9,1.6
			c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.4,0.6-0.6,0.9c-0.2,0.3-0.5,0.6-0.7,0.8s-0.5,0.5-0.8,0.7s-0.5,0.4-0.8,0.6
			c-0.2,0.2-0.5,0.3-0.8,0.4c-0.2,0.1-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1h-0.5h0.5c0.2,0,0.4-0.1,0.5-0.1c0.2-0.1,0.5-0.2,0.7-0.3
			c0.3-0.1,0.5-0.3,0.7-0.4c0.3-0.2,0.5-0.4,0.7-0.6c0.3-0.2,0.5-0.5,0.7-0.7c0.2-0.3,0.5-0.5,0.7-0.8s0.4-0.6,0.6-0.9
			s0.4-0.6,0.5-0.9c0.3-0.5,0.5-1.1,0.8-1.6c0.1-0.3,0.2-0.5,0.2-0.7c0.1-0.2,0.1-0.4,0.2-0.5c0.1-0.3,0.1-0.5,0.1-0.5H24.3z"/>
		<path fill="#DBC882" d="M64.1,154.4l-0.5-0.3c-0.1-0.1-0.3-0.3-0.5-0.5s-0.3-0.4-0.5-0.7c-0.3-0.6-0.6-1.3-0.8-2
			c-0.1-0.4-0.2-0.8-0.2-1.2l-0.1-0.6v-0.6c0-0.4,0-0.8,0-1.2s0-0.8,0.1-1.2c0.1-0.7,0.2-1.4,0.3-2.1c0.1-0.3,0.1-0.6,0.2-0.8
			c0-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.5,0.2-0.5s-0.1,0.2-0.2,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.3-0.1,0.6-0.2,0.8
			c-0.1,0.7-0.2,1.4-0.2,2.1c0,0.4,0,0.8,0,1.2s0,0.8,0.1,1.2l0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.4,0.2,0.8,0.3,1.1s0.2,0.7,0.4,1
			c0.1,0.3,0.3,0.6,0.5,0.9c0.1,0.2,0.3,0.4,0.5,0.6c0.2,0.2,0.3,0.3,0.4,0.4l0.5,0.3L64.1,154.4z"/>
		<path fill="#DBC882" d="M62.5,143.2l-0.2,0.5c-0.1,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.1,0.5-0.2,0.8c-0.1,0.7-0.2,1.4-0.3,2.1
			c0,0.4,0,0.8,0,1.2s0,0.8,0,1.2l0.1,0.6l0.1,0.6c0.1,0.4,0.2,0.8,0.3,1.1s0.2,0.7,0.4,1c0.1,0.3,0.3,0.6,0.4,0.9
			c0.2,0.3,0.3,0.5,0.5,0.7s0.3,0.3,0.5,0.4l0.5,0.3l-0.5-0.3c-0.2-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.4-0.4-0.5-0.6
			c-0.2-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.3-0.7-0.4-1c-0.1-0.4-0.2-0.8-0.3-1.2l-0.1-0.6l-0.1-0.6c-0.1-0.4-0.1-0.8-0.1-1.2
			s0-0.8,0-1.2c0-0.7,0.1-1.4,0.2-2.1c0-0.3,0.1-0.6,0.2-0.8c0-0.3,0.1-0.5,0.2-0.6c0.1-0.3,0.2-0.5,0.2-0.5H62.5z"/>
		<path fill="#DBC882" d="M64.4,141.2l-0.3,0.4c-0.1,0.2-0.2,0.3-0.3,0.5s-0.2,0.4-0.3,0.6c-0.3,0.6-0.5,1.1-0.7,1.7
			c-0.1,0.3-0.2,0.7-0.3,1s-0.2,0.7-0.2,1.1c-0.1,0.4-0.1,0.7-0.1,1.1c0,0.4,0,0.7,0,1.1c0,0.3,0,0.7,0.1,1c0,0.3,0.1,0.6,0.2,0.9
			c0.1,0.2,0.2,0.5,0.3,0.7s0.2,0.3,0.3,0.5l0.3,0.4l-0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.5s-0.2-0.4-0.3-0.7s-0.2-0.6-0.2-0.9
			c-0.1-0.3-0.1-0.7-0.1-1c0-0.4,0-0.7,0-1.1c0-0.4,0-0.7,0.1-1.1c0-0.4,0.1-0.7,0.2-1.1s0.2-0.7,0.3-1.1c0.2-0.6,0.4-1.2,0.6-1.8
			c0.1-0.3,0.2-0.5,0.3-0.7s0.2-0.4,0.3-0.5l0.3-0.4L64.4,141.2z"/>
		<path fill="#DBC882" d="M59.7,149.4l0.1,0.5c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.2,0.6,0.3,0.9
			c0.1,0.3,0.2,0.6,0.3,0.9s0.3,0.6,0.5,1l0.3,0.5l0.3,0.5c0.2,0.3,0.4,0.6,0.6,0.9l0.4,0.4l0.4,0.4c0.2,0.2,0.5,0.4,0.8,0.6
			c0.2,0.2,0.5,0.3,0.8,0.4c0.2,0.1,0.5,0.2,0.7,0.3s0.4,0.1,0.6,0.1l0.5,0.1l-0.5-0.1c-0.2,0-0.4-0.1-0.6-0.1
			c-0.2-0.1-0.5-0.1-0.7-0.3c-0.3-0.1-0.5-0.3-0.8-0.4c-0.3-0.2-0.6-0.4-0.8-0.6c-0.1-0.1-0.3-0.2-0.4-0.4s-0.3-0.3-0.4-0.4
			c-0.3-0.3-0.5-0.6-0.7-0.9l-0.3-0.5l-0.3-0.5c-0.2-0.3-0.3-0.6-0.5-1s-0.3-0.6-0.4-0.9s-0.2-0.6-0.3-0.9c-0.2-0.5-0.3-1-0.3-1.3
			s-0.1-0.5-0.1-0.5H59.7z"/>
		<path fill="#DBC882" d="M55.8,152.4c0,0,0,0.2,0,0.5c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.5,0.1,0.8c0.1,0.6,0.3,1.3,0.5,1.9
			c0.1,0.3,0.2,0.7,0.4,1s0.3,0.7,0.5,1l0.3,0.5l0.3,0.5c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.3,0.4,0.5,0.7,0.8c0.2,0.2,0.4,0.4,0.7,0.6
			c0.2,0.2,0.4,0.3,0.7,0.4c0.2,0.1,0.4,0.2,0.5,0.2l0.5,0.1l-0.5-0.1c-0.2,0-0.3-0.1-0.6-0.2c-0.2-0.1-0.5-0.2-0.7-0.4
			c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3-0.2-0.5-0.5-0.7-0.8s-0.5-0.6-0.7-0.9l-0.3-0.5l-0.3-0.5c-0.2-0.3-0.3-0.7-0.5-1s-0.3-0.7-0.4-1
			c-0.2-0.6-0.4-1.3-0.6-1.9c-0.1-0.3-0.1-0.6-0.2-0.8c0-0.2-0.1-0.4-0.1-0.6c0-0.3-0.1-0.5-0.1-0.5H55.8z"/>
		<path fill="#DBC882" d="M53.8,151.1l-0.1,0.5c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.5-0.1,0.7c0,0.6,0,1.2,0,1.8c0,0.3,0.1,0.7,0.1,1
			s0.1,0.7,0.2,1c0.1,0.3,0.2,0.7,0.3,1s0.2,0.6,0.3,1c0.2,0.6,0.6,1.1,0.9,1.6c0.2,0.2,0.3,0.4,0.5,0.5c0.1,0.1,0.3,0.2,0.5,0.3
			l0.4,0.2l-0.5-0.2c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.2-0.4-0.3-0.5-0.5c-0.2-0.2-0.4-0.4-0.5-0.7c-0.2-0.3-0.3-0.6-0.5-0.9
			c-0.2-0.3-0.3-0.7-0.4-1c-0.1-0.3-0.2-0.7-0.3-1s-0.2-0.7-0.3-1s-0.1-0.7-0.2-1c-0.1-0.6-0.1-1.2-0.1-1.9c0-0.3,0-0.5,0-0.8
			s0-0.4,0-0.6c0-0.3,0.1-0.5,0.1-0.5L53.8,151.1z"/>
		<path fill="#DBC882" d="M54.5,150.4c0,0,0,0.2-0.1,0.5c0,0.2-0.1,0.4-0.1,0.6c0,0.2-0.1,0.5-0.1,0.7c0,0.6,0,1.2,0,1.8
			c0,0.3,0.1,0.7,0.1,1s0.1,0.7,0.2,1c0.1,0.3,0.2,0.7,0.3,1s0.2,0.7,0.3,1s0.3,0.6,0.4,0.9c0.1,0.2,0.3,0.5,0.5,0.7
			s0.3,0.4,0.5,0.5s0.3,0.3,0.5,0.3l0.4,0.2l-0.5-0.2c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.2-0.4-0.3-0.5-0.5c-0.2-0.2-0.4-0.4-0.5-0.7
			c-0.2-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.3-0.7-0.4-1c-0.1-0.3-0.2-0.7-0.3-1s-0.2-0.7-0.2-1.1s-0.1-0.7-0.2-1
			c-0.1-0.6-0.1-1.2-0.1-1.9c0-0.3,0-0.5,0-0.8s0-0.4,0.1-0.6c0-0.3,0.1-0.5,0.1-0.5L54.5,150.4z"/>
		<path fill="#DBC882" d="M56.5,152.4l0.2,0.6c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.2,0.6,0.3,0.9s0.3,0.6,0.4,1s0.3,0.7,0.5,1.1
			s0.4,0.7,0.6,1.1s0.5,0.7,0.8,1.1l0.4,0.5l0.4,0.5c0.3,0.3,0.6,0.6,0.9,0.9c0.3,0.3,0.6,0.5,1,0.8c0.3,0.2,0.6,0.4,0.9,0.6
			c0.3,0.1,0.6,0.3,0.8,0.3s0.5,0.1,0.7,0.1h0.6h-0.6c-0.2,0-0.5-0.1-0.7-0.1c-0.7-0.2-1.3-0.5-1.8-0.8c-0.4-0.2-0.7-0.5-1-0.7
			c-0.4-0.3-0.7-0.6-1-0.9c-0.2-0.2-0.3-0.3-0.5-0.5l-0.4-0.5c-0.3-0.4-0.5-0.7-0.8-1.1s-0.5-0.7-0.7-1.1s-0.4-0.7-0.6-1.1
			s-0.3-0.7-0.5-1s-0.2-0.6-0.3-0.9c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.4-0.2-0.6-0.2-0.6L56.5,152.4z"/>
		<path fill="#DBC882" d="M57.8,153.5c0,0,0.1,0.2,0.2,0.5c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.3,0.2,0.5,0.3,0.7
			c0.1,0.3,0.3,0.6,0.5,0.9c0.2,0.3,0.4,0.6,0.6,0.9s0.5,0.6,0.8,0.9s0.6,0.6,0.9,0.9s0.6,0.5,1,0.8s0.7,0.5,1,0.7s0.7,0.4,1,0.5
			s0.6,0.3,0.9,0.4c0.3,0.1,0.5,0.2,0.8,0.3c0.2,0.1,0.4,0.1,0.6,0.1l0.6,0.1l-0.6-0.1c-0.2,0-0.4-0.1-0.7-0.1
			c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.6-0.2-0.9-0.4s-0.7-0.3-1-0.5s-0.7-0.4-1-0.6s-0.7-0.5-1-0.7s-0.6-0.5-0.9-0.8
			s-0.6-0.6-0.8-0.9s-0.5-0.6-0.7-0.9c-0.2-0.3-0.4-0.6-0.5-0.9c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.2-0.2-0.4-0.3-0.6l-0.2-0.5
			L57.8,153.5z"/>
		<path fill="#DBC882" d="M18.8,139.5l0.3,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.3,0.4,0.4,0.7c0.3,0.5,0.5,1.1,0.8,1.7
			c0.1,0.3,0.2,0.6,0.3,1s0.2,0.7,0.3,1c0.1,0.3,0.1,0.7,0.2,1.1c0,0.3,0.1,0.7,0.1,1s0,0.7,0,1s-0.1,0.6-0.1,0.9
			c-0.1,0.2-0.1,0.5-0.2,0.7s-0.2,0.4-0.3,0.5l-0.3,0.4l0.3-0.4c0.1-0.1,0.1-0.3,0.3-0.5c0.1-0.2,0.2-0.5,0.2-0.7
			c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3,0-0.6,0-0.9c0-0.3-0.1-0.7-0.1-1c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.4-0.2-0.7-0.3-1s-0.2-0.6-0.4-0.9
			c-0.2-0.6-0.5-1.1-0.8-1.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.1-0.2-0.2-0.3-0.3-0.4l-0.3-0.4L18.8,139.5z"/>
		<path fill="#DBC882" d="M18.4,140.1c3,3.5,7.8,16.4-0.4,17.7c2.2,0.1,4.3-1.2,5-3.3c0,0-1.6,5.1-6.9,5.5c2,0.1,4-0.7,5.4-2.3
			c-1.6,1.9-3.9,3.3-6.4,3.7c0,0,4.3,0.2,8.6-4.1c-1.3,2.2-3.2,3.9-5.4,5c2.4-0.5,4.6-1.8,6.2-3.7c-0.9,1.6-2.2,2.9-3.9,3.7
			c2.4-0.7,4.5-2.1,6-4.2c0,0-1.7,3-3.4,3.9c1.7-0.9,3.1-2.2,4.1-3.8c0.1,1.5-0.6,3-1.8,3.9c0,0,5.4-2.2,5.3-10.4s-1.2-5.5-2.2-8.8
			s-3-13.6-2.9-17s2.4-7.1,3.4-7.2s-4.2-1-4.2-1l-4.3,3l-2.8,9.3C16.4,133.4,16.6,137.1,18.4,140.1z"/>
		<path fill="#DBC882" d="M63.4,121.6c2.1,5.9,4.8,14.1,0.6,20.7s-3.2,13.3,2.6,15.2c-2,0-4-1-5.1-2.7c0,0,1.2,3.9,6.6,4.9
			c-2.4,0.1-4.8-0.7-6.6-2.3c0,0,2.1,3.1,7.3,3.9c-2.9,0-5.8-1.1-8-3c0,0,1,2.1,5.6,3.8c-2.7-0.3-5.3-1.5-7.2-3.6
			c0,0,0.4,1.7,3.5,3.1c0,0-7.9-1.9-9.3-9.8c-1.3-7.4,3.6-13.7,4.5-16.9s-0.3-14.6-3.1-17.8s3.7-2.9,3.7-2.9S62.3,118.1,63.4,121.6z
			"/>
		<g>
			<path fill="#F2B387" d="M26,133.4c-0.2-0.5-1.4-1.8-2.5-1s0.4,3.3,1.5,5s0.7,2.6,0.8,3.6s1,2.8,2.5,2.4s-0.9-7.5-0.9-7.5
				L26,133.4z"/>
			<path fill="#F2B387" d="M57.2,133.4c0.2-0.5,1.4-1.8,2.5-1s-0.3,3.3-1.5,5c-0.8,1.2-1.2,2.7-1.3,4.1c-0.1,1-0.6,2.1-2.1,1.9
				s0.9-7.5,0.9-7.5L57.2,133.4z"/>
		</g>
		<path fill="#DBC882" d="M28,140.7c0.1-1.7,0.1-3.4,0-5.1c0-1.5,0.8-2.5,0.5-4.1c-0.2-1.4,0.1-2.9,0.8-4.1c0,0-0.5-0.6-0.6-2.8
			s0.7-5,3.4-5.4l-3.9-1.4h-4.7l0.4,9.7l2.2,5.7c0.5,0.9,0.8,2,0.9,3C27.2,137.7,27.6,139.3,28,140.7z"/>
		<path fill="#DBC882" d="M52.1,119.1c2,0.4,3.6,3.6,3.2,7.1c-0.3,3.1-0.2,6.3,0.1,9.4c0.1,0.9,0.4,1.9,0.4,1.9
			c0.1-1.5,0.7-2.9,1.5-4.1c1.1-1.6,4.1-2.5,4-3c-0.1-0.3-0.4-6.6-1.7-10.3c-0.5-1.6-1.7-2.8-3.3-3.4l-3,0.3L52.1,119.1z"/>
		<path fill="#FFE997" d="M34.9,118.8c-2.5,1.6-9.5-2-12.6,1.4s0,6.8-3.1,10.7c-3.1,3.9-0.8,9.3-0.8,9.3s-3.6-4.2-1.6-9.3
			s1.8-9.3,3.7-13.8c2.2-4.8,7.2-7.7,12.5-7.3L34.9,118.8z"/>
		<path fill="#FFE997" d="M45.7,119.7c0.3-0.8,1.5-0.8,2.3-0.4s3,0.8,4.1-0.2s7.3-6.3,11.3,2.5c0,0-0.1-5.1-6.1-9.1
			c-4-2.7-4.8-4.8-9.4-5.8c-6.9-1.6-14.8,0.2-16.5,5.7s5,7,5,7c-0.1-0.3,0.3-0.7,1.1-0.1c0,0,0.7-1.2,2.2,0c0,0-0.1-0.7,0.8-0.8
			s1.7,1.1,1.8,1.2c0.3-0.4,0.9-0.4,1.3-0.1C43.4,119.6,43.5,117.9,45.7,119.7z"/>
		<path fill="#FFF0B8" d="M49.5,118.8l0.3-0.1c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.2,0.4-0.3s0.3-0.3,0.4-0.4l0.5-0.5
			c0.3-0.3,0.6-0.7,1-1c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.3l0.3-0.1h0.3c0.2,0,0.3,0,0.5,0
			c0.1,0,0.3,0,0.4,0.1l0.3,0.1h-0.3c-0.1,0-0.2,0-0.4,0s-0.3,0-0.5,0.1l-0.3,0.1l-0.3,0.1c-0.2,0.1-0.4,0.2-0.5,0.3
			c-0.4,0.3-0.7,0.6-1,0.9s-0.7,0.7-1,1s-0.6,0.5-1,0.8c-0.1,0.1-0.3,0.2-0.5,0.2c-0.1,0.1-0.2,0.1-0.4,0.1L49.5,118.8z"/>
		<path fill="#FFF0B8" d="M45.8,118.1v-0.2c0-0.2,0-0.3,0-0.5c0-0.3,0.1-0.5,0.2-0.8c0.1-0.3,0.3-0.6,0.5-0.9s0.5-0.7,0.8-0.9
			c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.5,1.3-0.6c0.5-0.2,1-0.3,1.5-0.3c0.2,0,0.5,0,0.8,0s0.5,0,0.8,0c0.5,0,1,0.1,1.5,0.2
			c0.8,0.2,1.6,0.6,2.3,1.1c0.3,0.2,0.5,0.4,0.8,0.7c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.1,0.2,0.3,0.3,0.4l0.1,0.2c0,0-0.2-0.2-0.5-0.5
			c-0.4-0.4-0.9-0.8-1.4-1.1c-0.7-0.5-1.5-0.8-2.3-1c-0.5-0.1-0.9-0.2-1.4-0.2c-0.2,0-0.5,0-0.7,0s-0.5,0-0.7,0
			c-0.5,0.1-1,0.1-1.4,0.3c-0.4,0.1-0.9,0.3-1.3,0.6c-0.4,0.2-0.8,0.5-1.1,0.7s-0.6,0.5-0.8,0.8c-0.4,0.5-0.6,1-0.8,1.6
			c-0.1,0.2-0.1,0.3-0.1,0.5C45.8,118,45.8,118.1,45.8,118.1z"/>
		<path fill="#FFF0B8" d="M47.2,118.3v-0.1c0-0.1,0.1-0.3,0.1-0.4c0.2-0.4,0.4-0.9,0.7-1.3c0.2-0.3,0.4-0.5,0.6-0.8
			c0.2-0.3,0.5-0.5,0.8-0.8s0.6-0.5,1-0.6c0.4-0.2,0.7-0.3,1.1-0.4c0.4-0.1,0.8-0.1,1.2-0.1s0.8,0,1.2,0c1.2,0.1,2.3,0.6,3.1,1.4
			c0.3,0.2,0.4,0.4,0.4,0.4l-0.4-0.3c-0.4-0.3-0.8-0.5-1.2-0.7c-0.6-0.3-1.2-0.4-1.9-0.4c-0.4,0-0.7,0-1.1,0c-0.4,0-0.7,0.1-1.1,0.1
			c-0.4,0.1-0.7,0.2-1,0.4c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.5,0.4-0.8,0.7c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.2-0.3,0.4-0.5,0.6
			c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.4C47.2,118.2,47.2,118.2,47.2,118.3z"/>
		<path fill="#FFF0B8" d="M35.7,118.3c-0.2,0-0.4,0-0.5-0.1c-0.5-0.1-0.9-0.3-1.3-0.7c-0.2-0.2-0.5-0.4-0.6-0.7
			c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.2-0.2-0.3-0.2-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0.1-0.8,0.2-1.2
			c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.2-0.5c0.2-0.3,0.3-0.6,0.6-0.9c0.2-0.3,0.4-0.5,0.6-0.7s0.4-0.4,0.6-0.5
			s0.4-0.3,0.5-0.4l0.4-0.3l-0.4,0.4c-0.2,0.1-0.3,0.3-0.4,0.4l-0.5,0.6c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.3-0.3,0.6-0.5,0.9
			c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.2,0.5c-0.1,0.4-0.1,0.7-0.1,1.1s0,0.7,0.1,1.1c0,0.2,0.1,0.4,0.2,0.5
			c0.1,0.2,0.1,0.3,0.2,0.5c0.1,0.3,0.3,0.6,0.5,0.9s0.4,0.5,0.6,0.7c0.3,0.3,0.7,0.6,1.1,0.8C35.4,118.2,35.5,118.3,35.7,118.3z"/>
		<path fill="#FFF0B8" d="M39.1,118.6c-0.2-0.1-0.5-0.2-0.7-0.4c-0.6-0.4-1.1-0.9-1.5-1.5c-0.3-0.4-0.5-0.8-0.7-1.3s-0.3-1-0.4-1.6
			c-0.1-0.6-0.1-1.2,0-1.7c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.2-0.6,0.3-0.8c0.6-1.1,1.4-1.9,2.5-2.5c0.9-0.5,1.9-0.8,2.9-1
			c0.7-0.1,1.4-0.2,2.1-0.2c0.2,0,0.4,0,0.6,0.1h0.2c-0.3,0-0.5,0-0.8,0c-0.7,0-1.4,0.1-2.1,0.3c-0.9,0.2-1.9,0.6-2.7,1.1
			c-0.5,0.3-0.9,0.6-1.3,1c-0.4,0.4-0.7,0.9-1,1.4c-0.1,0.2-0.2,0.5-0.3,0.8s-0.1,0.6-0.2,0.8c-0.1,0.5-0.1,1.1,0,1.6
			c0.1,1,0.4,1.9,0.9,2.8c0.4,0.6,0.8,1.1,1.4,1.6C38.7,118.3,38.9,118.5,39.1,118.6z"/>
		<path fill="#FFF0B8" d="M37.1,118.1L37.1,118.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.3-0.3-0.6-0.6-0.9-1c-0.2-0.2-0.3-0.5-0.5-0.8
			c-0.1-0.2-0.2-0.3-0.2-0.4s-0.1-0.3-0.2-0.5c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1-0.2-0.1-0.3-0.1-0.5s0-0.4-0.1-0.6s0-0.4,0-0.6
			s0-0.4,0-0.6s0-0.4,0.1-0.6l0.2-0.5l0.1-0.3l0.1-0.3c0.1-0.2,0.2-0.3,0.2-0.5s0.2-0.3,0.3-0.4c0.2-0.3,0.3-0.5,0.5-0.7
			s0.3-0.4,0.5-0.6L37,108l0.4-0.3l-0.3,0.4l-0.4,0.4c-0.2,0.2-0.3,0.4-0.5,0.6s-0.3,0.5-0.5,0.7c-0.2,0.3-0.3,0.6-0.4,0.9
			c-0.1,0.3-0.2,0.7-0.3,1c0,0.2,0,0.3-0.1,0.5c0,0.2,0,0.4,0,0.5c0,0.2,0,0.3,0,0.5s0,0.3,0.1,0.5c0.1,0.3,0.1,0.7,0.2,1l0.2,0.5
			l0.2,0.4c0.1,0.3,0.3,0.5,0.4,0.8c0.2,0.2,0.3,0.4,0.4,0.6s0.3,0.3,0.4,0.4S37,118,37.1,118.1L37.1,118.1z"/>
		<path fill="#DBC882" d="M53.9,151.3c-0.5,0.7-1.2,6.3,5.7,10.4c0,0-4.1-3.2-3.4-6.3C56.5,153.6,55.6,151.8,53.9,151.3z"/>
		<path fill="#DBC882" d="M65.4,129.3l0.2,0.8c0.1,0.3,0.1,0.6,0.2,0.9c0.1,0.4,0.1,0.8,0.1,1.2c0,0.2,0,0.4,0,0.7v0.7
			c0,0.5,0,1-0.1,1.6s-0.1,1.1-0.3,1.6s-0.2,1.1-0.4,1.7c-0.3,1.1-0.6,2.2-0.9,3.2c-0.1,0.5-0.3,1-0.3,1.5s0,0.9,0,1.4
			c0,0.7,0.2,1.4,0.5,2c0.1,0.2,0.3,0.4,0.5,0.6c-0.2-0.2-0.4-0.4-0.5-0.6c-0.3-0.6-0.5-1.3-0.5-2c0-0.5,0-0.9,0-1.4
			c0.1-0.5,0.2-1,0.3-1.5c0.3-1,0.6-2.1,0.8-3.3c0.1-0.6,0.3-1.1,0.3-1.7s0.2-1.1,0.2-1.6s0.1-1.1,0-1.5c0-0.2,0-0.5,0-0.7
			s0-0.4,0-0.7c0-0.4-0.1-0.8-0.2-1.1c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.5-0.2-0.8-0.2-0.8L65.4,129.3z"/>
		<path fill="#DBC882" d="M64,124.2c0,0,0.2,0.2,0.5,0.7c0.2,0.3,0.4,0.6,0.5,0.8c0.2,0.4,0.4,0.7,0.6,1.1c0.2,0.5,0.4,0.9,0.5,1.4
			c0.2,0.5,0.3,1.1,0.3,1.7c0.1,1.2,0.1,2.4-0.1,3.7c-0.2,1.2-0.4,2.4-0.8,3.6c-0.3,1-0.5,2-0.7,3.1c0,0.4,0,0.8,0.1,1.2
			c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.2,0.3,0.3,0.5,0.4l0.2,0.1c-0.1,0-0.1,0-0.2-0.1c-0.2-0.1-0.4-0.2-0.5-0.4
			c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.4-0.2-0.8-0.2-1.3c0.1-1.1,0.3-2.1,0.6-3.1c0.3-1.2,0.5-2.4,0.7-3.6c0.2-1.2,0.2-2.4,0-3.6
			c-0.1-1-0.4-2-0.9-3c-0.2-0.4-0.4-0.7-0.6-1.1c-0.2-0.3-0.4-0.6-0.5-0.8l-0.5-0.7L64,124.2z"/>
		<path fill="#FFE997" d="M22.9,114.4l-1.3,2.5c-0.8,1.5-1.9,3.4-2.9,5.4c-0.2,0.5-0.5,1-0.7,1.5s-0.4,1-0.6,1.5s-0.3,1-0.4,1.4
			s-0.2,0.9-0.3,1.3c0,0.4-0.1,0.8-0.1,1.1s0,0.6,0,0.9c0,0.5,0.1,0.8,0.1,0.8s0-0.3-0.1-0.8c0-0.2,0-0.5,0-0.9s0-0.7,0-1.2
			s0.1-0.9,0.2-1.3c0.1-0.5,0.2-1,0.4-1.5c0.2-0.5,0.3-1,0.5-1.5s0.4-1,0.6-1.5c0.9-2,2-4,2.7-5.5l1.3-2.5L22.9,114.4z"/>
		<path fill="#FFE997" d="M17.7,129.3c0,0,0-0.3,0.1-0.8s0.1-1.3,0.1-2.2c0-2.2,0.3-4.5,0.7-6.7c0.2-0.6,0.4-1.2,0.6-1.7
			s0.5-1.1,0.8-1.6s0.6-0.9,1-1.3c0.3-0.4,0.7-0.7,1.1-1c0.3-0.3,0.7-0.5,1-0.7s0.6-0.3,0.9-0.5l0.8-0.3L25,113l-0.8,0.3
			c-0.2,0.1-0.5,0.2-0.8,0.4c-0.3,0.2-0.7,0.4-1,0.7c-0.4,0.3-0.7,0.6-1.1,1s-0.7,0.8-1,1.3s-0.6,1-0.8,1.5
			c-0.2,0.5-0.4,1.1-0.6,1.7c-0.6,2.2-0.9,4.4-0.9,6.6c-0.1,0.9-0.1,1.7-0.2,2.2S17.7,129.3,17.7,129.3z"/>
		<path fill="#FFE997" d="M62.8,118.8l-0.1-0.2c0-0.1,0-0.3-0.2-0.5c-0.2-0.6-0.4-1.1-0.7-1.7c-0.4-0.8-0.8-1.5-1.3-2.1
			c-0.6-0.8-1.3-1.4-2.1-1.9c-0.4-0.2-0.9-0.4-1.3-0.5c-0.4-0.1-0.9-0.2-1.4-0.3c-0.4,0-0.9,0-1.3,0c-0.2,0-0.4,0-0.6,0.1
			c-0.2,0-0.4,0-0.6,0.1c-0.6,0.1-1.1,0.3-1.7,0.4l-0.6,0.2l-0.2-0.5l0.7-0.2c0.6-0.2,1.2-0.3,1.8-0.4l0.6-0.1c0.2,0,0.4,0,0.6,0
			c0.5,0,0.9,0,1.4,0.1c0.2,0,0.5,0.1,0.7,0.1c0.2,0.1,0.5,0.1,0.7,0.2c0.5,0.2,0.9,0.4,1.4,0.7c0.8,0.5,1.6,1.2,2.1,2
			c0.5,0.7,0.9,1.4,1.3,2.1c0.2,0.6,0.4,1.1,0.6,1.7l0.1,0.5L62.8,118.8z"/>
		<path fill="#FFF0B8" d="M42.3,118.3c0,0-0.2-0.3-0.5-0.8c-0.1-0.3-0.3-0.6-0.5-1s-0.4-0.9-0.5-1.3c-0.2-0.5-0.3-1.1-0.3-1.7
			s0.1-1.3,0.3-1.9c0.3-0.6,0.7-1.2,1.2-1.7c0.3-0.2,0.5-0.5,0.8-0.7c0.1-0.1,0.3-0.2,0.4-0.3l0.5-0.3c1.3-0.6,2.7-0.8,4.1-0.6
			c0.6,0.1,1.2,0.3,1.8,0.5c0.5,0.2,1,0.5,1.5,0.8c0.4,0.2,0.8,0.5,1.2,0.8c0.3,0.2,0.6,0.5,0.8,0.7c0.5,0.4,0.7,0.7,0.7,0.7
			l-0.8-0.6c-0.2-0.2-0.5-0.4-0.9-0.6s-0.8-0.5-1.2-0.7c-0.5-0.3-1-0.5-1.5-0.7c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.2
			c-0.6-0.1-1.3-0.1-1.9,0c-0.7,0.1-1.3,0.3-1.9,0.6l-0.5,0.2c-0.2,0.1-0.3,0.2-0.4,0.3c-0.3,0.2-0.5,0.4-0.8,0.6
			c-0.5,0.4-0.9,1-1.1,1.6c-0.2,0.6-0.3,1.2-0.3,1.8c0,0.5,0.1,1.1,0.2,1.6c0.1,0.5,0.3,0.9,0.4,1.3c0.2,0.4,0.3,0.7,0.4,1
			C42.2,118,42.3,118.3,42.3,118.3z"/>
		<path fill="#FFF0B8" d="M42.9,118.3c-0.2-0.5-0.4-1-0.5-1.4c-0.1-0.5-0.2-1-0.3-1.6s-0.1-1.2,0-1.8c0-0.3,0.1-0.6,0.2-0.9
			c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.2,0.3-0.5,0.5-0.7s0.4-0.4,0.6-0.5c0.2-0.1,0.4-0.2,0.6-0.3l0.5-0.2l0.4-0.1l-0.4,0.2
			c-0.3,0.2-0.6,0.4-0.9,0.6c-0.2,0.2-0.3,0.3-0.5,0.5s-0.3,0.4-0.4,0.7c-0.3,0.5-0.4,1.1-0.5,1.7s-0.1,1.2-0.1,1.8
			c0,0.5,0.1,1,0.2,1.5C42.7,117.7,42.9,118.3,42.9,118.3z"/>
		<path fill="#FFF0B8" d="M44.9,118.4c0-0.2,0-0.3,0-0.5s0-0.4,0-0.6c0-0.3,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.6,0.2-0.9
			c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.2,0.1-0.3,0.3-0.5l0.3-0.5l0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.3,0.5-0.3l0.5-0.3
			l0.3-0.1l0.3-0.1c0.3-0.1,0.7-0.2,1.1-0.3c0.3-0.1,0.7-0.1,1-0.1h0.9c0.3,0,0.5,0,0.8,0.1l0.6,0.1l0.5,0.1h-0.5
			c-0.3,0-0.8,0-1.3,0l-0.9,0.1c-0.3,0-0.6,0.1-1,0.2c-0.3,0.1-0.7,0.2-1,0.3l-0.3,0.1l-0.2,0.1l-0.5,0.3c-0.2,0.1-0.3,0.2-0.4,0.3
			c-0.1,0.1-0.3,0.2-0.4,0.3l-0.4,0.4l-0.3,0.4c-0.1,0.1-0.2,0.3-0.3,0.4s-0.2,0.3-0.2,0.4c-0.1,0.3-0.2,0.6-0.3,0.8
			s-0.1,0.5-0.2,0.7c0,0.2-0.1,0.4-0.1,0.6C44.9,118.1,44.9,118.2,44.9,118.4z"/>
		<path fill="#FFF0B8" d="M41.3,118.1c-0.4-0.3-0.7-0.6-1-1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.3-0.4-0.4-0.7
			c-0.1-0.2-0.3-0.5-0.3-0.8c-0.1-0.3-0.2-0.5-0.3-0.8s-0.1-0.6-0.1-0.9s0-0.6,0.1-0.8c0-0.1,0-0.3,0.1-0.4s0.1-0.2,0.1-0.4l0.1-0.3
			l0.2-0.3c0.1-0.2,0.2-0.3,0.3-0.5l0.3-0.3l0.3-0.3l-0.2,0.3l-0.2,0.4c-0.1,0.1-0.2,0.3-0.3,0.5l-0.1,0.3c0,0.1-0.1,0.2-0.1,0.3
			l-0.1,0.3c0,0.1,0,0.3,0,0.4s0,0.3,0,0.4s0,0.3,0,0.4c0,0.3,0,0.5,0.1,0.8s0.1,0.5,0.2,0.8c0.1,0.2,0.2,0.5,0.3,0.7
			c0.2,0.4,0.4,0.8,0.7,1.2c0.2,0.3,0.4,0.6,0.6,0.8S41.3,118.1,41.3,118.1z"/>
		<path fill="#FFF0B8" d="M32.1,118.1c0,0-0.9,0-2.1-0.1l-2.2-0.2c-0.4,0-0.8-0.1-1.2-0.1s-0.8,0-1.3,0c-0.2,0-0.4,0-0.6,0
			s-0.4,0-0.6,0.1c-0.4,0.1-0.8,0.1-1.2,0.3l-0.5,0.2c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
			c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.2-0.3,0.4-0.4,0.6L20,121l0.2-0.6c0.1-0.2,0.2-0.4,0.3-0.6c0.1-0.1,0.1-0.3,0.2-0.4L21,119
			c0.1-0.1,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3l0.5-0.3c0.4-0.1,0.8-0.2,1.2-0.3
			c0.2,0,0.4-0.1,0.6-0.1s0.4,0,0.6,0c0.4,0,0.9,0,1.3,0s0.8,0.1,1.2,0.1c0.8,0.1,1.5,0.2,2.2,0.3C31.3,117.9,32.1,118.1,32.1,118.1
			z"/>
		<path fill="#FFF0B8" d="M30.8,112.2h-0.6c-0.2,0-0.4,0-0.6,0c-0.3,0-0.5,0.1-0.8,0.1s-0.7,0.1-1,0.2s-0.7,0.2-1.1,0.3
			c-0.4,0.1-0.7,0.3-1.1,0.4c-0.4,0.2-0.7,0.4-1.1,0.6c-0.3,0.2-0.7,0.4-1,0.7c-0.3,0.2-0.6,0.5-0.9,0.8c-0.2,0.3-0.5,0.6-0.7,0.8
			l-0.3,0.4l-0.2,0.4c-0.1,0.2-0.3,0.5-0.4,0.7s-0.2,0.4-0.3,0.6l-0.2,0.5l0.1-0.6c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.5,0.3-0.8
			l0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.4-0.6,0.7-0.9c0.3-0.3,0.6-0.6,0.9-0.8c0.3-0.3,0.7-0.5,1-0.7
			c0.4-0.2,0.7-0.4,1.1-0.6s0.7-0.3,1.1-0.4s0.7-0.2,1.1-0.3c0.4-0.1,0.7-0.1,1-0.2s0.6,0,0.8,0c0.2,0,0.4,0,0.7,0L30.8,112.2z"/>
		<path fill="#FFF0B8" d="M30,117.8c0,0-0.5-0.1-1.3-0.3l-1.3-0.3l-0.7-0.2c-0.2,0-0.5-0.1-0.8-0.1c-0.2,0-0.5-0.1-0.8-0.1
			s-0.5,0-0.7,0s-0.5,0-0.7,0.1c-0.2,0-0.4,0.1-0.6,0.1l-0.5,0.1c-0.1,0-0.3,0.1-0.4,0.1l-0.3,0.1l0.3-0.2c0.1-0.1,0.2-0.1,0.4-0.2
			c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1c0.3,0,0.5-0.1,0.8-0.1s0.5,0,0.8,0c0.5,0.1,1,0.2,1.5,0.3
			c0.4,0.1,0.9,0.3,1.3,0.4s0.6,0.3,0.9,0.4L30,117.8z"/>
		<path fill="#FFF0B8" d="M30.8,114.8l-0.4-0.1l-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1s-0.4-0.1-0.6-0.1s-0.5,0-0.7-0.1h-0.8
			c-0.3,0-0.5,0.1-0.8,0.1s-0.5,0.1-0.8,0.2s-0.5,0.2-0.7,0.2c-0.4,0.2-0.8,0.3-1.3,0.6c-0.4,0.2-0.6,0.4-0.8,0.5l-0.3,0.2l0.3-0.3
			c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.3,0.8-0.5,1.2-0.7c0.2-0.1,0.5-0.2,0.8-0.3c0.5-0.2,1.1-0.3,1.6-0.3c0.3,0,0.5,0,0.8,0
			c0.5,0,0.9,0.1,1.4,0.3c0.2,0.1,0.4,0.1,0.5,0.2s0.3,0.1,0.4,0.2L30.8,114.8z"/>
		<path fill="#FFF0B8" d="M31.1,111.1c-0.5,0-1,0-1.5,0c-0.2,0-0.5,0-0.7,0.1s-0.5,0.1-0.8,0.1s-0.5,0.1-0.8,0.2s-0.6,0.2-0.8,0.3
			c-0.3,0.1-0.5,0.2-0.8,0.3c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.1-0.5,0.3-0.7,0.4s-0.4,0.3-0.6,0.4s-0.3,0.3-0.5,0.4l-0.3,0.3
			l-0.3,0.3l0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3s0.3-0.3,0.4-0.4c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.2,0.4-0.3,0.7-0.4
			c0.2-0.2,0.5-0.3,0.8-0.4s0.5-0.2,0.8-0.3c0.3-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.6-0.1,0.8-0.2s0.5-0.1,0.8-0.1h0.7
			C30.1,111,30.6,111,31.1,111.1z"/>
		<path fill="#FFF0B8" d="M31.7,117.1l-0.3-0.2c-0.2-0.1-0.5-0.3-0.9-0.5c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.5-0.2-0.7-0.3
			s-0.5-0.2-0.8-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-0.3,0-0.5-0.1-0.8-0.1s-0.5,0-0.8,0s-0.5,0.1-0.7,0.1s-0.4,0.1-0.6,0.1
			s-0.4,0.1-0.5,0.1l-0.4,0.1l-0.4,0.1l0.3-0.2l0.4-0.2c0.2-0.1,0.4-0.2,0.5-0.2c0.2-0.1,0.4-0.1,0.6-0.2s0.5-0.1,0.7-0.1
			c0.3,0,0.5,0,0.8,0s0.6,0,0.8,0.1c0.3,0,0.6,0.1,0.8,0.2c0.3,0.1,0.5,0.2,0.8,0.3c0.4,0.2,0.9,0.4,1.3,0.7
			c0.3,0.2,0.6,0.4,0.8,0.6C31.6,117,31.7,117,31.7,117.1z"/>
		<path fill="#FFF0B8" d="M19.5,123.6c0,0,0,0.1,0,0.2s0,0.2,0,0.4c0,0.2,0,0.5,0,0.7c0,0.3-0.1,0.6-0.1,0.9c-0.1,0.3-0.2,0.7-0.3,1
			s-0.2,0.7-0.4,1.1l-0.9,2.3c-0.1,0.4-0.3,0.8-0.4,1.2s-0.3,0.8-0.3,1.2c-0.2,0.7-0.3,1.4-0.3,2.1c0,0.5,0,1,0.1,1.5
			c0,0.2,0.1,0.4,0.1,0.6c-0.1-0.2-0.2-0.4-0.2-0.6c-0.2-0.5-0.3-1-0.3-1.5c0-0.7,0-1.5,0.2-2.2c0.1-0.4,0.2-0.8,0.3-1.2
			c0.1-0.4,0.3-0.8,0.4-1.2c0.6-1.6,1.4-3.1,1.7-4.3c0.1-0.3,0.2-0.6,0.2-0.8c0-0.2,0.1-0.4,0.1-0.7c0-0.1,0-0.3,0-0.4
			C19.5,123.6,19.5,123.6,19.5,123.6z"/>
		<path fill="#FFF0B8" d="M16.9,134.4c0-0.1,0-0.3,0-0.4c0-0.4,0-0.7,0.1-1.1c0.1-0.5,0.2-1,0.4-1.4c0.2-0.5,0.4-1.1,0.7-1.6
			c0.3-0.5,0.6-1,0.9-1.4c0.2-0.4,0.5-0.9,0.6-1.3c0.1-0.3,0.2-0.7,0.3-1c0-0.1,0-0.3,0-0.4c0,0.1,0,0.3,0,0.4c0,0.3-0.1,0.7-0.2,1
			c-0.1,0.5-0.3,0.9-0.5,1.4s-0.6,1-0.8,1.5c-0.3,0.5-0.5,1-0.7,1.5c-0.2,0.4-0.4,0.9-0.5,1.4c-0.1,0.3-0.2,0.7-0.2,1
			C16.9,134,16.9,134.2,16.9,134.4z"/>
		<path fill="#A0784A" d="M55.5,141.3L55.5,141.3L55.5,141.3z"/>
		<path fill="#FFF0B8" d="M32.7,110.8c-0.1,0.1-0.1,0.2-0.1,0.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.2-0.1,0.4-0.1,0.6
			s-0.1,0.5-0.1,0.7s0,0.5,0,0.7c0,0.3,0,0.5,0.1,0.8c0,0.1,0,0.3,0.1,0.4l0.1,0.4c0.1,0.2,0.2,0.5,0.3,0.7s0.2,0.4,0.4,0.6
			c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0.1,0.2,0.3,0.4,0.4L34,118l0.3,0.3l-0.3-0.2l-0.4-0.2c-0.2-0.1-0.3-0.2-0.4-0.3
			c-0.2-0.1-0.3-0.3-0.5-0.5s-0.3-0.4-0.5-0.6c-0.1-0.2-0.3-0.5-0.4-0.7l-0.1-0.4c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.6-0.1-0.8
			c0-0.3,0-0.5,0.1-0.8c0.1-0.5,0.2-0.9,0.4-1.3C32.2,111.6,32.4,111.2,32.7,110.8z"/>
		<path fill="#FFF0B8" d="M43.1,118.8v-0.2c0-0.1-0.1-0.2-0.2-0.4c-0.2-0.5-0.5-0.9-0.6-1.4c-0.1-0.3-0.2-0.7-0.3-1
			c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.4,0-0.9,0.1-1.3s0.2-0.9,0.4-1.3c0.1-0.2,0.2-0.4,0.3-0.6s0.2-0.4,0.4-0.5l0.2-0.2l0.3-0.2
			c0.2-0.1,0.3-0.3,0.5-0.4c0.4-0.2,0.7-0.3,1.1-0.4c0.3-0.1,0.7-0.1,1.1-0.1c0.5,0,1.1,0,1.6,0.2c0.1,0,0.3,0.1,0.4,0.2l0.1,0.1
			l-0.1-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.5-0.1-1-0.1-1.5,0c-0.3,0-0.7,0.1-1,0.2s-0.7,0.3-1,0.4c-0.2,0.1-0.3,0.2-0.5,0.3l-0.2,0.2
			l-0.2,0.2c-0.1,0.2-0.2,0.3-0.3,0.5s-0.2,0.4-0.3,0.6c-0.2,0.4-0.3,0.8-0.3,1.2c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.4,0,0.8,0,1.1
			c0,0.3,0.1,0.7,0.2,1c0.1,0.5,0.3,1,0.5,1.5C43,118.5,43.1,118.7,43.1,118.8z"/>
		<path fill="#FFF0B8" d="M45.2,118.8c0-0.1,0-0.2,0-0.2c0-0.2-0.1-0.4-0.2-0.7c-0.2-0.8-0.2-1.6,0-2.4c0.1-0.5,0.3-1,0.6-1.5
			s0.7-1,1.2-1.3c0.3-0.2,0.5-0.4,0.8-0.5c0.3-0.2,0.6-0.3,0.9-0.4c0.6-0.2,1.3-0.4,2-0.4c1.3,0,2.6,0.3,3.8,0.8
			c1.1,0.4,2.1,1,3,1.7c0.6,0.5,1.2,1,1.8,1.6c0.2,0.2,0.4,0.5,0.6,0.7c-0.2-0.2-0.4-0.5-0.6-0.7c-0.6-0.5-1.2-1-1.9-1.5
			c-0.9-0.6-1.9-1.1-3-1.5c-1.2-0.5-2.4-0.7-3.7-0.7c-0.6,0-1.3,0.1-1.9,0.3c-0.3,0.1-0.6,0.2-0.8,0.4c-0.3,0.1-0.5,0.3-0.8,0.5
			c-0.5,0.3-0.9,0.8-1.2,1.2c-0.3,0.4-0.5,0.9-0.7,1.4c-0.2,0.8-0.3,1.6-0.2,2.3C45.1,118.5,45.2,118.8,45.2,118.8z"/>
		<path fill="#FFF0B8" d="M42,118.8c-0.4-0.3-0.7-0.6-1-1c-0.1-0.2-0.3-0.3-0.4-0.5c-0.2-0.2-0.3-0.4-0.4-0.6
			c-0.1-0.2-0.3-0.5-0.4-0.7c-0.1-0.3-0.2-0.5-0.3-0.8s-0.1-0.6-0.2-0.8c0-0.3,0-0.6,0-0.8c0-0.1,0-0.3,0-0.4s0.1-0.3,0.1-0.4
			c0-0.2,0.1-0.4,0.2-0.7c0.1-0.2,0.2-0.4,0.2-0.5l0.2-0.4c0.1-0.2,0.2-0.3,0.2-0.3l-0.1,0.3c0,0.1-0.1,0.3-0.2,0.4
			s-0.1,0.3-0.1,0.6c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2,0,0.3v0.4c0,0.3,0,0.5,0.1,0.8c0,0.3,0.1,0.5,0.2,0.8
			c0.1,0.3,0.2,0.5,0.3,0.8s0.2,0.5,0.3,0.7l0.3,0.7c0.1,0.2,0.3,0.4,0.3,0.6c0.2,0.3,0.4,0.6,0.6,0.8L42,118.8z"/>
		<path fill="#FFF0B8" d="M39.5,109l-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2s-0.3,0.2-0.4,0.3l-0.5,0.5l-0.3,0.3l-0.2,0.3
			c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.1,0.3-0.2,0.4s-0.1,0.3-0.1,0.5s-0.1,0.3-0.1,0.5s0,0.3,0,0.5v0.5
			c0,0.2,0.1,0.3,0.1,0.5c0.1,0.3,0.1,0.6,0.3,0.9c0.1,0.3,0.2,0.6,0.4,0.8c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.2,0.3,0.4,0.4,0.6
			l0.3,0.4l0.3,0.4l-0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.4c-0.2-0.2-0.3-0.4-0.5-0.6s-0.3-0.5-0.5-0.7c-0.2-0.3-0.3-0.6-0.5-0.8
			c-0.1-0.3-0.2-0.7-0.3-1c0-0.2-0.1-0.4-0.1-0.5v-0.6c0-0.2,0-0.4,0-0.5c0-0.2,0-0.4,0.1-0.5l0.2-0.5c0.1-0.2,0.2-0.3,0.2-0.5
			c0.1-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.2-0.3,0.3-0.4s0.2-0.2,0.3-0.3L38,110c0.2-0.2,0.4-0.3,0.6-0.5c0.1-0.1,0.3-0.2,0.5-0.3
			C39.2,109.1,39.3,109.1,39.5,109z"/>
		<path fill="#FFF0B8" d="M30.3,114.3l-0.9-0.2c-0.3,0-0.7,0-1.1-0.1c-0.2,0-0.4,0-0.7,0c-0.2,0-0.5,0-0.7,0.1
			c-0.3,0-0.5,0.1-0.8,0.2s-0.5,0.2-0.8,0.3s-0.5,0.2-0.8,0.4s-0.5,0.3-0.8,0.5c-0.5,0.4-1,0.8-1.5,1.3c-0.2,0.2-0.4,0.5-0.6,0.8
			c-0.4,0.5-0.7,1.1-1,1.7c-0.1,0.3-0.2,0.6-0.3,0.9c-0.2,0.6-0.2,1.3-0.2,1.9s0,1.3,0,1.8s0.1,1.1,0.1,1.7c0,0.5,0,0.9,0,1.4
			c0,0.4-0.1,0.7-0.3,1.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.1-0.1,0.2-0.1,0.2s0-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.6
			c0.1-0.3,0.2-0.7,0.2-1c0-0.5,0-0.9-0.1-1.4c-0.2-1.2-0.2-2.3-0.2-3.5c0-0.7,0.1-1.3,0.2-2c0.1-0.3,0.2-0.7,0.3-1
			c0.1-0.3,0.3-0.7,0.5-1s0.4-0.6,0.6-0.9s0.4-0.5,0.7-0.8c0.5-0.5,1-0.9,1.6-1.3c0.3-0.2,0.6-0.3,0.8-0.5s0.6-0.2,0.9-0.3
			s0.6-0.2,0.8-0.2s0.5-0.1,0.8-0.1c0.2,0,0.5,0,0.7,0s0.5,0,0.7,0c0.4,0.1,0.8,0.1,1.1,0.2L30.3,114.3z"/>
		<path fill="#FFF0B8" d="M20.4,122.8c0,0,0-0.2-0.1-0.7c0-0.2,0-0.5,0-0.8s0-0.7,0.1-1c0-0.2,0.1-0.4,0.1-0.6s0.1-0.4,0.2-0.6
			c0.1-0.4,0.3-0.8,0.6-1.2c0.1-0.2,0.3-0.4,0.4-0.6c0.2-0.2,0.3-0.4,0.5-0.6s0.4-0.3,0.6-0.5l0.6-0.4c0.2-0.1,0.5-0.2,0.7-0.3
			l0.4-0.1l0.4-0.1l0.7-0.2h0.7c0.4,0,0.9,0,1.3,0s0.8,0.1,1.2,0.2c0.3,0,0.7,0.1,1,0.2l0.7,0.2l0.6,0.2l-0.7-0.1l-0.8-0.2
			c-0.3-0.1-0.6-0.1-1-0.2s-0.8-0.1-1.2-0.1c-0.4,0-0.9,0-1.3,0l-0.7,0.1l-0.7,0.2l-0.3,0.1l-0.3,0.1c-0.2,0.1-0.5,0.2-0.6,0.3
			l-0.6,0.4c-0.2,0.1-0.4,0.3-0.5,0.4c-0.2,0.1-0.3,0.3-0.5,0.5s-0.3,0.4-0.4,0.6c-0.2,0.4-0.4,0.7-0.6,1.1
			c-0.1,0.2-0.1,0.4-0.2,0.6s-0.1,0.4-0.1,0.6c-0.1,0.3-0.1,0.7-0.2,1s0,0.6,0,0.8C20.4,122.6,20.4,122.8,20.4,122.8z"/>
		<path fill="#FFE997" d="M34.5,108.8c-1,0.3-2.3,2.8-2.4,7.6c-0.1,7.1-1.8,7.7-3.3,8.5c0,0,3.5,0,4.5-5.9l2-8.4L34.5,108.8z"/>
		<path fill="#FFE997" d="M39.5,117l-0.2,0.1l0,0l-0.1,0.1c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.5-0.3,0.9
			c-0.5,1.7-1,3.3-1.6,4.9c-0.4,0.8-0.8,1.7-1.3,2.4c-0.2,0.3-0.5,0.7-0.8,1s-0.5,0.6-0.8,0.8c-0.2,0.2-0.5,0.4-0.8,0.6
			c-0.2,0.2-0.5,0.3-0.6,0.4l-0.6,0.3l0.6-0.3c0.2-0.1,0.4-0.3,0.6-0.4c0.2-0.2,0.5-0.4,0.7-0.6c0.3-0.3,0.5-0.5,0.7-0.8
			c0.3-0.3,0.5-0.7,0.7-1c0.4-0.8,0.8-1.6,1.1-2.4c0.5-1.6,1-3.3,1.3-4.9c0.1-0.3,0.2-0.7,0.3-1s0.2-0.5,0.3-0.8
			c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.1L39.5,117z"/>
		<path fill="#FFE997" d="M34.6,126.1l0.4-0.2c0.3-0.2,0.6-0.4,0.9-0.6c0.2-0.2,0.4-0.3,0.5-0.5c0.2-0.2,0.4-0.4,0.5-0.7
			c0.2-0.2,0.3-0.5,0.5-0.8c0.1-0.3,0.3-0.6,0.4-0.8c0.2-0.6,0.4-1.2,0.6-1.8c0.1-0.3,0.1-0.6,0.2-0.8s0.1-0.5,0.2-0.7
			c0.1-0.5,0.2-0.9,0.2-1.1s0.1-0.4,0.1-0.4l0.5,0.1c0,0,0,0.2-0.1,0.4s-0.1,0.7-0.3,1.1c0,0.2-0.1,0.5-0.2,0.8s-0.2,0.6-0.3,0.8
			c-0.2,0.6-0.4,1.2-0.7,1.8c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.3-0.3,0.5-0.5,0.8c-0.2,0.2-0.4,0.4-0.6,0.7c-0.2,0.2-0.4,0.4-0.6,0.5
			c-0.3,0.2-0.7,0.4-1,0.6L34.6,126.1z"/>
		<path fill="#FFE997" d="M39,119c1.7-3.3,6.2-5.4,10.9-1.3s-0.1,15.3,6.8,19.4c0,0-6.2-0.4-6.8-6.7c-0.4-3.3-2-6.4-4.5-8.7
			c-1-0.9-2.8-2.5-4.2-2.6L39,119z"/>
		<g id="glasses">
			<g>
				<polygon fill="#7F7F7F" points="53.5,133.7 57,133.6 57.6,133.1 53.1,132.3 				"/>
				<polygon opacity="0.3" fill="#7F7F7F" enableBackground="new    " points="53.5,133.7 57,133.6 57.6,133.1 53.1,132.3 				"/>
			</g>
			<g>
				<polygon fill="#7F7F7F" points="30.2,133.7 26.7,133.6 26.1,133.1 30.7,132.3 				"/>
				<polygon opacity="0.3" fill="#7F7F7F" enableBackground="new    " points="30.2,133.7 26.7,133.6 26.1,133.1 30.7,132.3 				
					"/>
			</g>
			<path fill="#7F7F7F" d="M44.3,134.8c0,0-0.6-1-2.4-1s-2.2,1-2.2,1l-0.2-0.1c0,0,0.4-1,2.5-1.1s2.7,1.1,2.7,1.1L44.3,134.8z"/>
			<rect x="37.9" y="131.8" fill="#7F7F7F" width="8.5" height="0.5"/>
			<g opacity="0.3">
				<rect x="37.9" y="131.8" fill="#7F7F7F" width="8.5" height="0.5"/>
			</g>
			<g>
				<path fill="#CED8E6" d="M40.2,131.7c0-1.2-1.5-2.4-6.1-2.3c-2.4,0.1-4.4,1.5-4.2,4.7c0.1,2.5,2.1,4.4,4.6,4.4l0,0
					c0.9,0,1.8-0.4,2.6-1C38.9,136.1,40,134,40.2,131.7z"/>
				<path opacity="0.3" fill="#CED8E6" enableBackground="new    " d="M38.7,130c0.6,0.3,1,0.9,1.1,1.5c-0.2,2.3-1.3,4.4-3.1,5.8
					c-0.7,0.6-1.6,1-2.6,1c-0.6,0-1.2-0.1-1.8-0.3c0.7,0.4,1.5,0.6,2.3,0.5c0.9,0,1.8-0.4,2.6-1c1.8-1.5,2.9-3.6,3.1-5.8
					C40.2,131.1,39.8,130.4,38.7,130z"/>
				<path opacity="0.3" fill="#CED8E6" enableBackground="new    " d="M30.5,134.5c-0.1-3.2,1.8-4.6,4.2-4.7c1.6-0.1,3.1,0,4.6,0.6
					c-0.9-0.6-2.5-0.9-5.1-0.8c-2.4,0.1-4.4,1.5-4.2,4.7c0,1.7,1.1,3.3,2.6,4C31.3,137.4,30.5,136,30.5,134.5z"/>
				<g>
					<path fill="#EBF0F5" d="M37.4,129.6l-4.1,8.8c0.4,0.1,0.9,0.2,1.3,0.1c0.1,0,0.3,0,0.4,0c0.7-2.1,2-6.2,2.8-8.8L37.4,129.6z"/>
					<path fill="#EBF0F5" d="M35.6,129.5c-1.7,2.4-3.8,5.4-4.9,7c0.4,0.6,0.9,1.1,1.5,1.5l4.1-8.4L35.6,129.5z"/>
				</g>
				<path fill="#7F7F7F" d="M34.6,138.8c-2.6,0.1-4.8-2-4.9-4.6l0,0c-0.1-3,1.5-4.9,4.5-5c5.6-0.2,6.3,1.7,6.3,2.5
					c-0.2,2.4-1.4,4.5-3.2,6.1C36.5,138.4,35.6,138.8,34.6,138.8z M34.2,129.7c-2.6,0.1-4.1,1.7-4,4.5c0.1,2.3,2,4.2,4.3,4.1
					c0.9,0,1.7-0.4,2.4-0.9c1.7-1.4,2.8-3.4,3-5.6C39.9,130.3,37.8,129.6,34.2,129.7z"/>
				<g opacity="0.3">
					<path fill="#7F7F7F" d="M30.2,134.2c-0.1-2.7,1.3-4.4,4-4.5c2.8-0.1,4.8,0.3,5.5,1.2c-0.6-1-2.6-1.5-5.6-1.4c-2.2,0-4,1.8-4,4
						c0,0.2,0,0.3,0,0.5c0,0.9,0.3,1.7,0.8,2.4C30.5,135.8,30.3,135,30.2,134.2z"/>
					<path fill="#7F7F7F" d="M40.1,130.7c0.2,0.3,0.3,0.6,0.3,0.9c-0.2,2.4-1.4,4.6-3.2,6.1c-0.8,0.6-1.7,1-2.7,1
						c-1.6,0.1-3-0.7-3.9-1.9c0.9,1.3,2.4,2.1,4,2c1,0,1.9-0.4,2.7-1c1.8-1.5,3-3.7,3.2-6.1C40.4,131.3,40.3,131,40.1,130.7z"/>
				</g>
				<path fill="#7F7F7F" d="M29.9,132.9c0.3-2,2.1-3.5,4.1-3.5C34,129.4,31,129.2,29.9,132.9z"/>
			</g>
			<g>
				<path fill="#CED8E6" d="M43.5,131.7c0-1.2,1.5-2.4,6.1-2.3c2.4,0.1,4.4,1.5,4.2,4.7c-0.1,2.5-2.1,4.4-4.6,4.4l0,0
					c-0.9,0-1.8-0.4-2.6-1C44.8,136.1,43.7,134,43.5,131.7z"/>
				<path opacity="0.3" fill="#CED8E6" enableBackground="new    " d="M45,130c-0.6,0.3-1,0.9-1.1,1.5c0.2,2.3,1.3,4.4,3.1,5.8
					c0.7,0.6,1.6,1,2.6,1c0.6,0,1.2-0.1,1.8-0.3c-0.7,0.4-1.5,0.6-2.3,0.5c-0.9,0-1.8-0.4-2.6-1c-1.8-1.5-2.9-3.6-3.1-5.8
					C43.5,131.1,44,130.4,45,130z"/>
				<path opacity="0.3" fill="#CED8E6" enableBackground="new    " d="M53.3,134.5c0.1-3.2-1.8-4.6-4.2-4.7c-1.6-0.1-3.1,0-4.6,0.6
					c0.9-0.6,2.5-0.9,5.1-0.8c2.4,0.1,4.4,1.5,4.2,4.7c0,1.7-1.1,3.3-2.7,4C52.4,137.4,53.2,136,53.3,134.5z"/>
				<g>
					<path fill="#EBF0F5" d="M52,130.1l-3.8,8.2c0.3,0.1,0.6,0.1,0.9,0.2h0.4h0.4c0.6-1.9,1.7-5.5,2.5-8.1
						C52.3,130.3,52.1,130.2,52,130.1z"/>
					<path fill="#EBF0F5" d="M47,137.9l4-8.1c-0.2-0.1-0.4-0.1-0.7-0.2c-1.7,2.4-3.8,5.3-4.9,6.9c0,0.1,0.1,0.1,0.2,0.2
						c0.3,0.3,0.6,0.6,1,0.9L47,137.9z"/>
				</g>
				<path fill="#7F7F7F" d="M46.5,137.8c-1.8-1.5-3-3.7-3.2-6.1c0-0.8,0.7-2.8,6.3-2.5c2.9,0.1,4.6,2,4.5,5
					c-0.1,2.6-2.3,4.7-4.9,4.6l0,0C48.2,138.8,47.2,138.4,46.5,137.8z M43.8,131.8c0.2,2.2,1.3,4.2,3,5.6c0.7,0.6,1.5,0.9,2.4,0.9
					c2.3,0.1,4.3-1.8,4.3-4.1c0.1-2.7-1.3-4.4-4-4.5C46,129.6,43.8,130.3,43.8,131.8L43.8,131.8z"/>
				<g opacity="0.3">
					<path fill="#7F7F7F" d="M53.5,134.2c0.1-2.7-1.3-4.4-4-4.5c-2.8-0.1-4.8,0.3-5.5,1.2c0.6-1,2.6-1.5,5.6-1.4c2.2,0,4,1.8,4,4
						c0,0.2,0,0.3,0,0.5c0,0.9-0.3,1.7-0.8,2.4C53.2,135.8,53.5,135,53.5,134.2z"/>
					<path fill="#7F7F7F" d="M43.6,130.7c-0.2,0.3-0.3,0.6-0.3,0.9c0.2,2.4,1.4,4.6,3.2,6.1c0.8,0.6,1.7,1,2.7,1
						c1.6,0.1,3-0.7,3.9-1.9c-0.9,1.3-2.4,2.1-4,2c-1,0-1.9-0.4-2.7-1c-1.8-1.5-3-3.7-3.2-6.1C43.3,131.3,43.4,131,43.6,130.7z"/>
				</g>
				<path fill="#7F7F7F" d="M43.5,131.5c0.1-0.6,0.4-1.1,0.9-1.4c0.8-0.4,1.7-0.6,2.6-0.7c-0.9,0.1-1.8,0.4-2.6,0.9
					C44,130.6,43.7,131,43.5,131.5z"/>
			</g>
		</g>
		<path fill="#FFE997" d="M37,119c1.7-3.3,6.2-5.4,10.9-1.3s0.4,21.4,7.3,25.5c0,0-7.4-1.7-8-8c-0.4-4-2.3-11.3-4-13
			c-1-0.9-2.7-2.9-4-3L37,119z"/>
		<path fill="#FFE997" d="M36.2,119.2c1.7-3.3,6-4.7,10.6-0.5s0.4,23.4,7.3,27.5c0,0-7.4-3.7-8-10c-0.4-4-2.3-11.3-4-13
			c-1-0.9-2.7-2.9-4-3L36.2,119.2z"/>
		<path fill="#FFE997" d="M46.8,116.4c2.6-0.8,8.3,0.1,8.3,7s2.5,16,6.4,17.3c0,0-5.6-1-7.2-14.5c-0.1-0.6-0.2-1.1-0.5-1.7l0,0
			c-1-2.2-2.5-4.1-4.3-5.7L46.8,116.4z"/>
		<path fill="#FFE997" d="M45.8,117.4c2.6-0.8,8.3,0.1,8.3,7s2.5,16,6.4,17.3c0,0-5.6-1-7.2-14.5c-0.1-0.6-0.2-1.1-0.5-1.7l0,0
			c-1-2.2-2.5-4.1-4.3-5.7L45.8,117.4z"/>
	</g>
	<g>
		<path fill="#FFF0B8" d="M54.4,127.8c0,0,0-0.2-0.1-0.5c0-0.2,0-0.4-0.1-0.6s-0.1-0.5-0.1-0.8s-0.1-0.6-0.2-0.9s-0.2-0.6-0.3-1
			s-0.3-0.7-0.4-1s-0.3-0.7-0.5-1s-0.3-0.7-0.5-1s-0.4-0.6-0.6-0.9s-0.4-0.5-0.6-0.8s-0.4-0.4-0.6-0.7s-0.4-0.4-0.6-0.5
			c-0.1-0.1-0.3-0.3-0.5-0.4c-0.3-0.2-0.4-0.3-0.4-0.3s0.2,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.1,0.4,0.3,0.7,0.4
			c0.3,0.2,0.5,0.4,0.7,0.6c0.3,0.2,0.5,0.5,0.7,0.8s0.5,0.6,0.7,0.9s0.4,0.7,0.6,1c0.2,0.3,0.3,0.7,0.5,1.1
			c0.1,0.3,0.3,0.7,0.4,1.1c0.1,0.3,0.2,0.7,0.2,1s0.1,0.6,0.1,0.9c0,0.5,0,0.9,0,1.4C54.4,127.6,54.4,127.8,54.4,127.8z"/>
		<path fill="#FFF0B8" d="M49.6,116.7l0.5,0.3c0.3,0.2,0.7,0.5,1.1,0.8c0.5,0.4,1,0.9,1.4,1.4c0.2,0.3,0.4,0.6,0.6,0.9
			c0.2,0.3,0.3,0.7,0.5,1c0.1,0.4,0.2,0.7,0.3,1.1s0.2,0.7,0.3,1.1c0.2,0.7,0.3,1.4,0.4,2c0.1,0.5,0.2,0.9,0.4,1.4
			c0.1,0.3,0.2,0.5,0.2,0.5s0-0.2-0.1-0.5c-0.1-0.5-0.2-0.9-0.3-1.4c-0.1-0.6-0.1-1.3-0.2-2c-0.1-0.8-0.3-1.5-0.6-2.2
			c-0.1-0.4-0.3-0.7-0.5-1.1s-0.4-0.7-0.6-1c-0.4-0.5-0.9-1-1.5-1.4c-0.4-0.3-0.8-0.5-1.2-0.7L49.6,116.7z"/>
	</g>
	<path fill="#FFF0B8" d="M39.2,118.2c0,0,6.8-1.7,9,2c1.3,2.4,2,5.2,2,8c0,0-2-11-9-10H39.2z"/>
	<path fill="#FFF0B8" d="M39.2,119.3c0,0,6.5,0.9,8,5c1.5,4.1,1,14,1,14s0-18.2-7-18.6L39.2,119.3L39.2,119.3z"/>
	<path fill="#FFF0B8" d="M41.6,116.6c0,0,7-0.9,8.7,3s1.5,5.2,1.5,5.2s-1.1-8.2-8.2-7.9L41.6,116.6L41.6,116.6z"/>
</g>
<g>
	<path fill="#F2B387" d="M27.5,41.1c-0.2-0.5-1.4-1.8-2.5-1s0.4,3.3,1.5,5s0.7,2.6,0.8,3.6s1,2.8,2.5,2.4s-0.9-7.5-0.9-7.5
		L27.5,41.1z"/>
	<path fill="#F2B387" d="M58.8,41.1c0.2-0.5,1.4-1.8,2.5-1s-0.3,3.3-1.5,5c-0.8,1.2-1.2,2.7-1.2,4.1c-0.1,1-0.6,2.1-2.1,1.9
		s0.9-7.5,0.9-7.5L58.8,41.1z"/>
	<path fill="#F2B387" d="M55.8,24.8l-12.7,0.1l-12.7-0.1l-3.7,2.6L29,45.5c0.5,2.3,0.8,6.6,1.1,8.5s3.1,5.5,5.3,8.5s3.7,3.2,4.7,3.5
		c1,0.1,2,0.1,3,0c1,0.1,2,0.1,3,0c1-0.3,2.5-0.5,4.7-3.5s5-6.7,5.3-8.5s0.6-6.1,1.1-8.5l2.3-18.1L55.8,24.8z"/>
	<path fill="#B08080" d="M60.1,21.5c-2.4-7.3-13.7-10.4-16.8-10.3c-3.6,0.1-4.8,0-9.1,1.8c-5.6,2.3-5.8,7-5.8,7
		c-3.7,0.9-3.5,5.8-2.9,9.6s1.9,11.1,1.9,11.1l1.8,4.8c0,0-0.3-2.8-0.5-4.9s1.5-4,0.8-5.6s-1.1-7.3,1.5-9s6.7,0,12.4,0
		s9.9-1.6,12.4,0s2.2,7.3,1.5,9s1,3.5,0.8,5.6s0.6,1.4,0.6,1.4l0.6-1.3c0,0,0.7-3.6,1.3-7.4S61.6,26.2,60.1,21.5L60.1,21.5z"/>
	<path fill="#92583A" d="M60.4,24.8c1.7,3.2,0,9.7,0,9.7s0.2,3.9-1.3,6.2L58.7,35l0.5-11.9L60.4,24.8z"/>
	<path fill="#F2B387" d="M33.5,25.9h-0.3c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.4-0.1-0.7-0.3-1-0.5
		c-1-0.7-1.8-1.7-2.2-2.9c-0.1-0.2-0.2-0.5-0.2-0.7s0-0.3,0-0.3s0,0.1,0.1,0.3c0.1,0.2,0.1,0.5,0.3,0.7c0.3,0.7,0.8,1.3,1.3,1.9
		c0.3,0.3,0.6,0.5,1,0.7c0.3,0.2,0.6,0.3,1,0.4c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.2,0,0.3,0h0.3L33.5,25.9z"/>
	<path fill="#A46B4C" d="M32.2,23.1l-0.3-0.3c-0.7-0.7-1.2-1.6-1.5-2.5c-0.1-0.3-0.2-0.7-0.2-1s-0.1-0.7-0.1-1s0.1-0.7,0.1-1
		c0.1-0.3,0.2-0.6,0.3-1c0.1-0.3,0.3-0.5,0.5-0.8c0.2-0.2,0.4-0.5,0.5-0.6l0.5-0.5c0.2-0.1,0.3-0.2,0.4-0.3l0.4-0.3l-0.3,0.4
		c-0.1,0.1-0.2,0.2-0.3,0.4s-0.3,0.4-0.4,0.5s-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.5-0.3,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
		c-0.1,0.3-0.1,0.6-0.1,0.9s0,0.6,0,0.9s0.1,0.6,0.1,0.9c0.1,0.3,0.1,0.6,0.2,0.8c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.4,0.4,0.7,0.6,1.1
		C32.2,23,32.2,23.1,32.2,23.1L32.2,23.1z"/>
	<path fill="#A46B4C" d="M31.6,16.8l-0.1,0.3c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.7,0.1,1.3,0.3,2c0.1,0.2,0.2,0.4,0.3,0.7
		c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.3,0.5,0.5c0.1,0.2,0.3,0.3,0.4,0.4l0.4,0.4l0.3,0.3l0.3,0.2
		l-0.3-0.1c-0.3-0.1-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.4-0.3-0.5-0.5c-0.2-0.2-0.4-0.4-0.5-0.6
		c-0.2-0.2-0.3-0.5-0.4-0.7s-0.2-0.5-0.3-0.8c-0.1-0.2-0.1-0.5-0.1-0.8c0-0.2,0-0.5,0-0.7s0-0.5,0.1-0.7c0.1-0.3,0.2-0.6,0.4-0.9
		C31.5,17,31.6,16.9,31.6,16.8z"/>
	<path fill="#A46B4C" d="M41.3,14.3l0.1-0.1c0.1-0.1,0.2-0.3,0.4-0.4l0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.3-0.2,0.5-0.2
		c0.2-0.1,0.4-0.2,0.5-0.2c0.4-0.1,0.9-0.2,1.3-0.3c0.5-0.1,1-0.1,1.5-0.1s1.1,0,1.6,0.1c0.3,0,0.5,0.1,0.8,0.1
		c0.3,0.1,0.5,0.1,0.8,0.2c0.5,0.2,1,0.4,1.5,0.6c0.5,0.3,0.9,0.6,1.3,0.8c0.4,0.2,0.8,0.4,1.3,0.5c0.4,0.1,0.8,0.2,1.2,0.2
		c0.6,0,1.3,0,1.8-0.3c0.2-0.1,0.4-0.2,0.6-0.3L57,14.6c-0.1,0.1-0.3,0.2-0.5,0.3c-0.6,0.3-1.2,0.5-1.9,0.5c-0.9,0-1.8-0.2-2.6-0.7
		c-0.4-0.2-0.9-0.5-1.3-0.7c-0.5-0.2-0.9-0.4-1.4-0.6c-0.3-0.1-0.5-0.1-0.7-0.2c-0.3-0.1-0.5-0.1-0.8-0.1c-0.5-0.1-1-0.1-1.5-0.1
		c-0.9-0.1-1.8,0-2.6,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.2,0-0.3,0.1-0.5,0.2l-0.4,0.2L42,13.9c-0.1,0.1-0.3,0.2-0.4,0.3L41.3,14.3
		L41.3,14.3z"/>
	<path fill="#A46B4C" d="M40.5,22.8l-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.3-0.3-0.4-0.5l-0.2-0.3L38.9,21
		c-0.1-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.6-0.3-0.9s-0.1-0.7-0.1-1s0-0.7,0-1s0.1-0.6,0.2-0.9c0.1-0.3,0.2-0.6,0.3-0.8
		s0.3-0.4,0.5-0.6l0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.5-0.3l0.4-0.2c0,0-0.1,0.1-0.3,0.3c-0.1,0.1-0.3,0.2-0.4,0.4
		c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.3-0.1,0.6-0.1,0.9s0,0.6,0,0.9s0,0.6,0.1,0.9
		s0.1,0.6,0.2,0.8c0.1,0.3,0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.5,0.3,0.7s0.2,0.4,0.3,0.5s0.2,0.3,0.3,0.4L40.5,22.8L40.5,22.8z"/>
	<path fill="#A46B4C" d="M47.6,25.9h-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.6-0.2-1.1-0.5-1.5-0.9c-0.6-0.6-1.1-1.3-1.3-2.1
		c-0.3-0.9-0.2-1.9,0.2-2.8c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.4,0.7-0.7,1.1-1c0.7-0.5,1.6-0.7,2.5-0.7c0.6,0,1.2,0.1,1.8,0.3
		c0.2,0.1,0.4,0.1,0.6,0.3c0,0-0.2-0.1-0.7-0.1c-0.6-0.1-1.1-0.1-1.7,0c-1.2,0.1-2.3,0.7-3.1,1.6c-0.3,0.3-0.5,0.7-0.7,1.1
		s-0.3,0.8-0.3,1.2c0,0.4,0,0.8,0.1,1.2c0.2,0.7,0.5,1.4,1.1,2c0.4,0.4,0.8,0.8,1.3,1C47.2,25.8,47.4,25.9,47.6,25.9L47.6,25.9z"/>
	<path fill="#A46B4C" d="M49.2,25.9h-0.3l-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.2c-0.4-0.2-0.7-0.4-1-0.7c-0.2-0.2-0.3-0.3-0.5-0.5
		s-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7s0-0.4,0-0.6s0.1-0.4,0.2-0.6c0.1-0.1,0.2-0.3,0.3-0.4
		s0.2-0.2,0.3-0.3l0.2-0.2l-0.1,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0,0.5s0,0.4,0,0.5
		c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.2,0.4,0.3,0.5c0.1,0.2,0.3,0.3,0.4,0.5c0.2,0.3,0.5,0.5,0.8,0.8
		c0.1,0.1,0.2,0.2,0.4,0.3l0.3,0.2L49.2,25.9L49.2,25.9z"/>
	<path fill="#A46B4C" d="M37.2,19.2v0.1c0,0.1,0,0.2,0,0.4c0,0.4,0.1,0.8,0.3,1.2c0.1,0.3,0.2,0.5,0.3,0.8s0.3,0.5,0.4,0.8
		c0.2,0.3,0.4,0.5,0.5,0.8c0.4,0.5,0.9,1,1.4,1.4c0.3,0.2,0.5,0.4,0.8,0.5s0.5,0.3,0.8,0.4s0.5,0.2,0.8,0.3l0.7,0.2l0.5,0.1l0.5,0.1
		h-1.1l-0.8-0.1c-0.3,0-0.5-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.6-0.4-0.9-0.6c-0.3-0.2-0.6-0.5-0.8-0.7
		c-0.3-0.3-0.5-0.5-0.7-0.8s-0.4-0.6-0.5-0.9c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.5-0.2-0.8c-0.1-0.4-0.1-0.9,0-1.3
		c0-0.1,0-0.2,0.1-0.3L37.2,19.2L37.2,19.2z"/>
	<path fill="#A46B4C" d="M36.6,22.9l-0.2-0.2c-0.1-0.2-0.3-0.3-0.4-0.5l-0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.5c-0.2-0.4-0.4-0.8-0.5-1.2
		c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.1-0.1-1.7s0.2-1.2,0.4-1.8s0.5-1.2,0.9-1.7s0.9-1,1.5-1.3
		s1.3-0.5,1.9-0.5c0.6,0,1.2,0,1.8,0.1s1.1,0.2,1.5,0.2s0.9,0,1.3,0l0.5-0.1c0.2,0,0.3-0.1,0.4-0.1c0.2-0.1,0.4-0.1,0.6-0.2l0.2-0.1
		l-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.1-0.3,0.1-0.4,0.2l-0.1,0c-0.4,0.1-0.9,0.2-1.4,0.1c-1,0-2.2-0.2-3.3-0.1
		c-0.6,0.1-1.1,0.2-1.7,0.5c-0.1,0.1-0.3,0.2-0.4,0.3l-0.2,0.1L37.1,13c-0.1,0.1-0.2,0.2-0.3,0.3l-0.3,0.3c-0.4,0.5-0.7,1-0.9,1.5
		s-0.3,1.1-0.4,1.7c0,0.5,0,1.1,0,1.6c0,0.3,0,0.5,0.1,0.8c0,0.3,0.1,0.5,0.1,0.7c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.2,0.1,0.3,0.2,0.5
		s0.1,0.3,0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6L36.6,22.9L36.6,22.9z"/>
	<path fill="#A46B4C" d="M38,21.3c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0.4,0.5,0.8,1,1.3,1.5s1,0.9,1.6,1.2l0.6,0.4
		l0.2,0.1h-0.3c-0.2,0-0.5-0.1-0.7-0.2c-0.2,0-0.3-0.1-0.5-0.2s-0.3-0.2-0.5-0.3c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.3-0.5-0.6-0.7-0.9
		s-0.3-0.6-0.4-0.9C38,22,38,21.8,38,21.6C38,21.5,38,21.4,38,21.3L38,21.3z"/>
	<path fill="#A46B4C" d="M36.5,23.6c0.1,0.2,0.3,0.4,0.5,0.6s0.4,0.4,0.6,0.5c0.2,0.2,0.5,0.4,0.8,0.5s0.5,0.3,0.8,0.5
		s0.5,0.3,0.7,0.4s0.5,0.3,0.7,0.5h-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.6-0.3-0.9-0.5
		c-0.3-0.2-0.6-0.4-0.8-0.6C37.1,25,37,24.9,37,24.8c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.2-0.2-0.4-0.2-0.6
		C36.5,23.8,36.5,23.7,36.5,23.6L36.5,23.6z"/>
	<path fill="#A46B4C" d="M50.3,23.9l-0.1-0.2c-0.1-0.2-0.2-0.4-0.2-0.5c-0.2-0.7-0.3-1.4-0.1-2.1c0.1-0.5,0.3-0.9,0.5-1.3
		c0.3-0.5,0.7-0.8,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.5c0.6-0.1,1.2,0,1.8,0.1s1.2,0.4,1.7,0.8s0.9,0.8,1.1,1.3c0.3,0.5,0.5,0.9,0.7,1.4
		c0.2,0.4,0.4,0.9,0.6,1.2c0.3,0.6,0.7,1.2,1.2,1.6c0.4,0.4,0.6,0.5,0.6,0.5L60.8,25c-0.2-0.1-0.3-0.2-0.5-0.3c-0.6-0.4-1-1-1.4-1.6
		c-0.5-0.7-0.9-1.6-1.4-2.5c-0.3-0.4-0.6-0.8-1-1.2c-0.4-0.3-1-0.5-1.5-0.6s-1.1-0.1-1.7-0.1c-0.5,0-1,0.2-1.5,0.4
		c-0.4,0.2-0.8,0.5-1.1,0.9c-0.3,0.3-0.5,0.7-0.6,1.2c-0.2,0.7-0.2,1.3-0.1,2c0,0.2,0.1,0.4,0.1,0.5L50.3,23.9L50.3,23.9z"/>
	<path fill="#A46B4C" d="M54,17.3l0.4-0.1c0.3,0,0.7,0,1,0c0.2,0,0.5,0.1,0.7,0.2c0.3,0.1,0.5,0.2,0.7,0.4c0.2,0.2,0.5,0.3,0.7,0.5
		s0.4,0.4,0.6,0.6s0.3,0.5,0.5,0.7s0.3,0.5,0.4,0.7c0.2,0.4,0.4,0.9,0.5,1.3c0.1,0.3,0.2,0.6,0.3,0.9l0.1,0.4l-0.2-0.3l-0.5-0.9
		c-0.2-0.4-0.5-0.8-0.7-1.2c-0.3-0.4-0.6-0.9-0.9-1.3c-0.2-0.2-0.3-0.4-0.5-0.6s-0.4-0.3-0.6-0.5c-0.2-0.1-0.4-0.3-0.6-0.4
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.7-0.2-1-0.2L54,17.3z"/>
	<path fill="#A46B4C" d="M58.3,17.7c0.1,0,0.2,0,0.3,0c0.3,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.5
		c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0.1,0.4,0.1,0.6s0.1,0.4,0.1,0.6c0,0.3,0,0.7,0,1c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.2-0.1,0.3
		c0,0.1,0,0.2-0.1,0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3s0-0.3,0-0.4c0-0.3-0.1-0.6-0.1-1c0-0.2-0.1-0.4-0.1-0.5
		c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.3-0.3-0.7-0.4-1c-0.2-0.3-0.4-0.6-0.6-0.8c-0.2-0.2-0.5-0.4-0.8-0.6H58.3z"/>
	<path fill="#A46B4C" d="M53,21.3c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2,0,0.3,0,0.5
		s0.1,0.3,0.1,0.5s0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.3,0.3,0.4s0.2,0.3,0.3,0.4s0.2,0.2,0.3,0.3l0.3,0.3l0.2,0.2l0.2,0.2L53.8,26
		c-0.2-0.1-0.4-0.2-0.7-0.3c-0.3-0.2-0.5-0.4-0.8-0.7c-0.1-0.1-0.2-0.3-0.3-0.5s-0.2-0.4-0.2-0.6c-0.1-0.2-0.1-0.4-0.1-0.6
		s0-0.4,0-0.6s0.1-0.4,0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.4s0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.1L53,21.3L53,21.3z"/>
	<path fill="#A46B4C" d="M51.3,22.1c0,0.2,0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6
		s0.2,0.3,0.4,0.5c0.2,0.3,0.4,0.4,0.4,0.5l-0.2-0.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
		c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.2,0-0.4,0-0.6C51.1,22.5,51.2,22.3,51.3,22.1L51.3,22.1z"/>
	<path fill="#A46B4C" d="M57.2,26.3v0.1c0,0.1,0,0.2,0,0.3c0,0.2,0,0.3,0.1,0.5s0.1,0.4,0.2,0.6c0.3,0.9,0.8,2.1,1.1,3.4
		c0.2,0.6,0.3,1.3,0.5,1.9c0.1,0.3,0.1,0.6,0.1,0.9c0,0.3,0,0.5,0,0.8c0,0.2,0,0.5-0.1,0.7S59,35.9,58.9,36l-0.3,0.4l0.1-0.4
		c0-0.1,0-0.3,0-0.5s0-0.4,0-0.7s-0.1-0.5-0.1-0.8s-0.1-0.6-0.2-0.8c-0.2-0.6-0.3-1.2-0.5-1.8c-0.4-1.1-0.6-2.3-0.8-3.5
		C57,27.8,57,27.5,57,27.3s0-0.4,0-0.5s0-0.2,0.1-0.3C57.1,26.4,57.1,26.4,57.2,26.3L57.2,26.3z"/>
	<path fill="#A46B4C" d="M58.3,28.4c0,0,0.2,0.4,0.5,1c0.3,0.7,0.6,1.5,0.8,2.3c0.1,0.4,0.1,0.9,0.1,1.3s-0.1,0.7-0.2,1.1
		c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.2,0.3L59,35c0,0,0-0.1,0.1-0.3c0-0.1,0-0.2,0-0.3s0-0.3,0-0.4c0-0.3,0-0.7,0-1
		c0-0.2-0.1-0.4-0.1-0.6s0-0.4-0.1-0.6c-0.2-0.8-0.4-1.6-0.5-2.3c-0.1-0.3-0.1-0.6-0.1-0.8L58.3,28.4L58.3,28.4z"/>
	<path fill="#A46B4C" d="M42.6,17.5l-0.2,0.2c-0.1,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.7-0.3,1c0,0.2,0,0.4,0,0.6s0,0.4,0.1,0.6
		c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.2,0.1,0.4,0.2,0.5s0.2,0.3,0.3,0.5c0.1,0.1,0.2,0.2,0.3,0.4l0.2,0.3l0.2,0.3L43,23.4
		c-0.2-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.3-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.3-0.6s-0.1-0.4-0.2-0.7
		c0-0.2,0-0.5,0-0.7s0.1-0.4,0.1-0.7c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.2,0.4-0.4,0.6-0.6L42.6,17.5L42.6,17.5z"
		/>
	<path fill="#A46B4C" d="M42.3,18.9c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.1,0.7,0,1.1c0,0.2,0.1,0.4,0.2,0.6
		c0.1,0.2,0.1,0.4,0.2,0.5s0.1,0.3,0.1,0.4v0.1L42.6,22c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.2-0.3-0.3-0.5s-0.2-0.4-0.2-0.6
		s0-0.5,0-0.7s0.1-0.4,0.2-0.6C41.9,19.2,42.1,19,42.3,18.9L42.3,18.9z"/>
	<path fill="#A46B4C" d="M41.1,21.1c-0.1-0.2-0.2-0.4-0.2-0.7c-0.1-0.3-0.1-0.5-0.2-0.8c0-0.4,0-0.7,0-1.1s0.1-0.9,0.2-1.3
		c0.1-0.5,0.4-0.9,0.7-1.3c0.4-0.4,0.9-0.7,1.4-0.8c0.3-0.1,0.6-0.1,0.8-0.2l0.8-0.1c0.6,0,1.1,0,1.7,0c0.5,0.1,1.1,0.2,1.6,0.3
		c1,0.3,1.8,0.7,2.6,1c0.4,0.1,0.7,0.2,1,0.3s0.6,0.1,0.8,0.2l0.7,0.1c0,0-0.3,0-0.8,0c-0.2,0-0.5,0-0.9-0.1c-0.4,0-0.7-0.1-1.1-0.2
		c-0.8-0.2-1.7-0.5-2.6-0.8c-0.5-0.1-1-0.2-1.5-0.3c-0.5,0-1,0-1.5,0L44,15.6c-0.2,0.1-0.5,0.1-0.7,0.1c-0.5,0.1-0.9,0.4-1.2,0.7
		c-0.3,0.3-0.6,0.7-0.8,1.1c-0.1,0.4-0.2,0.8-0.3,1.2c0,0.3-0.1,0.7-0.1,1s0,0.5,0,0.8C41,20.9,41,21.1,41.1,21.1L41.1,21.1z"/>
	<path id="smile" fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M43.5,55.2c2.8,0,8.8-2.9,8.8-2.9s-3.7,6.2-8.8,6.2
		s-8.8-6.2-8.8-6.2S40.7,55.2,43.5,55.2z"/>
	<path fill="#896363" d="M33.2,23.1l-1,2.4c0.4-0.3,3.4-0.5,6.4,1.2s10,0.9,13.4-0.8c0.9,0.7,1.9,1.2,3,1.5c2,0.6,2.5,7.2,1.8,8.6
		l0.5,7.6c0,0,1.1-0.5,1.6-2.7c2.2-1.1,2.8-16.3,2.6-19.5c-0.3-4.2-6-7.6-9-9.6s-14.5-2-18.8-0.1s-7.1,6.1-5.4,8.4c0,0,1.3,4,4,5.1
		L33.2,23.1z"/>
</g>
<g>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" d="M43.2,76.4C22.1,76.4,5,59.3,5,38.2S22.1,0,43.2,0
		s38.2,17.1,38.2,38.2l0,0C81.4,59.3,64.3,76.4,43.2,76.4z M43.2,2.9C23.7,2.9,7.9,18.7,7.9,38.2s15.8,35.3,35.3,35.3
		s35.3-15.8,35.3-35.3S62.7,2.9,43.2,2.9z"/>
	<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" points="11.1,56.5 68.7,13.2 71.3,15.7 13,58.9 	"/>
	<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" points="11.3,56.5 68.7,13.3 71.1,15.7 13,58.7 	"/>
</g>
<g>
	<path fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" d="M42.2,172.4C21.1,172.4,4,155.3,4,134.2S21.1,96,42.2,96
		s38.2,17.1,38.2,38.2l0,0C80.4,155.3,63.3,172.4,42.2,172.4z M42.2,98.9c-19.5,0-35.3,15.8-35.3,35.3s15.8,35.3,35.3,35.3
		s35.3-15.8,35.3-35.3S61.7,98.9,42.2,98.9z"/>
	<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" points="10.1,152.5 67.7,109.2 70.3,111.7 12,154.9 	"/>
	<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" points="10.3,152.5 67.7,109.3 70.1,111.7 12,154.7 	"/>
</g>
<g>
	<g>
		<path fill="#F2B387" d="M22.8,252.8c-0.2-0.5-1.4-1.8-2.5-1s0.4,3.3,1.5,5c1.1,1.7,0.7,2.6,0.8,3.6s1,2.8,2.5,2.4
			s-0.9-7.5-0.9-7.5L22.8,252.8z"/>
		<path fill="#F2B387" d="M54.2,252.8c0.2-0.5,1.4-1.8,2.5-1s-0.3,3.3-1.5,5c-0.8,1.2-1.2,2.7-1.2,4.2c-0.1,1-0.6,2.1-2.1,1.9
			s1-7.5,1-7.5L54.2,252.8z"/>
		<path fill="#F2B387" d="M51.2,236.4l-12.7,0.1l-12.7-0.1l-3.7,2.6l2.3,18.2c0.5,2.3,0.8,6.6,1.1,8.5s3.1,5.5,5.3,8.6
			s3.7,3.3,4.7,3.5s2,0.2,3,0c1,0.2,2,0.2,3.1,0c1-0.3,2.5-0.5,4.7-3.5s5-6.7,5.3-8.6s0.6-6.1,1.1-8.5L55,239L51.2,236.4z"/>
		<path fill="#B08080" d="M55.4,233.1c-2.4-7.3-13.8-10.4-16.9-10.3c-3.6,0.1-4.8,0-9.2,1.8c-5.6,2.3-5.9,7-5.9,7
			c-3.7,1-3.5,5.9-2.9,9.6s1.9,11.2,1.9,11.2l1.8,4.8l-0.5-4.9c-0.2-2.1,1.5-4,0.8-5.6s-1.1-7.3,1.5-9s6.7,0,12.5,0s9.9-1.7,12.5,0
			s2.2,7.4,1.5,9s1,3.5,0.8,5.6s0.6,1.4,0.6,1.4l0.6-1.3c0,0,0.7-3.6,1.3-7.4S57,237.8,55.4,233.1L55.4,233.1z"/>
		<path fill="#92583A" d="M55.8,236.4c1.7,3.3,0,9.8,0,9.8s0.2,3.9-1.3,6.2l-0.4-5.7l0.5-11.9L55.8,236.4z"/>
		<path fill="#896363" d="M52.5,260.3c-0.1,0.5-0.3,1.1-0.6,1.6c-0.2,0.5-0.3,1-0.3,1.6c-0.7,3.8-4.7,6.4-5,6.5s-1.4,0.6-1.4,0.6
			s-0.2-0.1,0-0.9s-0.2-3.5-0.9-4c-0.9-0.6-1.9-1.1-3-1.3c-1.3-0.4-2,0.3-2.8,0.3s-1.5-0.6-2.8-0.3c-1.1,0.2-2.1,0.7-3,1.3
			c-0.7,0.5-1.1,3.2-0.9,4c0.2,0.8,0,0.9,0,0.9s-1.1-0.5-1.4-0.6c-0.6-0.4-1.1-0.9-1.6-1.5c-1.8-1.8-3.1-4.1-3.7-6.6
			c-0.1-0.4-0.2-0.5-0.2-0.5s-0.5-0.3-0.5,1.1c0,1.1,0-0.3,0,0.5c0,0.3-0.3,0.3-0.2,1l0,0c0,0.2,0,0.4,0.1,0.6
			c0.3,2.3,0.1,3.8,1.4,6c1.4,2.3,1.9,2.2,2.6,3.5s1.2,1,2.1,2s2.8,2.1,3.8,3.1s3.6,0.9,4.4,1.1c1.3,0,2.5-0.3,3.6-1
			c0.6-0.5,1.3-0.9,2.1-1.2c0.9-0.3,1.6-1.8,2.5-2s1.8-2,2.4-2.5c0.9-0.6,1.6-1.5,1.9-2.6c0.2-1.2,1.7-3.5,1.8-5.3
			c0.1-1.6,0.1-3.2-0.2-4.9L52.5,260.3z M32.5,269.1c0.1-1.1,0.3-2,1.1-2.2s1.2-0.6,1.9-0.7c1,0,2,0.1,3,0.2c1-0.1,2-0.2,3-0.2
			c0.7,0.1,1.1,0.5,1.9,0.7s1,1.1,1.1,2.2c0.1,0.8-0.3,1.7-1,2.1c-0.6,0.3-0.8,1.2-1.3,1.4s-1.3,0.9-1.7,0.6s-1,0.1-1.3-0.4
			c-0.2-0.4-0.1-0.8,0.3-1.1c0.3-0.1,0.6-0.3,0.9-0.5c0.5-0.3,0.3-0.5,0.3-0.5c-0.4,0.2-0.8,0.3-1.2,0.3c-0.3,0-0.6,0-0.9,0.1
			c-0.3-0.1-0.6-0.1-0.9-0.1c-0.4,0-0.8-0.1-1.2-0.3c0,0-0.1,0.2,0.3,0.5c0.3,0.2,0.6,0.4,0.9,0.5c0.3,0.2,0.4,0.7,0.3,1.1
			c-0.3,0.5-0.9,0.1-1.3,0.4s-1.2-0.4-1.7-0.6s-0.8-1.1-1.3-1.4C32.7,270.8,32.3,270,32.5,269.1L32.5,269.1z"/>
		<path fill="#F2B387" d="M28.8,237.6h-0.3c-0.1,0-0.2,0-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.1c-0.4-0.1-0.7-0.3-1-0.5
			c-0.4-0.2-0.7-0.5-1-0.9c-0.5-0.6-1-1.3-1.2-2c-0.1-0.2-0.2-0.5-0.2-0.7s0-0.3,0-0.3l0.1,0.3c0.1,0.2,0.1,0.5,0.3,0.7
			c0.3,0.7,0.8,1.3,1.3,1.9c0.3,0.3,0.6,0.5,1,0.8c0.3,0.2,0.7,0.3,1,0.4c0.1,0,0.3,0.1,0.4,0.1s0.2,0,0.3,0h0.3L28.8,237.6z"/>
		<path fill="#A46B4C" d="M27.5,234.8c0,0-0.1-0.1-0.3-0.3c-0.3-0.3-0.5-0.6-0.8-1c-0.5-0.8-0.8-1.6-0.9-2.6c0-0.3-0.1-0.7-0.1-1
			s0.1-0.7,0.1-1c0.1-0.3,0.2-0.7,0.3-1c0.1-0.3,0.3-0.6,0.5-0.8s0.4-0.5,0.5-0.6s0.4-0.4,0.5-0.5s0.3-0.2,0.4-0.3s0.4-0.3,0.4-0.3
			l-0.3,0.4c-0.1,0.1-0.2,0.3-0.4,0.4c-0.1,0.2-0.3,0.4-0.4,0.5s-0.3,0.4-0.4,0.7s-0.2,0.5-0.3,0.8c-0.1,0.3-0.2,0.6-0.2,0.9
			c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.3,0,0.6,0,0.9c0,0.3,0.1,0.6,0.1,0.9c0.1,0.3,0.1,0.6,0.2,0.8s0.2,0.5,0.3,0.7
			c0.2,0.4,0.3,0.7,0.5,1.1C27.3,234.6,27.4,234.8,27.5,234.8L27.5,234.8z"/>
		<path fill="#A46B4C" d="M26.9,228.4l-0.1,0.3c-0.1,0.3-0.1,0.6-0.1,0.9c0,0.4,0,0.8,0.1,1.3c0,0.2,0.1,0.5,0.2,0.7
			s0.2,0.5,0.3,0.7c0.1,0.2,0.3,0.4,0.4,0.6s0.3,0.4,0.4,0.6c0.1,0.2,0.3,0.3,0.5,0.5c0.2,0.1,0.3,0.3,0.5,0.4l0.4,0.4l0.3,0.3
			l0.3,0.2l-0.3-0.1c-0.3-0.1-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.1-0.4-0.3-0.6-0.5s-0.4-0.4-0.5-0.6
			c-0.2-0.2-0.3-0.5-0.4-0.7s-0.2-0.5-0.3-0.8c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.2,0-0.5,0-0.7s0.1-0.4,0.1-0.6c0.1-0.3,0.2-0.6,0.4-0.9
			C26.8,228.6,26.9,228.5,26.9,228.4L26.9,228.4z"/>
		<path fill="#A46B4C" d="M36.6,225.9l0.1-0.1c0.1-0.1,0.2-0.3,0.4-0.4l0.3-0.2l0.4-0.2c0.1-0.1,0.3-0.2,0.5-0.3s0.4-0.2,0.5-0.2
			c0.4-0.1,0.8-0.2,1.3-0.3s1-0.1,1.5-0.1s1.1,0,1.6,0.1c0.3,0,0.5,0.1,0.8,0.1c0.3,0.1,0.5,0.1,0.8,0.2c0.5,0.2,1,0.4,1.5,0.6
			c0.5,0.3,0.9,0.6,1.3,0.8c0.4,0.2,0.8,0.4,1.3,0.5c0.4,0.1,0.8,0.2,1.2,0.2c0.6,0,1.3,0,1.8-0.3c0.2-0.1,0.4-0.2,0.6-0.3l-0.1,0.1
			c-0.1,0.1-0.3,0.2-0.5,0.3c-0.6,0.3-1.2,0.5-1.9,0.5c-1,0-1.9-0.2-2.7-0.6c-0.5-0.2-0.9-0.5-1.3-0.7c-0.5-0.2-0.9-0.4-1.4-0.6
			c-0.2-0.1-0.5-0.1-0.8-0.2c-0.2-0.1-0.5-0.1-0.8-0.1c-0.5-0.1-1-0.1-1.5-0.2c-0.9-0.1-1.8,0-2.7,0.2c-0.2,0-0.4,0.1-0.5,0.1
			s-0.3,0.1-0.5,0.2l-0.4,0.2l-0.3,0.2c-0.2,0.1-0.3,0.2-0.4,0.3L36.6,225.9L36.6,225.9z"/>
		<path fill="#A46B4C" d="M35.8,234.5c0,0-0.1-0.1-0.3-0.3c-0.1-0.1-0.3-0.2-0.4-0.4c-0.2-0.2-0.3-0.3-0.5-0.5l-0.2-0.3
			c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.3-0.3-0.5-0.4-0.8c-0.1-0.3-0.2-0.6-0.3-0.9c-0.1-0.3-0.1-0.7-0.1-1s0-0.7,0-1s0.1-0.6,0.2-0.9
			c0.1-0.3,0.2-0.6,0.3-0.8s0.3-0.5,0.5-0.6l0.3-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3l0.4-0.2c0,0-0.1,0.1-0.3,0.3
			c-0.1,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.3,0.3-0.4,0.5c-0.1,0.2-0.3,0.4-0.3,0.6c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.3-0.1,0.6-0.1,0.9
			c0,0.3,0,0.6,0,0.9c0,0.3,0.1,0.6,0.1,0.9c0.1,0.3,0.1,0.6,0.2,0.9s0.2,0.5,0.3,0.8c0.1,0.2,0.2,0.5,0.3,0.7s0.2,0.4,0.3,0.6
			c0.1,0.1,0.2,0.3,0.3,0.4L35.8,234.5L35.8,234.5z"/>
		<path fill="#A46B4C" d="M43,237.6h-0.2c-0.2,0-0.3-0.1-0.5-0.1c-0.6-0.2-1.1-0.5-1.5-0.9c-0.6-0.6-1.1-1.3-1.3-2.1
			c-0.3-0.9-0.2-1.9,0.2-2.8c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.4,0.7-0.7,1.1-1c0.7-0.5,1.6-0.7,2.5-0.7c0.6,0,1.2,0.1,1.8,0.3
			c0.2,0.1,0.4,0.1,0.6,0.3c0,0-0.2-0.1-0.7-0.1c-0.6-0.1-1.1-0.1-1.7,0c-0.8,0.1-1.5,0.4-2.2,0.8c-0.4,0.2-0.7,0.5-0.9,0.9
			c-0.3,0.3-0.5,0.7-0.7,1.1c-0.2,0.4-0.3,0.8-0.3,1.2s0,0.8,0.1,1.2c0.2,0.7,0.5,1.4,1.1,2c0.4,0.4,0.8,0.8,1.3,1
			C42.6,237.4,42.8,237.5,43,237.6L43,237.6z"/>
		<path fill="#A46B4C" d="M44.6,237.6h-0.3c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.3-0.1-0.5-0.2c-0.4-0.2-0.7-0.4-1-0.7
			c-0.2-0.2-0.3-0.3-0.5-0.5s-0.3-0.4-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.1-0.5-0.1-0.7s0-0.4,0-0.6
			c0-0.2,0.1-0.4,0.2-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3l0.2-0.2l-0.1,0.3c-0.1,0.1-0.1,0.2-0.1,0.3
			s-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0,0.5s0,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.2,0.4,0.3,0.5
			c0.1,0.2,0.3,0.3,0.4,0.5c0.3,0.3,0.5,0.5,0.8,0.8c0.1,0.1,0.3,0.2,0.4,0.3L44.6,237.6L44.6,237.6L44.6,237.6z"/>
		<path fill="#A46B4C" d="M32.5,230.8v0.1c0,0.1,0,0.2,0,0.4c0,0.4,0.1,0.8,0.3,1.2c0.1,0.3,0.2,0.5,0.3,0.8s0.3,0.5,0.4,0.8
			c0.2,0.3,0.4,0.5,0.5,0.8c0.2,0.3,0.4,0.5,0.7,0.8c0.2,0.2,0.5,0.5,0.8,0.7s0.5,0.4,0.8,0.6s0.5,0.3,0.8,0.4s0.5,0.2,0.8,0.3
			l0.7,0.2l0.5,0.1l0.5,0.1h-1.1l-0.8-0.1c-0.3-0.1-0.6-0.1-0.9-0.2c-0.3-0.1-0.6-0.2-0.9-0.4c-0.3-0.2-0.6-0.4-0.9-0.6
			c-0.3-0.2-0.6-0.5-0.8-0.7c-0.3-0.3-0.5-0.5-0.7-0.8s-0.4-0.6-0.5-0.9c-0.1-0.3-0.3-0.6-0.4-0.9c-0.1-0.3-0.2-0.6-0.2-0.8
			c-0.1-0.4-0.1-0.9,0-1.3c0-0.1,0-0.2,0.1-0.3L32.5,230.8L32.5,230.8z"/>
		<path fill="#A46B4C" d="M31.9,234.6l-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.5l-0.3-0.4c-0.1-0.1-0.2-0.3-0.3-0.5
			c-0.2-0.4-0.4-0.8-0.5-1.2c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.1-0.5-0.1-0.8c-0.1-0.6-0.1-1.2-0.1-1.7c0-0.6,0.2-1.2,0.4-1.8
			c0.2-0.6,0.5-1.2,0.9-1.7s1-1,1.6-1.3c0.6-0.3,1.3-0.5,2-0.5c0.6,0,1.2,0,1.8,0.1s1.1,0.1,1.6,0.2c0.4,0,0.9,0,1.3,0l0.5-0.1
			c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.4-0.2,0.6-0.3l0.2-0.1l-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0.1-0.3,0.1-0.4,0.2l-0.5,0.2
			c-0.5,0.1-0.9,0.1-1.4,0.1c-1,0-2.2-0.2-3.3-0.1c-0.7,0-1.5,0.3-2.1,0.7l-0.2,0.1l-0.2,0.1L32,225l-0.3,0.3
			c-0.4,0.5-0.7,1-0.9,1.6c-0.2,0.5-0.3,1.1-0.4,1.7c0,0.5,0,1.1,0,1.6c0,0.3,0,0.5,0.1,0.8c0,0.2,0.1,0.5,0.1,0.7
			c0.1,0.4,0.2,0.8,0.4,1.2l0.2,0.5l0.2,0.4c0.1,0.2,0.2,0.4,0.3,0.6L31.9,234.6L31.9,234.6z"/>
		<path fill="#A46B4C" d="M33.3,232.9c0,0.1,0.1,0.2,0.1,0.2c0.1,0.2,0.2,0.4,0.3,0.6c0.4,0.5,0.8,1,1.3,1.5s1,0.9,1.6,1.2l0.6,0.4
			l0.2,0.1h-0.3c-0.4-0.1-0.8-0.2-1.1-0.4c-0.2-0.1-0.3-0.1-0.5-0.2c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.3-0.5-0.6-0.7-0.9
			c-0.2-0.3-0.3-0.6-0.4-0.9c-0.1-0.2-0.1-0.5-0.1-0.7C33.2,233.1,33.2,233,33.3,232.9L33.3,232.9z"/>
		<path fill="#A46B4C" d="M31.8,235.2c0.1,0.2,0.3,0.5,0.5,0.6c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.2,0.5,0.4,0.8,0.5
			c0.3,0.2,0.5,0.4,0.8,0.5s0.5,0.3,0.7,0.4c0.2,0.1,0.5,0.3,0.7,0.4h-0.2c-0.5-0.1-1-0.2-1.5-0.4c-0.3-0.1-0.6-0.3-0.9-0.5
			c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.1-0.2-0.2-0.3-0.4c-0.1-0.1-0.2-0.2-0.2-0.4c-0.1-0.2-0.2-0.4-0.2-0.6
			C31.8,235.4,31.8,235.3,31.8,235.2L31.8,235.2z"/>
		<path fill="#A46B4C" d="M45.6,235.5l-0.1-0.2c-0.1-0.2-0.2-0.4-0.2-0.5c-0.2-0.7-0.3-1.4-0.1-2.1c0.1-0.5,0.3-0.9,0.5-1.3
			c0.3-0.5,0.7-0.9,1.2-1.1c0.5-0.3,1.1-0.4,1.7-0.5c0.6-0.1,1.2,0,1.8,0.1s1.2,0.4,1.7,0.8c0.5,0.4,0.8,0.8,1.1,1.4
			c0.3,0.5,0.5,0.9,0.7,1.4c0.2,0.4,0.4,0.9,0.6,1.2c0.3,0.6,0.7,1.2,1.2,1.6c0.2,0.2,0.4,0.4,0.6,0.5l-0.2-0.1
			c-0.2-0.1-0.3-0.2-0.5-0.3c-0.6-0.4-1-1-1.4-1.6c-0.5-0.7-0.9-1.6-1.4-2.5c-0.3-0.4-0.6-0.8-1-1.2c-0.4-0.3-0.9-0.5-1.5-0.6
			s-1.1-0.1-1.7-0.1c-0.5,0-1.1,0.2-1.5,0.4s-0.8,0.5-1.1,0.9c-0.3,0.3-0.5,0.7-0.6,1.2c-0.2,0.7-0.2,1.3-0.1,2
			c0,0.2,0.1,0.4,0.1,0.6C45.6,235.5,45.6,235.6,45.6,235.5L45.6,235.5z"/>
		<path fill="#A46B4C" d="M49.3,228.9l0.4-0.1c0.4,0,0.7,0,1.1,0c0.2,0,0.5,0.1,0.7,0.2c0.3,0.1,0.5,0.2,0.7,0.3
			c0.2,0.2,0.5,0.3,0.7,0.5s0.4,0.4,0.6,0.6c0.2,0.2,0.3,0.4,0.5,0.7s0.3,0.5,0.4,0.7c0.2,0.4,0.4,0.9,0.5,1.3
			c0.1,0.3,0.2,0.6,0.3,0.9l0.1,0.4l-0.2-0.3l-0.5-0.9c-0.2-0.4-0.4-0.8-0.7-1.2s-0.6-0.9-0.9-1.3c-0.2-0.2-0.3-0.4-0.5-0.6
			c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.1-0.7-0.2-1-0.2L49.3,228.9L49.3,228.9z"/>
		<path fill="#A46B4C" d="M53.7,229.3c0.1,0,0.2,0,0.3,0c0.3,0.1,0.5,0.2,0.7,0.3c0.1,0.1,0.3,0.2,0.4,0.3c0.1,0.1,0.3,0.3,0.4,0.4
			c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.2,0.1,0.4,0.2,0.6c0,0.2,0.1,0.4,0.1,0.6c0,0.3,0,0.7,0,1.1c0,0.1,0,0.3-0.1,0.4
			c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.3c0-0.1,0-0.2,0-0.3s0-0.2,0-0.3s0-0.3,0-0.4c0-0.3,0-0.6-0.1-1c0-0.2-0.1-0.4-0.1-0.6
			c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.2-0.7-0.4-1s-0.4-0.6-0.6-0.8C54.4,229.7,54.1,229.5,53.7,229.3L53.7,229.3z"/>
		<path fill="#A46B4C" d="M48.3,232.9l-0.2,0.2c-0.1,0.1-0.3,0.3-0.3,0.5c-0.1,0.3-0.2,0.5-0.2,0.8c0,0.2,0,0.3,0,0.5
			s0.1,0.3,0.1,0.5s0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.3,0.2,0.4c0.1,0.1,0.2,0.3,0.3,0.4s0.2,0.2,0.3,0.3l0.3,0.3l0.2,0.2l0.2,0.2
			l-0.3-0.1c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.2-0.6-0.4-0.8-0.7c-0.1-0.1-0.2-0.3-0.3-0.5s-0.2-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.6
			c0-0.2,0-0.4,0-0.6c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.2-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3s0.2-0.1,0.3-0.1L48.3,232.9
			L48.3,232.9z"/>
		<path fill="#A46B4C" d="M46.6,233.7c0,0,0,0.3,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6c0.1,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.6
			c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.3,0.4,0.4,0.4,0.5L48,237c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.3-0.4-0.4-0.7c-0.1-0.2-0.2-0.5-0.2-0.7s0-0.4,0-0.7C46.5,234.1,46.6,233.9,46.6,233.7
			L46.6,233.7z"/>
		<path fill="#A46B4C" d="M52.5,238v0.1c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.3,0.1,0.5s0.1,0.4,0.2,0.6c0.3,0.9,0.8,2.1,1.2,3.4
			c0.2,0.6,0.3,1.3,0.5,1.9c0.1,0.3,0.1,0.6,0.1,0.9s0,0.6,0,0.8s-0.1,0.5-0.1,0.7s-0.1,0.3-0.2,0.5c-0.1,0.1-0.2,0.3-0.3,0.4
			l0.1-0.4c0-0.2,0-0.3,0-0.5s0-0.4,0-0.6s-0.1-0.5-0.1-0.8s-0.1-0.6-0.2-0.9c-0.2-0.6-0.3-1.2-0.5-1.8c-0.4-1.1-0.7-2.3-0.9-3.5
			c0-0.2-0.1-0.5-0.1-0.7s0-0.4,0-0.5s0-0.2,0.1-0.3C52.5,238.1,52.5,238,52.5,238L52.5,238z"/>
		<path fill="#A46B4C" d="M53.7,240.1c0,0,0.2,0.4,0.5,1c0.4,0.7,0.6,1.5,0.8,2.3c0.1,0.4,0.1,0.9,0.1,1.3c0,0.4-0.1,0.8-0.2,1.1
			c0,0.1-0.1,0.3-0.2,0.4c0,0.1-0.1,0.2-0.2,0.3l-0.2,0.3l0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.3,0-0.4c0-0.3,0-0.7,0-1
			c0-0.2-0.1-0.4-0.1-0.6s-0.1-0.4-0.1-0.6c-0.2-0.8-0.4-1.6-0.5-2.3c-0.1-0.3-0.1-0.5-0.1-0.8L53.7,240.1L53.7,240.1z"/>
		<path fill="#A46B4C" d="M37.9,229.1l-0.2,0.2c-0.2,0.2-0.3,0.4-0.4,0.7c-0.1,0.3-0.2,0.7-0.3,1c0,0.2,0,0.4,0,0.6
			c0,0.2,0,0.4,0,0.6c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.2,0.2,0.4,0.3,0.5s0.2,0.3,0.3,0.4s0.2,0.3,0.3,0.4l0.2,0.3l0.2,0.3l-0.3-0.2
			c-0.2-0.1-0.5-0.3-0.7-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.3-0.3-0.5s-0.2-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.2-0.7
			c0-0.2,0-0.5,0-0.7s0.1-0.4,0.1-0.7c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.2-0.3,0.3-0.5c0.2-0.2,0.4-0.4,0.6-0.6L37.9,229.1
			L37.9,229.1z"/>
		<path fill="#A46B4C" d="M37.6,230.6c-0.1,0.2-0.1,0.3-0.1,0.5c-0.1,0.4-0.1,0.7,0,1.1c0,0.2,0.1,0.4,0.2,0.6
			c0.1,0.2,0.1,0.4,0.2,0.5l0.1,0.4v0.1l-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3s-0.2-0.3-0.3-0.5s-0.2-0.4-0.2-0.6c0-0.2,0-0.5,0-0.7
			s0.1-0.4,0.2-0.6C37.2,230.8,37.4,230.7,37.6,230.6L37.6,230.6z"/>
		<path fill="#A46B4C" d="M36.4,232.8c-0.1-0.2-0.2-0.5-0.2-0.7c-0.1-0.3-0.1-0.5-0.2-0.8c-0.1-0.4-0.1-0.7,0-1.1
			c0-0.4,0.1-0.9,0.2-1.3c0.2-0.5,0.4-0.9,0.8-1.3c0.4-0.4,0.9-0.7,1.4-0.9c0.3-0.1,0.6-0.1,0.8-0.2l0.8-0.1c0.6,0,1.1,0,1.7,0
			c0.5,0.1,1.1,0.2,1.6,0.3c1,0.3,1.8,0.7,2.6,1c0.4,0.1,0.7,0.2,1,0.3s0.6,0.1,0.8,0.2l0.7,0.1h-0.8c-0.3,0-0.6,0-0.9-0.1
			c-0.4,0-0.7-0.1-1.1-0.2c-0.8-0.2-1.7-0.5-2.7-0.8c-0.5-0.1-1-0.2-1.5-0.3c-0.5,0-1,0-1.6,0L39,227c-0.2,0.1-0.5,0.1-0.7,0.1
			c-0.5,0.1-0.9,0.4-1.2,0.7s-0.6,0.7-0.8,1.1c-0.2,0.4-0.3,0.8-0.3,1.2c0,0.3-0.1,0.7-0.1,1s0,0.6,0,0.8
			C36.3,232.5,36.4,232.7,36.4,232.8L36.4,232.8z"/>
		<path fill="#896363" d="M28.4,234.8l-1,2.4c0.5-0.3,3.4-0.5,6.5,1.2s10,0.9,13.5-0.8c0.9,0.7,1.9,1.2,3,1.5
			c2.1,0.6,2.6,7.3,1.9,8.7l0.5,7.6c0,0,1.1-0.6,1.6-2.7c2.2-1.1,2.8-16.3,2.6-19.6c-0.3-4.3-6-7.6-9-9.7s-14.6-2-18.9-0.1
			s-7.2,6.2-5.5,8.4c0,0,1.3,4.1,4,5.1L28.4,234.8z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" fill="#F9E4BB" d="M36.2,257.9c0,0-2.2,0.6-2.5,1.6s0.1,2.6,1,2.8
			c0.8,0.3,1.7,0.3,2.5,0.1c0.4-0.1,0.8-0.2,1.2-0.3l1.1,0.3c0.7,0.2,1.4,0.3,2.1,0.1c1-0.3,1.9-1,1.9-1.7s-0.4-2.5-3.2-3.2
			L36.2,257.9z"/>
		<path fill-rule="evenodd" clip-rule="evenodd" fill="#CCA38F" d="M37.8,257.2c0,0-1.6,0.3-1.2,0.9c0.5,0.6,1.1,1.1,1.7,1.5
			l1.9-1.5C40.2,258,40,257.2,37.8,257.2z"/>
		<g id="stars">
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#FD8B39" d="M65.7,255.9c0,0,0.6,2,1.1,3.6c0.4,1.4,1.5,2.5,2.9,2.9l3.6,1.1
				l-3.6,1c-1.4,0.4-2.5,1.5-2.9,2.9c-0.5,1.7-1.1,3.7-1.1,3.7l-1.1-3.6c-0.4-1.4-1.5-2.5-2.9-2.9l-3.6-1.1l3.6-1.1
				c1.4-0.4,2.5-1.5,2.9-2.9L65.7,255.9z"/>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#FD8B39" d="M8.6,232.9l0.8,2.6c0.3,1,1.1,1.8,2.1,2.1l2.6,0.8l-2.6,0.8
				c-1,0.3-1.8,1.1-2.1,2.1L8.6,244c0,0-0.4-1.4-0.8-2.7c-0.3-1-1.1-1.8-2.1-2.1L3,238.4l2.7-0.8c1-0.3,1.8-1.1,2.1-2.1L8.6,232.9z"
				/>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#FD8B39" d="M21.6,265.9l0.5,1.7c0.2,0.7,0.7,1.2,1.4,1.4l1.7,0.5l-1.7,0.5
				c-0.7,0.2-1.2,0.7-1.4,1.4l-0.5,1.7l-0.5-1.7c-0.2-0.7-0.7-1.2-1.4-1.4l-1.7-0.5l1.7-0.5c0.7-0.2,1.2-0.7,1.4-1.4L21.6,265.9z"/>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#FD8B39" d="M61.7,226.6l0.7,2.3c0.3,0.9,1,1.6,1.8,1.8l2.3,0.7l-2.3,0.7
				c-0.9,0.3-1.6,1-1.8,1.8l-0.7,2.3l-0.6-2.3c-0.3-0.9-1-1.6-1.8-1.8l-2.3-0.7l2.3-0.7c0.9-0.3,1.6-1,1.8-1.8L61.7,226.6z"/>
		</g>
	</g>
	<g>
		<path fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" d="M38.2,286.4C17.1,286.4,0,269.3,0,248.2S17.1,210,38.2,210
			s38.2,17.1,38.2,38.2l0,0C76.4,269.3,59.3,286.4,38.2,286.4z M38.2,212.9c-19.5,0-35.3,15.8-35.3,35.3s15.8,35.3,35.3,35.3
			s35.3-15.8,35.3-35.3S57.7,212.9,38.2,212.9L38.2,212.9z"/>
		<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" points="6.1,266.5 63.7,223.2 66.3,225.7 8,268.9 		"/>
		<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#FF1D25" points="6.3,266.5 63.7,223.3 66.1,225.7 8,268.7 		"/>
	</g>
	<g id="ears">
		<g>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#E8D2A7" d="M15.4,214.4c0,0,7.3,7.5,8.1,9.5s2,4.6,2,4.6s-0.1,1.9,0.7,2
				c0.8,0.1,1.6,0,2.3-0.3c1.2-0.4,2.3-1,3.4-1.8c0.3-0.3,0.5-0.8,0.6-1.3l-0.6-1.9c0,0-1.6-7.6-1.8-8.4s-2.7-8.1-12.1-7
				c0,0-6.1,0.5-6.3,2.9S14,214.8,15.4,214.4z"/>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#E3B9A5" d="M18.1,214.4c0,0,4.7,4.6,5.9,6.1s2.8,3.9,2.8,3.9l0.7,1.3
				c0.2-0.6,0.2-1.2,0.3-1.9c-0.1-0.4-0.8-10.1-5.7-10.6C20.8,213.7,19.5,214.1,18.1,214.4z"/>
		</g>
		<g>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#E8D2A7" d="M60.5,214.4c0,0-7.3,7.5-8.1,9.5s-2,4.6-2,4.6s0.2,1.9-0.7,2
				c-0.8,0.1-1.6,0-2.3-0.3c-1.2-0.4-2.3-1-3.4-1.8c-0.3-0.3-0.5-0.8-0.6-1.3l0.6-1.9c0,0,1.6-7.6,1.8-8.4s2.7-8.1,12.1-7
				c0,0,6.1,0.5,6.3,2.9S61.9,214.8,60.5,214.4z"/>
			<path fill-rule="evenodd" clip-rule="evenodd" fill="#E3B9A5" d="M57.8,214.4c0,0-4.7,4.6-5.9,6.1s-2.9,3.9-2.9,3.9l-0.7,1.3
				c-0.2-0.6-0.2-1.2-0.3-1.9c0.1-0.4,0.8-10.1,5.7-10.6C55.1,213.7,56.4,214.1,57.8,214.4z"/>
		</g>
	</g>
</g>
<g>
	<path fill="#3E3E42" d="M4.3,184.4v-7.8h5.3v1.2h-4v2h3.9v1.2H5.6v3.4L4.3,184.4L4.3,184.4z"/>
	<path fill="#3E3E42" d="M14.2,184.4v-0.6c-0.5,0.5-1.1,0.8-1.8,0.7c-1,0.1-1.9-0.7-2-1.7v-0.1c0-1,0.8-1.8,1.8-1.8
		c0.1,0,0.1,0,0.2,0c0.7,0,1.4,0.2,1.8,0.7v-0.8c0-0.6-0.5-1.1-1.3-1.1c-0.6,0-1.2,0.3-1.7,0.7l-0.5-0.9c0.6-0.6,1.5-0.9,2.4-0.9
		c1.3,0,2.3,0.5,2.3,2v3.7L14.2,184.4z M14.2,183.1v-0.9c-0.3-0.4-0.8-0.6-1.3-0.6c-0.6,0-1,0.4-1,1s0.4,1,1,1
		C13.4,183.7,13.9,183.5,14.2,183.1z"/>
	<path fill="#3E3E42" d="M16.7,181.5c-0.1-1.6,1.1-2.9,2.7-2.9c0.1,0,0.1,0,0.2,0c0.8,0,1.6,0.3,2.1,1l-0.8,0.8
		c-0.3-0.4-0.8-0.7-1.3-0.7c-1,0.1-1.8,1-1.7,2c0.1,0.9,0.8,1.7,1.7,1.7c0.5,0,1-0.2,1.3-0.7l0.8,0.7c-0.5,0.7-1.3,1-2.1,1
		c-1.6,0.1-2.9-1.2-2.9-2.7C16.7,181.7,16.7,181.6,16.7,181.5z"/>
	<path fill="#3E3E42" d="M22.5,181.5c-0.1-1.6,1.2-2.9,2.7-2.9h0.1c1.5,0,2.8,1.3,2.8,2.8c0,0.1,0,0.2,0,0.3v0.3h-4.3
		c0.1,0.9,0.9,1.6,1.8,1.6c0.6,0,1.2-0.2,1.6-0.6l0.6,0.8c-0.6,0.5-1.4,0.8-2.3,0.8c-1.6,0.1-2.9-1.1-2.9-2.7
		C22.5,181.7,22.5,181.6,22.5,181.5z M25.3,179.6c-0.8,0-1.5,0.6-1.6,1.5h3.2C26.9,180.3,26.2,179.6,25.3,179.6
		C25.4,179.6,25.3,179.6,25.3,179.6z"/>
	<path fill="#3E3E42" d="M35.7,184.4v-0.6c-0.5,0.5-1.1,0.8-1.8,0.7c-1,0-1.9-0.8-1.9-1.8l0,0c0-1,0.8-1.8,1.8-1.8
		c0.1,0,0.1,0,0.2,0c0.7,0,1.4,0.2,1.8,0.7v-0.8c0-0.6-0.5-1.1-1.3-1.1c-0.6,0-1.2,0.3-1.7,0.7l-0.5-0.9c0.6-0.6,1.5-0.9,2.4-0.9
		c1.3,0,2.3,0.5,2.3,2v3.7L35.7,184.4z M35.7,183.1v-0.9c-0.3-0.4-0.8-0.6-1.3-0.6c-0.6,0-1,0.4-1,1s0.4,1,1,1
		C34.9,183.7,35.4,183.5,35.7,183.1z"/>
	<path fill="#3E3E42" d="M42.3,184.4v-3.5c0-0.9-0.4-1.2-1.1-1.2c-0.6,0-1.1,0.3-1.4,0.8v3.9h-1.2v-5.6h1.2v0.8
		c0.5-0.6,1.2-0.9,2-0.9c0.9-0.1,1.7,0.5,1.8,1.4c0,0.1,0,0.3,0,0.4v3.9L42.3,184.4L42.3,184.4z"/>
	<path fill="#3E3E42" d="M49.1,184.4v-0.8c-0.4,0.6-1.1,0.9-1.8,0.9c-1.4,0-2.5-1.1-2.5-2.9s1-3,2.5-3c0.7,0,1.4,0.3,1.8,0.9v-2.9
		h1.2v7.8H49.1z M49.1,182.7v-2.2c-0.3-0.5-0.9-0.7-1.5-0.8c-1,0-1.6,0.8-1.6,1.9c-0.1,0.9,0.5,1.7,1.4,1.8c0.1,0,0.1,0,0.2,0
		C48.2,183.4,48.7,183.1,49.1,182.7L49.1,182.7z"/>
	<path fill="#3E3E42" d="M54.9,184.4v-7.8h5.3v1.2h-4v2h3.9v1.2h-3.8v2.2h4v1.2L54.9,184.4L54.9,184.4z"/>
	<path fill="#3E3E42" d="M61.5,185.5c0.1,0.1,0.3,0.1,0.5,0.1c0.3,0,0.7-0.2,0.8-0.5l0.3-0.6l-2.3-5.7h1.3l1.6,4.3l1.6-4.2h1.3
		l-2.7,6.6c-0.3,0.8-1.1,1.4-2,1.3c-0.2,0-0.5,0-0.7-0.1L61.5,185.5z"/>
	<path fill="#3E3E42" d="M67.1,181.5c-0.1-1.6,1.2-2.9,2.7-2.9h0.1c1.5,0,2.8,1.3,2.8,2.8c0,0.1,0,0.2,0,0.3v0.3h-4.4
		c0.1,0.9,0.9,1.6,1.8,1.6c0.6,0,1.2-0.2,1.6-0.6l0.6,0.8c-0.6,0.5-1.4,0.8-2.3,0.8c-1.6,0.1-2.9-1.1-2.9-2.7
		C67.1,181.7,67.1,181.6,67.1,181.5z M70,179.6c-0.8,0-1.5,0.6-1.6,1.5h3.2C71.6,180.3,70.9,179.6,70,179.6L70,179.6z"/>
	<path fill="#3E3E42" d="M73.4,183.6l0.6-0.9c0.5,0.5,1.2,0.8,1.9,0.8c0.7,0,1.1-0.3,1.1-0.8c0-1.1-3.4-0.3-3.4-2.5
		c0-0.9,0.8-1.7,2.2-1.7c0.8,0,1.6,0.3,2.2,0.8l-0.5,0.9c-0.4-0.5-1-0.7-1.7-0.7c-0.6,0-1,0.3-1,0.7c0,1,3.4,0.3,3.4,2.5
		c0,1-0.8,1.7-2.3,1.7C75,184.5,74.1,184.2,73.4,183.6z"/>
	<path fill="#3E3E42" d="M10.6,197.2v-7.8h5.3v1.2h-4v2h3.9v1.2H12v3.4L10.6,197.2z"/>
	<path fill="#3E3E42" d="M20.9,197.2v-0.8c-0.5,0.6-1.2,0.9-2,0.9c-0.9,0.1-1.7-0.5-1.8-1.4c0-0.1,0-0.3,0-0.4v-4h1.2v3.5
		c0,0.9,0.4,1.1,1.1,1.1c0.6,0,1.1-0.3,1.5-0.7v-3.9h1.2v5.6L20.9,197.2z"/>
	<path fill="#3E3E42" d="M23.7,197.2v-7.8h1.2v7.8H23.7z"/>
	<path fill="#3E3E42" d="M26.5,197.2v-7.8h1.2v7.8H26.5z"/>
	<path fill="#3E3E42" d="M29.2,198.3c0.1,0.1,0.3,0.1,0.5,0.1c0.4,0,0.7-0.2,0.8-0.5l0.3-0.6l-2.3-5.7h1.3l1.6,4.2l1.6-4.2h1.3
		l-2.7,6.6c-0.3,0.8-1.1,1.3-1.9,1.3c-0.2,0-0.5,0-0.7-0.1L29.2,198.3z"/>
	<path fill="#3E3E42" d="M40.4,197.2l-3-7.8H39l2.3,6.3l2.3-6.3h1.5l-3,7.8H40.4z"/>
	<path fill="#3E3E42" d="M45.8,190.1c0-0.4,0.3-0.8,0.7-0.8s0.8,0.3,0.8,0.7c0,0.1,0,0.1,0,0.2c0,0.4-0.4,0.7-0.8,0.7
		C46.1,190.7,45.9,190.5,45.8,190.1z M46,197.2v-5.6h1.2v5.6H46z"/>
	<path fill="#3E3E42" d="M48.3,196.4l0.6-0.9c0.5,0.5,1.2,0.8,1.9,0.8s1.1-0.3,1.1-0.8c0-1.1-3.4-0.3-3.4-2.5c0-0.9,0.8-1.7,2.2-1.7
		c0.8,0,1.6,0.3,2.2,0.8l-0.5,0.9c-0.4-0.4-1-0.7-1.7-0.7c-0.6,0-1,0.3-1,0.7c0,1,3.4,0.3,3.4,2.5c0,1-0.8,1.8-2.3,1.8
		C49.8,197.3,49,197,48.3,196.4z"/>
	<path fill="#3E3E42" d="M54.1,190.1c0-0.4,0.3-0.8,0.7-0.8s0.8,0.3,0.8,0.7c0,0.1,0,0.1,0,0.2c0,0.4-0.4,0.7-0.8,0.7
		C54.4,190.7,54.2,190.5,54.1,190.1z M54.3,197.2v-5.6h1.2v5.6H54.3z"/>
	<path fill="#3E3E42" d="M57.1,197.2v-7.8h1.2v2.9c0.4-0.6,1.1-0.9,1.8-0.9c1.4,0,2.5,1.1,2.5,3s-1.1,2.9-2.5,2.9
		c-0.7,0-1.4-0.3-1.8-0.9v0.8H57.1z M59.8,196.2c0.9,0,1.6-0.8,1.6-1.7c0-0.1,0-0.1,0-0.2c0-1.1-0.6-1.9-1.6-1.9
		c-0.6,0-1.1,0.3-1.4,0.8v2.2C58.6,195.9,59.2,196.2,59.8,196.2z"/>
	<path fill="#3E3E42" d="M63.8,197.2v-7.8H65v7.8H63.8z"/>
	<path fill="#3E3E42" d="M66.3,194.3c-0.1-1.6,1.2-2.9,2.7-2.9h0.1c1.5,0,2.8,1.3,2.8,2.8c0,0.1,0,0.2,0,0.3v0.3h-4.3
		c0.1,0.9,0.9,1.6,1.8,1.6c0.6,0,1.2-0.2,1.6-0.6l0.6,0.8c-0.6,0.6-1.4,0.9-2.3,0.8c-1.6,0.1-2.9-1.1-2.9-2.7
		C66.3,194.5,66.3,194.4,66.3,194.3z M69.2,192.4c-0.8,0-1.6,0.6-1.6,1.5h3.2C70.7,193.1,70,192.4,69.2,192.4L69.2,192.4z"/>
</g>
<g>
	<g>
		<g>
			<path fill="#3E3E42" d="M34.08,294.54c-0.1-2.2,1.7-4.1,4-4.2c0.1,0,0.2,0,0.3,0c1.3,0,2.5,0.6,3.2,1.7l-1.2,0.6
				c-0.5-0.7-1.3-1.1-2.1-1.1c-1.6,0.1-2.8,1.5-2.7,3.1c0.1,1.5,1.3,2.7,2.7,2.7c0.7,0,1.4-0.3,1.9-0.7v-1.3h-2.4v-1.2h3.8v3
				c-0.8,0.9-2.1,1.5-3.3,1.5c-2.3,0.1-4.1-1.7-4.2-3.9C34.08,294.64,34.08,294.64,34.08,294.54z"/>
			<path fill="#3E3E42" d="M43.08,298.54v-8h1.3v8H43.08z"/>
			<path fill="#3E3E42" d="M49.58,298.54v-0.6c-0.5,0.5-1.2,0.8-1.9,0.8c-1.1,0-2-0.8-2-1.9c0,0,0,0,0-0.1c0-1,0.8-1.9,1.9-1.9h0.1
				c0.7,0,1.4,0.2,1.9,0.8v-0.9c0-0.7-0.6-1.1-1.4-1.1c-0.7,0-1.3,0.3-1.7,0.7l-0.5-0.9c0.7-0.6,1.5-1,2.5-0.9
				c1.3,0,2.4,0.5,2.4,2.1v3.9H49.58z M49.58,297.24v-0.9c-0.3-0.4-0.9-0.6-1.4-0.6c-0.6-0.1-1.1,0.3-1.2,0.9
				c-0.1,0.6,0.3,1.1,0.9,1.2c0.1,0,0.2,0,0.4,0C48.68,297.84,49.28,297.64,49.58,297.24L49.58,297.24z"/>
			<path fill="#3E3E42" d="M59.68,298.54v-3.8c0-0.6-0.3-1-1-1c-0.5,0-1,0.3-1.3,0.8v4.1h-1.2v-3.8c0-0.6-0.3-1-1-1
				c-0.5,0-1,0.3-1.3,0.8v4.1h-1.2v-5.8h1.3v0.8c0.5-0.6,1.2-0.9,1.9-0.9c0.7-0.1,1.4,0.4,1.6,1.1c0.5-0.7,1.2-1.1,2-1.1
				c0.8-0.1,1.6,0.5,1.7,1.4c0,0.1,0,0.3,0,0.4v4.2L59.68,298.54z"/>
			<path fill="#3E3E42" d="M62.18,295.64c0-1.7,1.4-3,3.1-2.9c1.7,0.1,3,1.4,2.9,3.1c0,1.6-1.4,2.9-3,2.9c-1.6,0.1-3-1.2-3-2.8
				C62.18,295.84,62.18,295.74,62.18,295.64z M66.88,295.64c-0.1-0.9-1-1.6-1.9-1.4c-0.9,0.1-1.6,1-1.4,1.9c0.1,0.8,0.8,1.5,1.7,1.5
				c1,0,1.7-0.8,1.7-1.8C66.88,295.74,66.88,295.64,66.88,295.64z"/>
			<path fill="#3E3E42" d="M73.38,298.54v-0.8c-0.5,0.6-1.3,0.9-2.1,0.9c-0.9,0.1-1.7-0.5-1.9-1.4c0-0.1,0-0.3,0-0.4v-4.1h1.3v3.7
				c0,0.9,0.5,1.2,1.2,1.2c0.6,0,1.1-0.3,1.5-0.8v-4.1h1.3v5.8L73.38,298.54L73.38,298.54z"/>
			<path fill="#3E3E42" d="M76.28,298.54v-5.8h1.3v0.9c0.4-0.6,1.1-1,1.9-1v1.3c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0.3-1.5,0.8v4
				L76.28,298.54z"/>
		</g>
		<g>
			<path fill="#3C3C40" d="M8.9,298.38l-0.74-1.85H4.01l-0.74,1.85H2.08l3.35-8.35h1.29l3.37,8.35H8.9z M6.09,291.11l-1.78,4.49
				h3.54L6.09,291.11z"/>
			<path fill="#3C3C40" d="M15.51,295.5c0-1.64,1.13-3.01,2.95-3.01c1.84,0,2.96,1.37,2.96,3.01c0,1.65-1.12,3.02-2.96,3.02
				C16.64,298.52,15.51,297.15,15.51,295.5z M20.12,295.5c0-1.01-0.59-1.9-1.66-1.9c-1.06,0-1.65,0.89-1.65,1.9
				c0,1.02,0.59,1.91,1.65,1.91C19.52,297.41,20.12,296.52,20.12,295.5z"/>
			<path fill="#3C3C40" d="M11.84,298.32l-2.3-5.86h1.17l1.78,4.74l1.77-4.74h1.17l-2.3,5.86H11.84z"/>
			<path fill="#3C3C40" d="M22.47,291.12c0-0.43,0.36-0.77,0.77-0.77c0.43,0,0.77,0.34,0.77,0.77c0,0.43-0.35,0.77-0.77,0.77
				C22.83,291.89,22.47,291.55,22.47,291.12z M22.62,298.33v-5.74h1.25v5.74H22.62z"/>
			<path fill="#3E3E42" d="M29.37,298.45v-0.82c-0.41,0.62-1.13,0.93-1.85,0.93c-1.44,0-2.58-1.03-2.58-2.99
				c0-1.96,1.13-3.09,2.58-3.09c0.72,0,1.44,0.31,1.85,0.93v-2.99h1.24v8.03H29.37z M29.27,296.6v-2.27
				c-0.31-0.52-0.82-0.82-1.44-0.82c-1.03,0-1.65,0.82-1.65,1.96c0,0.1,0,0.1,0,0.21c0,0.93,0.72,1.75,1.65,1.75
				C28.44,297.42,29.06,297.11,29.27,296.6z"/>
		</g>
	</g>
	<path fill="#3E3E42" d="M11.5,312.7v-8H15c1.4-0.1,2.6,1,2.7,2.4s-1,2.6-2.4,2.7c-0.1,0-0.2,0-0.3,0h-2.1v3h-1.4V312.7z
		 M16.2,307.2c0-0.7-0.6-1.3-1.3-1.3h-0.1h-1.9v2.6h1.9c0.7,0.1,1.3-0.5,1.4-1.2C16.2,307.3,16.2,307.2,16.2,307.2z"/>
	<path fill="#3E3E42" d="M18.6,305.4c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8c0,0.4-0.3,0.8-0.8,0.8l0,0
		C19,306.1,18.6,305.8,18.6,305.4z M18.8,312.7v-5.8H20v5.8H18.8z"/>
	<path fill="#3E3E42" d="M21.3,309.8c0-1.6,1.2-3,2.9-3h0.1c0.9-0.1,1.7,0.3,2.2,1l-0.8,0.8c-0.3-0.4-0.8-0.7-1.3-0.7
		c-1.1,0.1-1.9,1.1-1.7,2.1c0.1,0.9,0.8,1.6,1.7,1.7c0.5,0,1-0.2,1.3-0.7l0.8,0.8c-0.5,0.7-1.4,1.1-2.2,1c-1.6,0-3-1.2-3-2.9
		C21.3,309.9,21.3,309.8,21.3,309.8z"/>
	<path fill="#3E3E42" d="M27.2,311.9l0.6-0.9c0.5,0.5,1.3,0.8,2,0.8c0.8,0,1.1-0.3,1.1-0.8c0-1.1-3.5-0.3-3.5-2.6
		c0-1,0.8-1.8,2.3-1.8c0.8,0,1.6,0.3,2.3,0.8l-0.5,0.9c-0.5-0.5-1.1-0.7-1.7-0.7c-0.7,0-1.1,0.3-1.1,0.7c0,1,3.5,0.3,3.5,2.6
		c0,1-0.9,1.8-2.4,1.8C28.8,312.9,27.9,312.6,27.2,311.9z"/>
	<path fill="#3E3E42" d="M32.6,312.9l2.9-8.5h0.9l-2.9,8.5H32.6z"/>
	<path fill="#3E3E42" d="M37.2,312.7v-8h5.5v1.3h-4.1v2.1h4v1.2h-4v3.5L37.2,312.7z"/>
	<path fill="#3E3E42" d="M43.8,305.4c0-0.4,0.4-0.8,0.8-0.8l0,0c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8S43.8,305.8,43.8,305.4z
		 M44,312.7v-5.8h1.3v5.8H44z"/>
	<path fill="#3E3E42" d="M46.9,312.7v-8h1.3v8H46.9z"/>
	<path fill="#3E3E42" d="M50,311.3V308h-1v-1.1h1v-1.6h1.3v1.6h1.2v1.1h-1.1v3c0,0.4,0.2,0.7,0.6,0.7c0.2,0,0.4-0.1,0.6-0.2l0.3,1
		c-0.3,0.3-0.8,0.4-1.2,0.4c-0.7,0.1-1.4-0.4-1.5-1.1C50,311.6,50,311.5,50,311.3z"/>
	<path fill="#3E3E42" d="M53.3,309.8c0-1.6,1.3-3,2.9-3h0.1c1.6,0,2.9,1.3,2.9,2.9c0,0.1,0,0.2,0,0.3v0.3h-4.5
		c0.1,0.9,0.9,1.7,1.9,1.6c0.6,0,1.2-0.2,1.7-0.6l0.6,0.8c-0.7,0.6-1.5,0.9-2.4,0.9c-1.6,0.1-3-1.1-3.1-2.8
		C53.3,310,53.3,309.9,53.3,309.8z M56.3,307.8c-0.9,0-1.6,0.7-1.6,1.5l0,0H58C57.9,308.4,57.2,307.7,56.3,307.8L56.3,307.8
		L56.3,307.8z"/>
	<path fill="#3E3E42" d="M60.4,312.7v-5.8h1.3v0.9c0.5-0.6,1.1-1,1.9-1v1.3c-0.1,0-0.3,0-0.4,0c-0.6,0-1.1,0.3-1.5,0.8v4L60.4,312.7
		z"/>
	<path fill="#3E3E42" d="M64.1,311.9l0.6-0.9c0.5,0.5,1.3,0.8,2,0.8c0.8,0,1.2-0.3,1.2-0.8c0-1.1-3.5-0.3-3.5-2.6
		c0-1,0.8-1.8,2.3-1.8c0.8,0,1.6,0.3,2.3,0.8l-0.5,0.9c-0.5-0.5-1.1-0.7-1.7-0.7c-0.7,0-1.1,0.3-1.1,0.7c0,1,3.5,0.3,3.5,2.6
		c0,1-0.8,1.8-2.4,1.8C65.6,312.9,64.7,312.5,64.1,311.9z"/>
</g>
<g>
	<g>
		<path fill="#3E3E42" d="M38.29,87.13l0.8-1.1c0.7,0.7,1.6,1.1,2.6,1.1c1.2,0,1.6-0.6,1.6-1.1c0-1.7-4.7-0.6-4.7-3.6
			c0-1.3,1.2-2.4,3-2.4c1.1,0,2.2,0.4,3,1.1l-0.8,1.1c-0.6-0.6-1.5-0.9-2.3-0.9s-1.4,0.4-1.4,1c0,1.5,4.7,0.6,4.7,3.6
			c0,1.3-1,2.5-3.1,2.5C40.39,88.43,39.19,87.93,38.29,87.13z"/>
		<path fill="#3E3E42" d="M53.19,88.23v-3.8c0-0.6-0.3-1.1-1-1.1c-0.5,0-1,0.3-1.4,0.8v4.1h-1.2v-3.8c0-0.6-0.3-1.1-1-1.1
			c-0.5,0-1,0.3-1.3,0.8v4.1h-1.3v-5.9h1.3v0.8c0.5-0.6,1.2-0.9,1.9-0.9c0.7-0.1,1.4,0.4,1.6,1.1c0.5-0.7,1.2-1.1,2-1.1
			c0.9-0.1,1.6,0.5,1.7,1.4c0,0.1,0,0.3,0,0.4v4.2H53.19z"/>
		<path fill="#3E3E42" d="M55.99,80.83c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8S55.99,81.23,55.99,80.83z
			 M56.19,88.23v-5.9h1.2v5.9H56.19z"/>
		<path fill="#3E3E42" d="M59.09,88.23v-8.1h1.3v8.1H59.09z"/>
		<path fill="#3E3E42" d="M61.79,80.83c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8S61.79,81.23,61.79,80.83z
			 M61.99,88.23v-5.9h1.3v5.9H61.99z"/>
		<path fill="#3E3E42" d="M68.89,88.23v-3.7c0-0.9-0.5-1.2-1.2-1.2c-0.6,0-1.2,0.3-1.5,0.8v4.1h-1.3v-5.9h1.3v0.8
			c0.5-0.6,1.3-0.9,2.1-0.9c0.9-0.1,1.8,0.5,1.9,1.5c0,0.1,0,0.3,0,0.4v4.1C70.19,88.23,68.89,88.23,68.89,88.23z"/>
		<path fill="#3E3E42" d="M71.69,89.73l0.6-0.9c0.5,0.5,1.2,0.8,1.9,0.8c0.8,0.1,1.6-0.5,1.7-1.3c0-0.1,0-0.2,0-0.3v-0.7
			c-0.4,0.6-1.1,1-1.9,1c-1.5,0-2.6-1.1-2.6-3s1.1-3,2.6-3c0.8,0,1.5,0.4,1.9,1v-0.8h1.3v5.6c0,2.1-1.6,2.7-3,2.7
			C73.29,90.73,72.39,90.33,71.69,89.73z M75.89,86.33v-2.2c-0.4-0.5-0.9-0.8-1.5-0.8c-1,0.1-1.8,1.1-1.6,2.1
			c0.1,0.9,0.8,1.5,1.6,1.6C74.99,87.13,75.59,86.83,75.89,86.33z"/>
	</g>
	<g>
		<path fill="#3C3C40" d="M12.37,87.98l-0.75-1.87h-4.2l-0.75,1.87h-1.2l3.39-8.43h1.3l3.4,8.43H12.37z M9.52,80.64l-1.79,4.54h3.58
			L9.52,80.64z"/>
		<path fill="#3C3C40" d="M19.04,85.07c0-1.66,1.14-3.04,2.98-3.04c1.86,0,2.99,1.38,2.99,3.04c0,1.67-1.13,3.05-2.99,3.05
			C20.18,88.12,19.04,86.74,19.04,85.07z M23.69,85.07c0-1.02-0.6-1.92-1.68-1.92c-1.07,0-1.67,0.9-1.67,1.92
			c0,1.03,0.6,1.93,1.67,1.93C23.09,87,23.69,86.1,23.69,85.07z"/>
		<path fill="#3C3C40" d="M15.33,87.92l-2.32-5.92h1.18l1.79,4.79l1.79-4.79h1.18l-2.32,5.92H15.33z"/>
		<path fill="#3C3C40" d="M26.07,80.65c0-0.43,0.36-0.78,0.78-0.78c0.43,0,0.78,0.35,0.78,0.78c0,0.43-0.35,0.78-0.78,0.78
			C26.43,81.43,26.07,81.08,26.07,80.65z M26.22,87.93v-5.8h1.26v5.8H26.22z"/>
		<path fill="#3E3E42" d="M33.03,88.05v-0.83c-0.42,0.62-1.14,0.94-1.87,0.94c-1.46,0-2.6-1.04-2.6-3.02c0-1.98,1.14-3.12,2.6-3.12
			c0.73,0,1.46,0.31,1.87,0.94v-3.02h1.25v8.11H33.03z M32.93,86.18v-2.29c-0.31-0.52-0.83-0.83-1.46-0.83
			c-1.04,0-1.66,0.83-1.66,1.98c0,0.1,0,0.1,0,0.21c0,0.94,0.73,1.77,1.66,1.77C32.1,87.01,32.72,86.7,32.93,86.18z"/>
	</g>
</g>
</svg>

    </div>
}
export const Face3 = () =>{
setTimeout(() => {
    let del = 0, del_1 = 0
    window.TweenMax.set('#face3', {opacity:1})
    new window.TimelineMax()
    .set('#legMan1L, #legMan1R', {transformOrigin: '50% 5%'})
    .set('#legMan1LPred,#legMan1RPred', {transformOrigin:'50% 0%'})
  
    function man1LegMove(duration, repeat, metka){
       faceAnim3.add(
          new window.TimelineMax({repeat:repeat})
          .to('#legMan1R', 1,{scaleY:1.05,rotation:-5, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
          .to('#legMan1RPred', 1, {rotation:0,ease:window.Power0.easeNone}, 'manPrt2')
          .to('#rightHandPrt1', 1, {transformOrigin:'30% 0%', rotation:7, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
  
          .to('#legMan1R', 1,{scaleY:0.95,rotation:10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
          .to('#legMan1RPred', 1, {rotation:-10,ease:window.Power0.easeNone}, 'manPrt3')
          .to('#legMan1RPred', 1, {rotation:0,ease:window.Power0.easeNone}, 'manPrt3+=1')
          .to('#rightHandPrt1', 1, {transformOrigin:'30% 0%', rotation:-7, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
          .to('#legMan1RPred > *:nth-child(1)', 0.5, {rotation:10,repeat:1,yoyo:true,transformOrigin:"80% 0%",ease:window.Power0.easeNone}, 'manPrt3+=0.3')
  
  
          .to('#legMan1L', 1,{scaleY:0.95,rotation:10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
          .to('#legMan1LPred', 1, {rotation:-10,ease:window.Power0.easeNone}, 'manPrt2')
          .to('#legMan1LPred', 1, {rotation:0,ease:window.Power0.easeNone}, 'manPrt2+=1')
          .to('#legMan1LPred > *:nth-child(1)', 0.5, {rotation:10,repeat:1,yoyo:true,transformOrigin:"80% 0%",ease:window.Power0.easeNone}, 'manPrt2+=0.3')
          .to('#leftHandPrt1', 1, {transformOrigin:'60% 0%', rotation:-15, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
  
          .to('#legMan1L', 1,{scaleY:1.05,rotation:-10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
          .to('#legMan1LPred', 1, {rotation:0,ease:window.Power0.easeNone}, 'manPrt3')
          .to('#leftHandPrt1', 1, {transformOrigin:'60% 0%', rotation:15, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
          .totalDuration(duration)
       ,metka)
    }
  
    function man2LegMove(duration, repeat, metka){
      faceAnimMan2.add(
          new window.TimelineMax({repeat:repeat})
          .to('#legsRPrt2', 1, {rotation:-15, scaleY:0.9, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
          .to('#legsRPrt2 > *:nth-child(1)', 1, {rotation:10,ease:window.Power0.easeNone}, 'manPrt2')
          .to('#legsRPrt2 > *:nth-child(1)', 1, {rotation:0,ease:window.Power0.easeNone}, 'manPrt2+=1')
          .to('#legsRPrt2 > *:nth-child(1) > *:nth-child(1)', 0.5, {rotation:-10,repeat:1,yoyo:true,transformOrigin:"50% 10%",ease:window.Power0.easeNone}, 'manPrt2+=0.3')
  
            .to('#legsRPrt2', 1, {rotation:10, scaleY:1.1, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
  
            .to('#legsLPrt2', 1, {rotation:7, scaleY:1.1, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
            .to('#legsLPrt2', 1, {rotation:-15, scaleY:0.9, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
            .to('#legsLPrt2 > *:nth-child(1)', 1, {rotation:10,ease:window.Power0.easeNone}, 'manPrt3')
          .to('#legsLPrt2 > *:nth-child(1)', 1, {rotation:0,ease:window.Power0.easeNone}, 'manPrt3+=1')
          .to('#legsLPrt2 > *:nth-child(1) > *:nth-child(1)', 0.5, {rotation:-10,repeat:1,yoyo:true,transformOrigin:"50% 10%",ease:window.Power0.easeNone}, 'manPrt3+=0.3')
  
  
          .to('#handRightPrt2', 1, {transformOrigin:'25% 5%', rotation:10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
          .to('#handRightPrt2', 1, {transformOrigin:'25% 5%', rotation:-10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
  
            .to('#handLeftPrt2', 1, {transformOrigin:'50% 5%', rotation:-10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt2')
            .to('#handLeftPrt2', 1, {transformOrigin:'50% 5%', rotation:10, repeat:1, yoyo:true, ease:window.Power0.easeNone}, 'manPrt3')
          .totalDuration(duration)
      ,metka)
    }
  
    //part 1 (man Up)
    const faceAnim3 = new window.TimelineMax({repeat:-1, repeatDelay:0.4})
    .to('#manPrt1, #shadowPrt1', 3, {x:-54, y:-22, ease:window.Power0.easeNone}, 'mt1')
    .from('#textPrt1', 0.4, {opacity:0})
    .from('#laserPrt1', 0.2, {opacity:0})
    .to('#manPrt1', 3, {x:-60, y:-22, ease:window.Power0.easeNone}, 'mt1')
    .fromTo('#laserPrt1', 0.4, {transformOrigin:'0% 0%', rotation:-4}, {rotation:5, yoyo:true, repeat:2, ease:window.Power0.easeNone}, 'check')
     for (let i = 1; i < 13; i++) {
      faceAnim3.from(`#linesPrt1 >*:nth-child(${i})`, 0.1, {opacity:0}, `check+=${del}`)
      del+=0.05
     }  
    faceAnim3
    .to('#laserPrt1, #linesPrt1, #textPrt1', 0.1, {opacity:0})
    .to('#doors >*:nth-child(1)', 1.5, {x:50, y:-30}, 'doors')
    .to('#doors >*:nth-child(2)', 1.5, {x:-50, y:30}, 'doors')
    .to('#manPrt1, #shadowPrt1', 4, {x:-155, y:-270, ease:window.Power0.easeNone}, 'doors+=0.5')
    .to('#doors >*:nth-child(1)', 1.5, {x:0, y:0}, 'doors+=3')
    .to('#doors >*:nth-child(2)', 1.5, {x:0, y:0}, 'doors+=3')
  
    faceAnim3.timeScale(1.5)
    man1LegMove(3,1,'mt1')
    man1LegMove(4,2,'doors+=0.5')
  
    //part 2 (man Down)
    const faceAnimMan2 = new window.TimelineMax({repeat:-1, repeatDelay:0.2})
    .set('#legsRPrt2', {transformOrigin: '50% 5%'})
    .set('#legsLPrt2', {transformOrigin: '70% 5%'})
  
    .to('#manPrt2, #circlePrt2', 2, {x:52, y:-25, ease:window.Power0.easeNone}, 'manPrt2')
        .from('#laserPrt2', 0.2, {opacity:0}, 'manPrt2_c')
        .from('#textPrt2', 0.2, {opacity:0}, 'manPrt2_c')
      .to('#headPrt2', 0.5, {rotation:10, transformOrigin:'50% 100%'},'manPrt2_c')
      .fromTo('#laserPrt2', 0.3, {transformOrigin:'100% 100%', rotation:-4}, {rotation:5, yoyo:true, repeat:3, ease:window.Power0.easeNone}, 'manPrt2_c')
      for (let i = 13; i > 0; i--) {
      faceAnimMan2.from(`#linesPrt2 >*:nth-child(${i})`, 0.1, {opacity:0}, `manPrt2_c+=${del_1}`)
      del_1+=0.05
      }
        faceAnimMan2
    .to('#headPrt2', 0.5, {rotation:0, transformOrigin:'50% 100%'},'manPrt2_c+=1')
    .to('#damper', 0.8, {x:-30, y:-17}, 'manPrt2_c+=1')
    .to('#rectAprrove', 0.1, {css:{fill:'#1ee248'}}, 'manPrt2_c+=1')
  
    .to('#laserPrt2', 0.2, {opacity:0}, 'manPrt2_c+=1')
    .to('#textPrt2', 0.2, {opacity:0}, 'manPrt2_c+=1')
    .to('#linesPrt2', 0.2, {opacity:0}, 'manPrt2_c+=1')
  
    .to('#manPrt2, #circlePrt2', 1.5, {x:180, y:-150, ease:window.Power0.easeNone}, 'manPrt2_c+=1.1')
  
    .to('#damper', 1, {x:0, y:0}, 'manPrt2_m')
    .to('#rectAprrove', 0.1, {css:{fill:'#e31b25'}}, 'manPrt2_m')
  
  man2LegMove(2, 1, 'manPrt2')
  man2LegMove(1.5, 0, 'manPrt2_c+=1.1')
}, 800);
    return <div>
        <svg style={{minHeight:'450px',maxWidth:'100%',height:'450px',marginBottom:'1rem'}} version="1.1" id="face3" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 299.66 587.23" enable-background="new 0 0 299.66 587.23" xmlSpace="preserve">
<g>
	<g>
		<defs>
			<polygon id="SVGID_1_" points="81.61,564.98 -17.85,549.47 101.3,364.11 222.15,439 174.83,560.53 			"/>
		</defs>
		<clipPath id="SVGID_2_">
			<use xlinkHref="#SVGID_1_"  overflow="visible"/>
		</clipPath>
		<g opacity="0.19" clip-path="url(#SVGID_2_)">
			<g id="circlePrt2">
				<path opacity="0.4" enable-background="new    " d="M35.94,548.1c12.2,7,32,7,44.19,0s12.21-18.47,0-25.52s-32-7-44.19,0
					S23.74,541.05,35.94,548.1z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<polygon fill="#939598" points="92.34,404.41 99.09,410.47 97.68,466.28 96.06,470.01 85.17,463.72 85.17,404.99 			"/>
			<polygon fill="#BCBEC0" points="106.77,390.44 133.27,375.15 143.99,383.61 143.99,442.34 96.06,470.01 96.06,411.28 			"/>
			<path fill="#121214" d="M120.02,390.13c0.75-0.44,1.36-0.09,1.36,0.78v55.39c-0.05,0.96-0.56,1.83-1.36,2.35
				c-0.75,0.43-1.35,0.08-1.35-0.78v-55.4C118.72,391.52,119.23,390.65,120.02,390.13z"/>
			<g>
				<g>
					<defs>
						<polygon id="SVGID_3_" points="149.65,465.43 119.06,447.18 119.12,391.38 150.42,408.88 						"/>
					</defs>
					<clipPath id="SVGID_4_">
						<use xlinkHref="#SVGID_3_"  overflow="visible"/>
					</clipPath>
					<g clip-path="url(#SVGID_4_)">
						<g id="damper">
							<path opacity="0.4" fill="#D3F8FF" enable-background="new    " d="M119.06,391.87v54.79l27.58,15.91
								c0.49,0.35,1.1,0.46,1.68,0.3l0,0c0.41-0.42,0.62-1,0.58-1.59v-49c-0.1-1.91-1.11-3.65-2.71-4.7L119.06,391.87z"/>
							<path opacity="0.7" fill="#008B9D" enable-background="new    " d="M119.06,446.66v0.52l27.13,15.67
								c0.63,0.47,1.5,0.47,2.13,0c-0.58,0.16-1.19,0.05-1.68-0.3L119.06,446.66z"/>
							<path opacity="0.7" fill="#008B9D" enable-background="new    " d="M119.06,391.86l27.13,15.67c1.6,1.05,2.61,2.79,2.71,4.7
								v49.05c0.04,0.59-0.17,1.17-0.58,1.59c0.71-0.33,1.11-1.09,1-1.86v-49c-0.11-1.91-1.12-3.66-2.72-4.7l-27.48-15.93
								C119.09,391.54,119.07,391.7,119.06,391.86z"/>
						</g>
					</g>
				</g>
			</g>
		</g>
		<g>
			<polygon points="106.13,389.14 133.92,373.09 143.99,381.05 143.99,383.61 133.27,375.15 106.77,390.44 96.06,411.28 
				96.06,408.72 			"/>
			<polygon fill="#535359" points="106.13,389.14 133.92,373.09 123.03,366.81 95.23,382.85 			"/>
			<polygon fill="#303033" points="96.06,408.72 106.13,389.14 95.23,382.85 85.17,402.43 			"/>
			<polygon fill="#121214" points="85.17,404.99 96.06,411.28 96.06,408.72 85.17,402.43 			"/>
		</g>
	</g>
	<g>
		<defs>
			<polygon id="SVGID_5_" points="222.66,568.87 11.77,568.87 24.02,398.83 139.94,323 234.91,424.11 			"/>
		</defs>
		<clipPath id="SVGID_6_">
			<use xlinkHref="#SVGID_5_"  overflow="visible"/>
		</clipPath>
		<g clip-path="url(#SVGID_6_)">
			<g id="manPrt2">
				<g>
					<g id="legsPrt2">
						<g id="legsLPrt2">
							<g>
								<path fill="#842A00" d="M44.43,532.24c0,0-0.49,3.33-0.52,3.49c-0.04,0.17,1.71,2.4,3.88,2.26c2.18-0.14,2.6-1.82,2.84-2.16
									c0.24-0.33,1.66-0.55,3.29-1.71c1.32-0.95,2.65-2.73,2.78-4.07c0.03-0.3,0-0.58-0.11-0.82c-0.54-1.22-3.01-0.23-3.38-0.06
									l-1.15,1.05c-1.8,2.56-1.95-0.58-3.72-0.77C46.96,529.3,44.43,532.24,44.43,532.24z"/>
								<path fill="#173E57" d="M47.42,502.57l-0.02,0.35c0,0-2.36,4.54-2.69,10.68c-0.34,6.14-0.25,18.83-0.25,18.83
									s1.01,0.64,2.37,0.89c1.8,0.33,3.64,1.46,4.87-1.42l0.7-4.29l4.29-21.38l0.28-2.94c0.19-2.03-0.95-3.95-2.81-4.79
									c-0.03-0.01-0.06-0.03-0.09-0.04C51.03,497.1,47.58,499.25,47.42,502.57z"/>
							</g>
							<path fill="#173E57" d="M57.71,501.29c-0.15-7.42,3.85-17.49,3.8-21.57l-2.77-4.52l-4-7.42l-6.5,7.76l-0.97,6.97l0.13,19.21
								c-0.89,7.88,7.98,8.4,9.58,3.24L57.71,501.29z"/>
						</g>
						<g id="legsRPrt2">
							<g>
								<path fill="#842A00" d="M57.49,537.28c0,0-0.05,3.2-0.07,3.42s1.56,2.37,3.53,2.35s3.09-2.18,3.09-2.18s3.09,0.06,6.1-1.89
									c3.01-1.96,4.04-2.78,3.48-4.28s-8.69,0.1-8.69,0.1s-1.63-1.53-4.35-0.87C59.42,534.21,58.54,538.04,57.49,537.28z"/>
								<path fill="#2E6585" d="M58.02,507.11c-0.04,1.91-0.11,3.63-0.11,3.63c-0.67,1.18-1.26,3.45-1.43,8.66
									c-0.06,1.86,0.03,4.8,0.17,7.81c0.25,5.43,0.85,11.57,0.85,11.57c1.1,0.68,2.28,0.43,3.4,0.06
									c2.67-0.87,4.82-3.89,4.82-3.89s0.51-11.86,0.76-17.82c0.1-2.46,0.49-5.89,0.99-9.25c0.51-3.37-2.62-6.14-5.9-5.24
									c-0.04,0.01-0.07,0.02-0.11,0.03C59.47,503.21,58.06,505.03,58.02,507.11z"/>
							</g>
							<path fill="#2E6585" d="M68.33,504.88c0.48-2.29,1.02-4.84,1.6-7.84c1.76-9.16,0.59-15.91,0.59-15.91l-3.87-6.02l-3.82-2.23
								l-5.01,2.71l-2.81,1.16c0.05,4.08,2.55,16.61,2.69,24.03c0.04,2.18,0.08,4.17,0.11,5.79
								C58.19,511.49,67.06,512.03,68.33,504.88z"/>
						</g>
					</g>
					<g id="handLeftPrt2">
						<path fill="#2E6585" d="M48.31,436.05c1.38-2.88,5.62-2.85,6.82,0.11c0.48,1.19,0.6,2.67-0.11,4.45
							c-2.2,5.52-5,16.94-5.08,18.17s5.31,16.85,5.31,16.85s-4.46,3.23-5.1,2.36c-0.64-0.86-7.82-16.7-7.51-19.44
							C42.85,456.72,46.11,440.64,48.31,436.05z"/>
					</g>
					<g id="headPrt2">
						<path fill="#FFB385" d="M55.07,438.07l10.2,1.64l-1.73-5.26l-0.04-0.12l-0.47-1.67c0,0,2.81-1.43,3.16-1.96
							c0.88-1.33-2.53-11.77-5.43-12.2c-0.04,0.02-0.08,0.05-0.11,0.07c-0.07,0.1-0.25,0.39-0.57,0.76
							c-0.15,0.61,0.19,1.35,0.71,2.07c0.32,0.43,0.47,0.87,0.51,1.29c0.07,0.53-0.14,0.79-0.32,1.29
							c-0.15,0.43-0.61,1.13-0.85,1.52c-0.25,0.43-2.32,1.27-2.47,2c-0.14,0.66-3.03,5.77-3.03,5.77L55.07,438.07z"/>
						<g>
							<path fill="#FFB385" d="M55.41,437.88c1.3-2.15-0.9-5.24-0.9-5.24s-4.6-9.36-0.22-13.86c2.04-2.44,5.81-2.61,5.81-2.61
								s5.37-0.67,7.47,3.56c0.86,1.33,0.67,4.08,0.58,4.94c-0.01,0.17-0.08,0.32-0.19,0.46c-0.18,0.21-0.45,0.58-0.5,0.83
								c-0.07,0.37,0.42,1.04,0.29,1.57c-0.12,0.54-1.13,5.34-2.55,6.27c-0.6,0.46-1.07,0.1-1.56,0.09c-0.14,0-0.32,0.91-0.47,1
								C63.18,434.88,55.83,439.37,55.41,437.88z"/>
							<path fill="#956363" d="M67.1,424.26c-0.79,0.83-1.4,1.86-1.4,1.86s-0.2,1.24-0.47,1.61c-0.28,0.37-0.16,1.58-0.16,1.58
								s-0.3-2.6-0.88-2.63c-2.33,0.06-2.08,3.79-2.05,4.46c-1.2,2.73-2.38,2.03-2.84,2.15c-3.82,0.6-6.31-0.74-6.31-0.74
								s-3.79-10.46,1.1-13.79c0.63-0.68,0.45-1.99,3.9-2.92c5.03-1.36,5.88,0.96,8.07,1.25c2.21-0.13,3.25,3.03,2.18,4.29
								C68.24,421.73,67.31,424.04,67.1,424.26z"/>
							<path fill="#C08080" d="M67.36,423.59c0,0-1.36-2.29-3.89-2.09c-2.53,0.2-3.7,1.57-3.7,1.57s-1.39,1.1-2.07,2.82l0,0
								c0,0-5.05,1.97-6.07-1.98c0,0-0.23,6.19,1.24,8.63c0,0,4.1,3.5,6.54,2.04c0,0,0.46-0.32,1.12-1.02
								c0.38-0.35,0.77-0.79,1.1-1.32c0.02-0.03,0.04-0.05,0.06-0.08v-0.01c0.15-0.26,0.29-0.54,0.4-0.84l2-2.21l0.98,0.05
								l0.41,0.32c0,0,0.24,0.12,0.69-0.23c0.45-0.35-0.44-3.07-0.44-3.07s0.89-0.88,1.32-1.67
								C67.47,423.73,67.36,423.59,67.36,423.59z"/>
							<path fill="#FFB385" d="M65.03,429.02c0,0-0.65-3.31-1.49-2.2c-0.84,1.11-0.74,2.38-0.64,3.65
								C63,431.73,65.03,429.02,65.03,429.02z"/>
							<path fill="#FDC6B4" d="M67.3,423.51c-0.77-0.85-1.75-1.55-2.86-1.89c-0.55-0.18-1.14-0.21-1.71-0.14
								c-0.58,0.05-1.12,0.31-1.63,0.58h-0.01l0,0c-0.74,0.34-1.38,0.9-1.92,1.52c-0.54,0.63-0.98,1.33-1.34,2.08
								c0.34-0.76,0.77-1.47,1.3-2.12c0.54-0.63,1.16-1.21,1.92-1.59h-0.01c0.52-0.29,1.06-0.57,1.66-0.64
								c0.59-0.09,1.21-0.07,1.78,0.11c1.16,0.33,2.18,1.02,3.01,1.9c0.05,0.05,0.05,0.13,0,0.18s-0.13,0.05-0.18,0L67.3,423.51
								L67.3,423.51z"/>
							<path fill="#956363" d="M65.63,432.8c0,0-1.27,0.1-1.17,1.37c0,0,2.14,0.04,2.14-2.62L65.63,432.8z"/>
						</g>
					</g>
					<path fill="#EAE5D8" d="M55.56,433.42c0.36-0.65,0.62-0.76,0.62-0.76l-0.19,0.68c-0.08,0.61,0.43,1.7,2.25,2.26
						c3.36,0.79,6.04,0.09,6.74,0.32c0.37,0.12,0.83,1.89,0.81,2.4c0,0-2.27,2.72-7.51,1.23c-2.17-0.62-2.98-1.82-3.18-3.03
						C54.84,435.42,55.23,434.03,55.56,433.42z"/>
					<path fill="#1F4E6C" d="M46.66,440.68c0.73-5.16,3.98-8.72,9.08-7.54c0,0-2.57,2.69,2.33,4.55c2.9,1.1,7.59,0.16,7.59,0.16
						s0.95,0.56,1.83,1.14c2.03,1.35,5.92,3.6,5.34,9.02c-0.79,7.4-3.76,10.2-3.51,15.16s2.02,21.52,2.02,21.52
						s-1.45,1.83-4.16,2.95c-1.51,0.62-3.41,1.02-5.67,0.75c-6.3-0.76-6.89-1.73-6.89-1.73l-0.17-13.41l-0.76,13.16
						c0,0-6.14-4.24-6.6-5.13c-0.46-0.88,1.05-11.47,2.06-16.68C50.16,459.39,45.93,445.83,46.66,440.68z"/>
					<g id="handRightPrt2">
						<path fill="#FFB385" d="M74.38,481.57c0.05,0.02,0.08,0.03,0.12,0.06l-0.07,0.06c0,0,2.54,3.7,3.72,4.37
							c1.19,0.67,4.2,0.13,4.79-2.62c0.02-0.79-0.47-1.76-0.93-2.5c-0.34-0.54-1.02-0.8-1.61-0.55c-0.32,0.14-0.67,0.21-0.9,0.05
							c-0.57-0.39-2.36-0.9-2.36-0.9l-0.03,0.03l0,0c-0.33-0.98-1.12-3.59-1.36-4.53c-1.05-4.05-3.22-5.55-3.22-5.55l-2.43,1.62
							C69.99,474.4,72.37,480.51,74.38,481.57z"/>
						<path fill="#2E6585" d="M72.46,458.04c0.15-2.16,0.23-4.79-0.04-6.91c0.37-1.1,2.3-8.56-4.24-8.42
							c-5.88,0.34-4.69,7.13-4.69,7.13c0.18,5.07,1.59,13.53,1.95,14.86c0,0,0.08,1.28,0.5,2.65c0.58,1.89,2.63,2.9,4.49,2.21l0,0
							c1.41-0.52,2.34-1.9,2.29-3.4L72.46,458.04z"/>
						<path fill="#2E6585" d="M71.03,479.62l0.01,0.03h0.01c1.69,1.66,7.43-0.07,7.28-1c-0.03-0.16-0.26-0.24-0.62-0.27l0.39-0.07
							l-2.75-6.98l-2.61-5.95c-0.81-1.67-2.62-2.61-4.32-2.24l0,0c-2.22,0.48-3.29,2.96-2.21,5.13c0.22,0.44,0.37,0.73,0.37,0.73
							l4.33,10.4c-0.05,0.01-0.09,0.02-0.09,0.02C70.9,479.5,70.97,479.55,71.03,479.62z"/>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<polygon fill="#939598" points="161.04,444.08 167.8,450.13 166.39,505.94 164.77,509.68 153.88,503.39 153.88,444.66 			"/>
			<polygon fill="#BCBEC0" points="175.48,430.11 201.98,414.81 212.69,423.28 212.69,482.01 164.77,509.68 164.77,450.95 			"/>
		</g>
		<g>
			<polygon points="174.84,428.81 202.63,412.76 212.69,420.71 212.69,423.28 201.98,414.81 175.48,430.11 164.77,450.95 
				164.77,448.38 			"/>
			<polygon fill="#535359" points="174.84,428.81 202.63,412.76 191.74,406.47 163.94,422.52 			"/>
			<polygon fill="#303033" points="164.77,448.38 174.84,428.81 163.94,422.52 153.88,442.09 			"/>
			<polygon points="164.63,443.94 171.24,431.09 164.08,426.96 157.47,439.81 			"/>
			<polygon id="rectAprrove" fill="#E31B25" points="165.99,439.52 168.28,435.06 165.8,433.62 163.5,438.09 			"/>
			<polygon fill="#121214" points="153.88,444.66 164.77,450.95 164.77,448.38 153.88,442.09 			"/>
		</g>
	</g>
	<g>
		<g>
			<path d="M188.3,414.61l-1.13,0.29c-0.41-0.22-0.85-0.4-1.3-0.52c-3.81-0.85-7.79,0.17-10.73,2.74c-0.33,0.33-0.63,0.69-0.89,1.08
				l-1.13,0.29l0.51,2c0.48,1.32,1.62,2.28,3,2.53c3.81,0.84,7.79-0.17,10.73-2.74c1.1-0.88,1.64-2.28,1.43-3.67L188.3,414.61z"/>
			<path fill="#BCBEC0" d="M186.85,418.28c-2.94,2.56-6.92,3.57-10.73,2.72c-3.4-1-4.11-3.73-1.59-6.2
				c2.95-2.58,6.95-3.59,10.77-2.72C188.66,413,189.37,415.81,186.85,418.28z"/>
		</g>
		<g>
			<path fill="#383838" d="M186.6,414.86l-0.14-0.53h-0.08c-0.49-0.76-1.27-1.29-2.15-1.47c-2.99-0.67-6.11,0.13-8.41,2.15
				c-0.69,0.59-1.11,1.42-1.18,2.32h-0.08l0.14,0.53l0,0c0.36,1.05,1.26,1.82,2.36,2c2.98,0.67,6.11-0.13,8.41-2.14
				C186.33,417.04,186.77,415.94,186.6,414.86z"/>
			<path fill="#808080" d="M185.3,417.2c-2.3,2.02-5.43,2.82-8.42,2.15c-2.66-0.74-3.22-2.92-1.24-4.86
				c2.31-2.01,5.43-2.81,8.42-2.14C186.77,413.09,187.3,415.27,185.3,417.2z"/>
		</g>
		<path fill="#295E87" d="M185.08,417.16c-2.18,1.89-5.13,2.64-7.95,2c-2.52-0.7-3-2.76-1.17-4.58c2.18-1.9,5.13-2.64,7.95-2
			C186.42,413.27,186.95,415.33,185.08,417.16z"/>
		<path fill="#383838" d="M184.9,417.07c-2.08,1.85-4.93,2.59-7.65,2c-2.43-0.68-2.93-2.66-1.13-4.42c2.08-1.85,4.93-2.59,7.65-2
			C186.2,413.33,186.7,415.31,184.9,417.07z"/>
		<path fill="#808080" d="M184.06,416.22c-1.77,1.56-4.19,2.18-6.49,1.66c-2.06-0.58-2.48-2.26-1-3.75c1.78-1.56,4.19-2.17,6.5-1.66
			C185.16,413.05,185.59,414.73,184.06,416.22z"/>
		<g>
			<path fill="#575757" d="M183.3,414.41c0,0-2.52,2.94-6,1.53c-0.51-1.64,0.41-3.37,2.04-3.88c1.64-0.51,3.37,0.41,3.88,2.04
				C183.25,414.2,183.28,414.3,183.3,414.41z"/>
			<path fill="#575757" d="M182.77,415.86c-1.16,1.01-2.74,1.42-4.24,1.08c-1.35-0.38-1.63-1.47-0.63-2.45
				c1.16-1.01,2.74-1.42,4.25-1.08C183.49,413.78,183.77,414.88,182.77,415.86z"/>
		</g>
		<g>
			<path fill="#383838" d="M178.38,415.26c0.93-0.3,1.65-1.02,1.95-1.95c0.13-0.34,0.08-0.72-0.15-1l0,0l-0.68-0.68l-0.14,0.15
				c-0.16-0.03-0.32-0.03-0.48,0c-0.93,0.32-1.66,1.06-1.95,2c-0.05,0.19-0.05,0.39,0,0.58l-0.09,0.09l0.66,0.67
				C177.76,415.3,178.08,415.35,178.38,415.26z"/>
			<path fill="#666666" d="M177.75,414.64c-0.84,0.22-1.35-0.28-1.12-1.13c0.3-0.92,1.03-1.65,1.95-1.95
				c0.85-0.23,1.35,0.27,1.13,1.12C179.4,413.6,178.68,414.33,177.75,414.64z"/>
			<path fill="#050505" d="M178.44,411.84c-0.73,0.24-1.31,0.82-1.55,1.55c-0.12,0.45,0,0.77,0.31,0.89c-0.06-0.19-0.06-0.4,0-0.59
				c0.24-0.74,0.82-1.32,1.56-1.56c0.19-0.06,0.38-0.06,0.57,0C179.21,411.85,178.88,411.72,178.44,411.84z"/>
			<path fill="#003552" d="M178.75,412.13c-0.74,0.24-1.32,0.82-1.56,1.56c-0.06,0.19-0.06,0.4,0,0.59c0.19,0.07,0.39,0.07,0.58,0
				c0.74-0.24,1.32-0.82,1.56-1.56c0.07-0.19,0.07-0.41,0-0.6C179.14,412.06,178.94,412.06,178.75,412.13z"/>
			<g>
				<path fill="#EB5C44" d="M178.8,413.21c0.06-0.23-0.08-0.37-0.31-0.31c-0.25,0.08-0.44,0.28-0.52,0.53
					c-0.06,0.23,0.07,0.36,0.3,0.3C178.52,413.65,178.72,413.46,178.8,413.21z"/>
				<path fill="#EB5C44" d="M179.1,413.08c-0.02,0.03,0,0.08,0.03,0.09c0.01,0,0.01,0,0.02,0.01l0.13-0.26l0,0
					C179.2,412.94,179.13,413,179.1,413.08z"/>
				<path fill="#EB5C44" d="M177.59,413.49c0-0.07,0-0.12-0.09-0.1c-0.08,0.02-0.14,0.08-0.16,0.16c0,0.07,0,0.11,0.09,0.1
					C177.51,413.62,177.57,413.56,177.59,413.49z"/>
				<path fill="#EB5C44" d="M178.68,413.76l0.15-0.14c-0.03-0.01-0.06-0.01-0.09,0C178.71,413.66,178.69,413.71,178.68,413.76z"/>
				<path fill="#EB5C44" d="M178,412.71c0,0-0.11,0-0.18,0s-0.08,0.14,0,0.18c0.08,0.04,0.11,0,0.17-0.05
					C178.05,412.79,178.04,412.75,178,412.71z"/>
				<path fill="#EB5C44" d="M178.14,414c-0.08,0.02-0.14,0.08-0.16,0.16l0,0c0.09-0.04,0.18-0.08,0.26-0.13
					C178.24,414,178.2,414,178.14,414z"/>
				<path fill="#EB5C44" d="M178.71,412.37c0-0.07,0-0.11-0.09-0.09c-0.08,0.02-0.14,0.08-0.16,0.16c0,0.07,0,0.11,0.09,0.09
					C178.63,412.5,178.69,412.44,178.71,412.37z"/>
				<path fill="#EB5C44" d="M177.67,414c0,0-0.12,0-0.18,0s-0.08,0.14-0.05,0.18c0.03,0.04,0.12,0,0.18-0.05
					C177.68,414.08,177.71,414,177.67,414z"/>
				<path fill="#EB5C44" d="M179.3,412.38c0,0-0.12,0-0.18,0.05s-0.08,0.14,0,0.18c0.08,0.04,0.12,0,0.18-0.05
					S179.3,412.42,179.3,412.38z"/>
			</g>
		</g>
		
			<linearGradient id="laserPrt2_1_" gradientUnits="userSpaceOnUse" x1="124.8723" y1="782.9748" x2="180.1354" y2="782.9748" gradientTransform="matrix(1 0 0 1 0 -382.4255)">
			<stop  offset="0"  style={{"stopColor":"#E31B25","stop-opacity":0}}/>
			<stop  offset="0.1"  style={{"stopColor":"#E31B25","stop-opacity":0.03}}/>
			<stop  offset="0.23"  style={{"stopColor":"#E31B25","stop-opacity":0.12}}/>
			<stop  offset="0.4"  style={{"stopColor":"#E31B25","stop-opacity":0.26}} />
			<stop  offset="0.58"  style={{"stopColor":"#E31B25","stop-opacity":0.45}}/>
			<stop  offset="0.79" style={{"stopColor":"#E31B25","stop-opacity":0.7}} />
			<stop  offset="1"style={{"stopColor":"#E31B25"}} />
		</linearGradient>
		<path id="laserPrt2" fill="url(#laserPrt2_1_)" d="M179.07,411.37L124.87,386v28.4l53.78,0.69c0.7-0.21,1.22-0.79,1.37-1.5
			c0.04-0.12,0.07-0.24,0.09-0.36C180.24,412.44,179.81,411.67,179.07,411.37z"/>
		<path opacity="0.6" fill="#517FA2" enable-background="new    " d="M186.14,414.44c-0.79-3.1-3.95-4.98-7.05-4.18
			c-3.1,0.79-4.98,3.95-4.18,7.05l0,0c0.35,0.98,1.2,1.7,2.23,1.87c2.82,0.64,5.77-0.11,7.95-2c0.82-0.65,1.22-1.69,1.06-2.72
			L186.14,414.44z"/>
		<path opacity="0.3" fill="#FFFFFF" enable-background="new    " d="M183.53,413.12c-0.71-0.11-1.32-0.55-1.64-1.19
			c-0.27-0.59,0-1,0.68-1c0.71,0.11,1.32,0.55,1.65,1.19C184.48,412.76,184.18,413.19,183.53,413.12z"/>
	</g>
	<g>
		<g id="linesPrt2">
			<path fill="#FF1D25" d="M177.87,413.39h-0.05l-1-0.21c-0.13-0.03-0.21-0.16-0.19-0.29c0.03-0.14,0.15-0.22,0.29-0.2l1,0.2
				c0.13,0.03,0.22,0.16,0.2,0.3C178.09,413.3,177.99,413.39,177.87,413.39z"/>
			<path fill="#FF1D25" d="M174.89,412.77L174.89,412.77l-2-0.41c-0.14-0.03-0.22-0.16-0.2-0.29c0.03-0.14,0.16-0.22,0.3-0.2l2,0.41
				c0.13,0.03,0.22,0.16,0.19,0.3l0,0c-0.02,0.13-0.15,0.22-0.28,0.19C174.9,412.77,174.89,412.77,174.89,412.77z"/>
			<path fill="#FF1D25" d="M170.89,411.93L170.89,411.93l-2-0.42c-0.13-0.03-0.21-0.16-0.19-0.29c0.03-0.14,0.16-0.22,0.3-0.2
				l2,0.42c0.13,0.03,0.22,0.16,0.19,0.3l0,0C171.16,411.87,171.03,411.96,170.89,411.93L170.89,411.93L170.89,411.93z"/>
			<path fill="#FF1D25" d="M166.89,411.1h-0.05l-2-0.42c-0.13-0.03-0.22-0.16-0.19-0.3l0,0c0.02-0.13,0.15-0.22,0.28-0.19h0.01
				l2,0.42c0.13,0.02,0.22,0.14,0.2,0.27c0,0.01,0,0.01,0,0.02c-0.03,0.12-0.14,0.2-0.26,0.2H166.89z"/>
			<path fill="#FF1D25" d="M162.89,410.27h-0.05l-2-0.41c-0.14-0.03-0.22-0.16-0.2-0.3c0.03-0.14,0.16-0.22,0.3-0.2l2,0.42
				c0.13,0.03,0.22,0.16,0.19,0.3l0,0C163.11,410.2,163,410.28,162.89,410.27L162.89,410.27z"/>
			<path fill="#FF1D25" d="M158.89,409.43h-0.05l-2-0.42c-0.13-0.03-0.21-0.16-0.19-0.29c0.03-0.14,0.15-0.22,0.29-0.2l2,0.42
				c0.13,0.03,0.21,0.16,0.19,0.29c-0.03,0.12-0.14,0.2-0.26,0.2H158.89z"/>
			<path fill="#FF1D25" d="M154.89,408.6h-0.05l-2-0.41c-0.14-0.03-0.22-0.16-0.2-0.3s0.16-0.22,0.3-0.2l2,0.41
				c0.14,0.03,0.22,0.16,0.2,0.3c-0.03,0.12-0.15,0.2-0.27,0.19L154.89,408.6z"/>
			<path fill="#FF1D25" d="M150.89,407.76h-0.05l-2-0.42c-0.14-0.03-0.22-0.15-0.2-0.29c0.03-0.13,0.16-0.22,0.3-0.2l2,0.42
				c0.13,0.03,0.22,0.16,0.19,0.3l0,0c-0.03,0.12-0.14,0.2-0.26,0.19L150.89,407.76z"/>
			<path fill="#FF1D25" d="M146.89,406.93h-0.05l-2-0.42c-0.13-0.03-0.22-0.16-0.19-0.3l0,0c0.03-0.13,0.16-0.21,0.29-0.19l2,0.41
				c0.14,0.01,0.24,0.14,0.23,0.27S147.03,406.94,146.89,406.93L146.89,406.93z"/>
			<path fill="#FF1D25" d="M142.89,406.1h-0.05l-2-0.42c-0.14-0.03-0.22-0.15-0.2-0.29c0.03-0.13,0.16-0.22,0.3-0.2l2,0.42
				c0.14,0.03,0.22,0.16,0.2,0.3c-0.04,0.12-0.15,0.2-0.28,0.19L142.89,406.1z"/>
			<path fill="#FF1D25" d="M138.89,405.26h-0.05l-2-0.42c-0.13-0.03-0.22-0.16-0.19-0.3l0,0c0.03-0.13,0.17-0.22,0.3-0.19l2,0.42
				c0.13,0.03,0.21,0.16,0.19,0.29c-0.03,0.13-0.15,0.22-0.28,0.2L138.89,405.26z"/>
			<path fill="#FF1D25" d="M134.89,404.43h-0.05l-2-0.41c-0.14-0.03-0.22-0.16-0.2-0.3c0.03-0.14,0.16-0.22,0.3-0.19l0,0l2,0.41
				c0.14,0.02,0.23,0.15,0.2,0.29v0.01c-0.03,0.13-0.16,0.21-0.29,0.19L134.89,404.43L134.89,404.43z"/>
			<path fill="#FF1D25" d="M130.85,403.59h-0.05l-1-0.2c-0.13-0.03-0.22-0.16-0.19-0.3l0,0c0.03-0.14,0.15-0.22,0.29-0.2l1,0.21
				c0.13,0.02,0.22,0.15,0.19,0.28v0.01C131.07,403.5,130.97,403.59,130.85,403.59z"/>
		</g>
	</g>
	<g id="textPrt2">
		<path d="M124.87,358.22v-9.45l1.21,0.74v8l3,1.85v1.46L124.87,358.22z"/>
		<path d="M129.86,357.84c0-2,1-3,2.56-2c1.64,1.2,2.6,3.12,2.57,5.16c0,2-1,3-2.57,2C130.78,361.79,129.83,359.87,129.86,357.84z
			 M133.86,360.29c0.05-1.22-0.49-2.4-1.45-3.15c-0.92-0.57-1.43,0.18-1.43,1.39c-0.06,1.22,0.47,2.39,1.43,3.15
			c0.94,0.57,1.46-0.17,1.46-1.39H133.86z"/>
		<path d="M135.77,361.45c0-2,1-3,2.56-2c1.64,1.2,2.6,3.12,2.57,5.15c0,2-1,3-2.57,2C136.7,365.39,135.74,363.48,135.77,361.45z
			 M139.77,363.9c0.05-1.23-0.5-2.4-1.47-3.15c-0.92-0.56-1.43,0.18-1.43,1.39c-0.06,1.22,0.47,2.39,1.43,3.15
			C139.3,365.86,139.78,365.11,139.77,363.9L139.77,363.9z"/>
		<path d="M145.3,370.69l-1.53-3.75l-0.69,0.56v1.84l-1.09-0.66v-9.46l1.09,0.67v5.91l2.2-2l1.34,0.82l-2.08,1.83l2.12,5
			L145.3,370.69z"/>
		<path d="M149.93,364.92c0-0.51,0.31-0.73,0.67-0.51c0.42,0.32,0.66,0.81,0.67,1.33c0,0.51-0.3,0.74-0.67,0.51
			C150.19,365.93,149.94,365.44,149.93,364.92z M150.07,373.61v-6.85l1.08,0.67v6.84L150.07,373.61z"/>
		<path d="M155.92,377.18v-4.28c0.07-0.8-0.32-1.57-1-2c-0.4-0.3-0.96-0.24-1.28,0.14v4.78l-1.09-0.66v-6.88l1.09,0.66v0.94
			c0.51-0.43,1.24-0.43,1.75,0c1.06,0.67,1.68,1.87,1.61,3.12v4.81L155.92,377.18z"/>
		<path d="M158.62,377.24v-3.95l-0.83-0.5v-1.31l0.83,0.51v-1.87l1.08,0.66v1.87l1,0.62v1.3l-1-0.62v3.59
			c-0.03,0.42,0.15,0.83,0.48,1.09c0.2,0.13,0.4,0.14,0.48,0.06l0.26,1.28c-0.2,0.13-0.52,0.12-1-0.19
			C159.06,379.23,158.56,378.26,158.62,377.24z"/>
		<path d="M161.38,377.09c0-2,1-3,2.56-2c1.64,1.2,2.6,3.12,2.57,5.15c0,2-1,3-2.57,2C162.31,381.03,161.35,379.12,161.38,377.09z
			 M165.38,379.53c0.05-1.22-0.49-2.39-1.45-3.15c-0.92-0.56-1.43,0.19-1.43,1.39c-0.06,1.22,0.47,2.4,1.43,3.16
			c0.94,0.57,1.46-0.18,1.46-1.4L165.38,379.53L165.38,379.53z"/>
		<path d="M170.47,384.48v-4l-0.83-0.51v-1.3l0.83,0.5v-1.82l1.08,0.66v1.87l1,0.62v1.31l-1-0.62v3.58
			c-0.03,0.42,0.15,0.84,0.48,1.1c0.13,0.11,0.32,0.14,0.48,0.06l0.26,1.27c-0.2,0.14-0.52,0.13-1-0.18
			C170.91,386.47,170.41,385.5,170.47,384.48z"/>
		<path d="M176.91,390v-4.3c0.08-0.8-0.31-1.58-1-2c-0.4-0.27-0.94-0.21-1.28,0.14v4.77l-1.08-0.66v-9.46l1.08,0.67v3.54
			c0.51-0.43,1.26-0.43,1.77,0c1.07,0.68,1.69,1.88,1.61,3.15v4.85L176.91,390z"/>
		<path d="M179.09,387.9c0-2,1-2.94,2.53-2c1.62,1.24,2.53,3.19,2.45,5.23v0.35l-3.85-2.35c0.07,1.14,0.65,2.2,1.59,2.86
			c0.41,0.31,0.97,0.35,1.42,0.11l0.49,1.28c-0.66,0.32-1.44,0.22-2-0.24C180.03,391.93,179.05,389.97,179.09,387.9z M181.62,387.08
			c-0.93-0.57-1.36,0.15-1.41,1l2.81,1.71C183.02,388.71,182.5,387.7,181.62,387.08z"/>
		<path d="M187.48,393c0-2.07,1.07-2.93,2.58-2c0.88,0.57,1.55,1.41,1.91,2.39l-0.71,0.47c-0.21-0.61-0.61-1.14-1.14-1.51
			c-0.91-0.55-1.51,0-1.51,1.35c-0.05,1.25,0.51,2.45,1.51,3.2c0.48,0.3,0.86,0.25,1.14-0.11l0.71,1.34
			c-0.35,0.44-0.94,0.65-1.91,0.06C188.41,396.98,187.45,395.04,187.48,393z"/>
		<path d="M195.96,401.64v-0.74c-0.37,0.36-1,0.33-1.61-0.07c-1.08-0.77-1.72-2.01-1.73-3.33c0-1.57,0.92-1.66,1.73-1.17
			c0.71,0.45,1.27,1.1,1.61,1.87v-1c-0.02-0.82-0.46-1.58-1.16-2c-0.44-0.33-1.04-0.33-1.48,0l-0.44-1.31
			c0.67-0.38,1.5-0.3,2.09,0.18c1.32,0.78,2.11,2.22,2.08,3.75v4.53L195.96,401.64z M195.96,400.08v-1
			c-0.23-0.59-0.65-1.09-1.18-1.44c-0.61-0.37-1.07-0.16-1.07,0.59c0.03,0.76,0.43,1.45,1.07,1.86
			C195.3,400.35,195.72,400.4,195.96,400.08z"/>
		<path d="M204.56,406.89v-4.47c0.05-0.68-0.26-1.34-0.82-1.73c-0.38-0.26-0.89-0.17-1.16,0.2v4.79L201.5,405v-4.46
			c0.06-0.68-0.25-1.35-0.82-1.74c-0.38-0.26-0.89-0.16-1.15,0.22v4.78l-1.08-0.67v-6.84l1.08,0.66v0.94
			c0.43-0.45,1.13-0.5,1.62-0.12c0.73,0.48,1.22,1.24,1.36,2.1c0.39-0.53,1.13-0.65,1.66-0.26c0.01,0.01,0.02,0.02,0.04,0.03
			c0.97,0.65,1.52,1.77,1.43,2.94v5L204.56,406.89z"/>
		<path d="M206.73,404.79c0-2,1.05-3,2.53-2.05c1.62,1.24,2.53,3.19,2.45,5.23v0.36l-3.85-2.33c0.07,1.15,0.65,2.2,1.59,2.86
			c0.41,0.31,0.97,0.36,1.42,0.11l0.49,1.28c-0.66,0.32-1.44,0.23-2-0.24C207.68,408.81,206.69,406.85,206.73,404.79z M209.3,404
			c-0.93-0.56-1.35,0.15-1.41,1l2.81,1.71C210.7,405.63,210.18,404.62,209.3,404z"/>
		<path d="M212.78,411.91v-6.84l1.09,0.66v1c0.41-0.47,1.12-0.55,1.62-0.18V408c-0.11-0.1-0.22-0.18-0.35-0.25
			c-0.4-0.27-0.93-0.22-1.27,0.12v4.66L212.78,411.91z"/>
		<path d="M219.41,416v-0.74c-0.38,0.35-1,0.33-1.62-0.08c-1.08-0.76-1.72-2-1.73-3.32c0-1.58,0.92-1.67,1.73-1.17
			c0.71,0.45,1.28,1.09,1.62,1.86v-1c-0.03-0.82-0.47-1.57-1.17-2c-0.44-0.32-1.04-0.32-1.48,0l-0.44-1.31
			c0.67-0.38,1.5-0.31,2.09,0.18c1.32,0.78,2.11,2.21,2.08,3.74v4.54L219.41,416z M219.41,414.44v-1c-0.24-0.59-0.66-1.08-1.19-1.43
			c-0.61-0.37-1.07-0.17-1.07,0.58c0.02,0.76,0.43,1.46,1.07,1.87c0.47,0.21,0.94,0.25,1.19-0.06V414.44z"/>
	</g>
</g>
<g>
	<polygon fill="#7F8183" points="68.46,227.71 70.86,228.91 70.86,224.12 	"/>
	<g>
		<path fill="#785146" d="M152.34,151.5l0.71-0.41c0.05,0-0.66,0.44-0.66,0.44L152.34,151.5z"/>
	</g>
	<g>
		<path fill="#FFFFFF" d="M98.04,141.93c-0.04,0.01-0.07,0.01-0.11,0l-0.9-0.44c-0.12-0.07-0.17-0.22-0.11-0.34
			c0.07-0.12,0.22-0.17,0.34-0.11l0.89,0.45c0.12,0.05,0.17,0.18,0.12,0.3c0,0.01-0.01,0.02-0.01,0.03
			C98.21,141.89,98.12,141.93,98.04,141.93z"/>
		<path fill="#FFFFFF" d="M154.37,170.06h-0.12l-1.76-0.88c-0.12-0.06-0.17-0.22-0.11-0.34s0.22-0.17,0.34-0.11l0,0l1.76,0.88
			c0.12,0.07,0.17,0.22,0.11,0.34C154.54,170.02,154.45,170.06,154.37,170.06z M150.85,168.3c-0.04,0.01-0.08,0.01-0.12,0l-1.78-0.9
			c-0.12-0.06-0.17-0.22-0.11-0.34s0.22-0.17,0.34-0.11l1.76,0.88c0.12,0.07,0.17,0.22,0.11,0.34
			C151.01,168.24,150.93,168.29,150.85,168.3z M147.33,166.55c-0.04,0.01-0.08,0.01-0.12,0l-1.76-0.88
			c-0.12-0.06-0.17-0.21-0.11-0.33s0.21-0.18,0.34-0.12l1.76,0.88c0.12,0.07,0.17,0.22,0.11,0.34
			C147.5,166.51,147.41,166.55,147.33,166.55z M143.81,164.79c-0.04,0.01-0.08,0.01-0.12,0l-1.74-0.91
			c-0.12-0.06-0.17-0.21-0.11-0.33c0.05-0.12,0.2-0.18,0.32-0.12c0.01,0,0.02,0.01,0.02,0.01l1.76,0.87
			c0.12,0.07,0.17,0.22,0.11,0.34C144,164.74,143.91,164.79,143.81,164.79z M140.28,163.03h-0.11l-1.76-0.88
			c-0.12-0.06-0.17-0.21-0.11-0.33c0.07-0.12,0.22-0.17,0.34-0.11l1.76,0.88c0.12,0.06,0.17,0.21,0.11,0.33
			C140.46,162.99,140.37,163.04,140.28,163.03z M136.76,161.27h-0.11l-1.76-0.88c-0.12-0.07-0.17-0.22-0.11-0.34
			c0.07-0.12,0.22-0.17,0.34-0.11l1.76,0.88c0.12,0.06,0.17,0.21,0.11,0.33C136.94,161.23,136.85,161.27,136.76,161.27
			L136.76,161.27z M133.24,159.5h-0.11l-1.76-0.88c-0.12-0.06-0.17-0.22-0.11-0.34s0.22-0.17,0.34-0.11l1.76,0.88
			c0.12,0.06,0.17,0.21,0.11,0.33C133.42,159.46,133.33,159.5,133.24,159.5z M129.72,157.74c-0.04,0.01-0.07,0.01-0.11,0l-1.76-0.88
			c-0.12-0.06-0.17-0.22-0.11-0.34s0.22-0.17,0.34-0.11l1.76,0.88c0.12,0.07,0.17,0.22,0.11,0.34
			C129.9,157.71,129.81,157.75,129.72,157.74L129.72,157.74z M126.2,155.99h-0.11l-1.76-0.88c-0.12-0.06-0.17-0.21-0.11-0.33
			c0.06-0.13,0.21-0.18,0.33-0.12h0.01l1.76,0.88c0.12,0.07,0.17,0.22,0.11,0.34C126.38,155.95,126.29,156,126.2,155.99
			L126.2,155.99z M122.68,154.23h-0.11l-1.76-0.88c-0.12-0.06-0.17-0.21-0.11-0.33c0.05-0.12,0.18-0.17,0.3-0.12
			c0.01,0,0.02,0.01,0.03,0.01l1.76,0.87c0.12,0.06,0.18,0.21,0.12,0.34C122.86,154.2,122.77,154.24,122.68,154.23L122.68,154.23z
			 M119.16,152.5c-0.04,0.01-0.07,0.01-0.11,0l-1.76-0.88c-0.12-0.06-0.17-0.21-0.11-0.33s0.2-0.17,0.32-0.12c0,0,0.01,0,0.01,0.01
			l1.76,0.88c0.12,0.05,0.18,0.19,0.13,0.31c0,0.01,0,0.01-0.01,0.02C119.33,152.46,119.25,152.5,119.16,152.5z M115.64,150.74
			c-0.04,0.01-0.07,0.01-0.11,0l-1.76-0.87c-0.12-0.07-0.17-0.22-0.11-0.34s0.21-0.17,0.33-0.11l1.76,0.88
			c0.12,0.05,0.18,0.19,0.13,0.31c0,0.01,0,0.01-0.01,0.02c-0.06,0.07-0.14,0.1-0.23,0.09
			C115.64,150.72,115.64,150.74,115.64,150.74z M112.12,148.98h-0.11l-1.76-0.88c-0.12-0.07-0.17-0.22-0.11-0.34
			s0.21-0.17,0.33-0.11l1.76,0.88c0.12,0.05,0.18,0.19,0.13,0.31c0,0.01,0,0.01-0.01,0.02c-0.06,0.07-0.14,0.11-0.23,0.1
			C112.12,148.96,112.12,148.98,112.12,148.98z M108.6,147.22c-0.04,0.01-0.07,0.01-0.11,0l-1.76-0.88
			c-0.12-0.07-0.17-0.22-0.11-0.34s0.21-0.17,0.33-0.11l1.76,0.88c0.13,0.06,0.18,0.21,0.12,0.33v0.01
			c-0.06,0.06-0.14,0.1-0.23,0.09V147.22z M105.08,145.5c-0.04,0.01-0.07,0.01-0.11,0l-1.76-1c-0.12-0.06-0.17-0.21-0.11-0.33
			c0.05-0.12,0.19-0.18,0.31-0.13c0.01,0,0.01,0,0.02,0.01l1.76,0.88c0.13,0.06,0.18,0.21,0.12,0.33v0.01
			C105.28,145.38,105.19,145.48,105.08,145.5z M101.56,143.74c-0.04,0.01-0.07,0.01-0.11,0l-1.76-0.88
			c-0.12-0.06-0.17-0.21-0.11-0.33c0.05-0.12,0.19-0.18,0.31-0.13c0.01,0,0.01,0,0.02,0.01l1.76,0.88c0.12,0.05,0.18,0.2,0.12,0.32
			c0,0.01-0.01,0.02-0.01,0.02c-0.06,0.06-0.14,0.08-0.22,0.06C101.56,143.69,101.56,143.74,101.56,143.74z"/>
		<path fill="#FFFFFF" d="M157.02,171.39c-0.04,0.01-0.07,0.01-0.11,0l-0.9-0.44c-0.12-0.06-0.17-0.22-0.11-0.34
			s0.22-0.17,0.34-0.11l0,0l0.89,0.45c0.12,0.05,0.17,0.18,0.12,0.3c0,0.01-0.01,0.02-0.01,0.03
			C157.19,171.35,157.1,171.39,157.02,171.39z"/>
	</g>
	<g>
		<path opacity="0.15" enable-background="new    " d="M140.12,165.11c0,0-25-4.48-27.64-9.27c-2.64-4.79,18.17-10.93,18.17-10.93
			s5.78-9.84,13.37-7.24c7.59,2.6,23.51,11.94,22.68,12.79C165.87,151.31,140.12,165.11,140.12,165.11z"/>
		<g>
			
				<rect x="158.94" y="95.74" transform="matrix(0.4742 -0.8804 0.8804 0.4742 -13.039 198.733)" fill="#53595A" width="1.85" height="29.09"/>
			
				<rect x="158.56" y="124.9" transform="matrix(0.4741 -0.8805 0.8805 0.4741 -38.8982 213.7605)" fill="#53595A" width="1.85" height="29.09"/>
			
				<rect x="138.37" y="137.28" transform="matrix(0.4742 -0.8804 0.8804 0.4742 -60.4264 202.4651)" fill="#53595A" width="1.85" height="29.09"/>
			<path fill="#53595A" d="M127.59,146.22c-0.54,0-1.07-0.13-1.54-0.39c-1-0.54-1.64-1.57-1.68-2.71l-0.84-23.66
				c-0.12-2.85,1.4-5.53,3.91-6.89l22.09-12.06l1.1,32.48l-21.4,12.77C128.73,146.06,128.17,146.22,127.59,146.22z M147.79,103.58
				l-19.46,10.66c-1.9,1.03-3.05,3.06-2.95,5.22l0.84,23.62c0.03,0.75,0.65,1.34,1.4,1.31c0.23-0.01,0.46-0.07,0.66-0.19
				l20.47-12.24L147.79,103.58z"/>
			<g>
				<path fill="#A1712F" d="M171.53,99.97c-5.35-4.84-7.23-2.14-12-4.71c-5.9-3.19-2.2-2.89-7.66-5.31c-3.61-1.6-7.17,3.24-7,5.62
					l-2.48,20c-0.16,1.97-1.28,3.74-3,4.72l-13.59,7.42c-2.07,1.19-3.63,5.59-1.56,6.79c0,0,7.55,5.2,9.48,6.21
					c2.31,1.21,9.69,3.94,9.69,3.94c3.94,1.45,7-2,9-3.15l12.82-7.34c1.93-1.12,3.17-3.13,3.3-5.35l2.38-21.27
					C171.69,102.63,174.05,102.27,171.53,99.97z"/>
				
					<linearGradient id="SVGID_7_" gradientUnits="userSpaceOnUse" x1="405.1358" y1="475.4268" x2="379.6958" y2="527.0869" gradientTransform="matrix(1 0 0 1 -242.8 -382.4255)">
					<stop  offset="0" style={{stopColor:"#EBCC60"}}/>
					<stop  offset="0.52" style={{stopColor:"#DBB244"}}/>
					<stop  offset="0.59" style={{stopColor:"#E1BB48"}} />
					<stop  offset="0.64" style={{stopColor:"#E9C74C"}} />
					<stop  offset="0.76" style={{stopColor:"#E9C448"}} />
					<stop  offset="0.84" style={{stopColor:"#F2D261"}} />
					<stop  offset="0.93" style={{stopColor:"#E9C448"}} />
				</linearGradient>
				<path fill="url(#SVGID_7_)" d="M169.53,99.24c-5.34-4.84-7.22-2.14-12-4.71c-5.9-3.19-2.2-2.89-7.66-5.31
					c-3.61-1.6-7.17,3.24-7,5.62L140.39,114c-0.15,1.97-1.28,3.74-3,4.71l-12.16,6.9c-2.07,1.2-3.07,6.12-1,7.31
					c0,0,7,4.69,8.92,5.69c2.31,1.22,9.68,3.94,9.68,3.94c3.95,1.46,5.55-1.47,7.52-2.59l12.82-7.33c1.95-1.11,3.22-3.12,3.36-5.36
					l2.38-20.45C169.67,101.91,172.03,101.54,169.53,99.24z"/>
			</g>
			<path fill="#53595A" d="M152.97,159.67c-0.54,0-1.07-0.14-1.54-0.4c-1-0.54-1.63-1.57-1.67-2.71l-0.84-23.62
				c-0.12-2.85,1.4-5.52,3.91-6.88l17.51-9.6c1.55-0.86,3.5-0.29,4.36,1.26c0.24,0.44,0.38,0.94,0.4,1.44l-1.14,24
				c0.08,2.72-1.33,5.27-3.67,6.65l-15.67,9.44C154.11,159.53,153.54,159.68,152.97,159.67z M171.89,117.92
				c-0.23,0-0.46,0.06-0.66,0.17l-17.51,9.59c-1.89,1.03-3.04,3.04-2.95,5.19l0.84,23.59c0.03,0.76,0.68,1.34,1.43,1.31
				c0.22-0.01,0.44-0.07,0.63-0.19l15.67-9.44c1.78-1.06,2.84-3.01,2.77-5.08l1.14-23.9c-0.04-0.45-0.31-0.85-0.72-1.06
				C172.33,117.99,172.11,117.92,171.89,117.92z"/>
		</g>
	</g>
	<g>
		<defs>
			<polygon id="SVGID_8_" points="315.34,373.04 59.51,373.04 105.44,254.64 106.74,123.77 182.66,87.68 			"/>
		</defs>
		<clipPath id="SVGID_9_">
			<use xlinkHref="#SVGID_8_"  overflow="visible"/>
		</clipPath>
		<g opacity="0.19" clip-path="url(#SVGID_9_)">
			<g id="shadowPrt1">
				<path opacity="0.4" enable-background="new    " d="M201.38,326.95c12.2,7,32,7,44.19,0c12.19-7,12.21-18.47,0-25.52
					c-12.21-7.05-32-7-44.19,0S189.18,319.9,201.38,326.95z"/>
			</g>
		</g>
	</g>
	<polygon fill="#E7E2E2" points="63.11,152.63 75.79,164.14 89.24,158.33 76.93,147.23 	"/>
	<g>
		<polygon fill="#696A6C" points="105.3,106.39 170.14,68.67 194.52,54.6 194.52,44.5 64.15,119.04 64.15,265.03 105.3,242.04 		"/>
		<polygon fill="#A3A3A3" points="200.52,192.64 200.43,47.85 69.73,122.39 69.73,268.37 105.3,248.5 105.3,201.89 105.3,188.26 
			105.3,106.39 183.77,61.06 183.77,62.53 183.77,203.83 		"/>
		<polygon fill="#696A6C" points="64.52,264.64 69.52,268.64 69.52,259.39 		"/>
		<polygon fill="#696A6C" points="194.52,44.64 200.52,47.64 193.08,52.04 		"/>
		<g>
			<g>
				<path fill="#909090" d="M105.44,108.3v146.34l1.86-1.09V109.39l74.47-43.33l1.19-0.68V209.5l1.88-1.08V62.14L105.44,108.3z"/>
				<polygon fill="#767676" points="103.04,106.93 103.04,253.25 105.43,254.63 105.43,108.31 				"/>
				<polygon fill="#767676" points="180.57,66.75 180.57,208.16 182.96,209.54 182.96,65.37 				"/>
				<polygon fill="#B0B0B0" points="182.44,60.75 103.04,106.93 105.43,108.31 184.83,62.13 				"/>
			</g>
			<g>
				<g>
					<defs>
						<polygon id="SVGID_10_" points="181.77,208.83 107.3,252.15 107.3,109.39 181.77,66.06 						"/>
					</defs>
					<clipPath id="SVGID_11_">
						<use xlinkHref="#SVGID_10_"  overflow="visible"/>
					</clipPath>
					<g id="doors" clip-path="url(#SVGID_11_)">
						<g>
							<polygon fill="#C9D0D6" points="145.31,87.12 146.17,86.6 146.17,229.37 145.32,229.88 							"/>
							<g>
								<g>
									<polygon fill="#F3FFFF" points="181.77,196.83 146.18,217.56 146.17,86.6 181.77,66.06 									"/>
									<polygon fill="#C6C5B8" points="181.77,208.83 146.17,229.37 146.18,217.56 181.77,196.83 									"/>
								</g>
								<g>
									
										<linearGradient id="SVGID_12_" gradientUnits="userSpaceOnUse" x1="-1559.7744" y1="558.8898" x2="-1558.6045" y2="560.9198" gradientTransform="matrix(-1 0 0 1 -1408.5308 -382.4255)">
										<stop  offset="0.16" style={{stopColor:"#B7B7B7"}} />
										<stop  offset="0.32" style={{stopColor:"#DDDDDD"}} />
										<stop  offset="0.54" style={{stopColor:"#B9B9B9"}} />
										<stop  offset="0.73" style={{stopColor:"#ADADAD"}} />
									</linearGradient>
									<path fill="url(#SVGID_12_)" d="M148.63,177.03c-0.03,0.2,0.05,0.39,0.21,0.51l3,2V177l-1.81-1.2l0,0l0,0
										c-0.05-0.01-0.1-0.01-0.15,0c-0.11,0.01-0.21,0.04-0.31,0.09C149.09,176.1,148.75,176.52,148.63,177.03z"/>
									
										<linearGradient id="SVGID_13_" gradientUnits="userSpaceOnUse" x1="-1559.7744" y1="545.1398" x2="-1558.6044" y2="547.1698" gradientTransform="matrix(-1 0 0 1 -1408.5308 -382.4255)">
										<stop  offset="0.16" style={{stopColor:"#B7B7B7"}} />
										<stop  offset="0.32" style={{stopColor:"#DDDDDD"}}/>
										<stop  offset="0.54" style={{stopColor:"#B9B9B9"}} />
										<stop  offset="0.73"  style={{stopColor:"#ADADAD"}}/>
									</linearGradient>
									<path fill="url(#SVGID_13_)" d="M148.63,163.29c-0.03,0.19,0.05,0.39,0.21,0.5l3,2v-2.55l-1.81-1.19l0,0l0,0
										c-0.05-0.01-0.1-0.01-0.15,0c-0.11,0-0.22,0.04-0.31,0.09C149.09,162.35,148.74,162.78,148.63,163.29z"/>
									<g>
										
											<linearGradient id="SVGID_14_" gradientUnits="userSpaceOnUse" x1="-688.4747" y1="552.6375" x2="-686.1647" y2="552.6375" gradientTransform="matrix(1 0 0 1 839.15 -382.4255)">
											<stop  offset="0.27" style={{stopColor:"#ADADAD"}} />
											<stop  offset="0.46"  style={{stopColor:"#B9B9B9"}}/>
											<stop  offset="0.68"style={{stopColor:"#DDDDDD"}}  />
											<stop  offset="0.84" style={{stopColor:"#B7B7B7"}}  />
										</linearGradient>
										<path fill="url(#SVGID_14_)" d="M152.95,180.77v-21.81l-2.3,0.11v21.79l0,0c0.04,0.18,0.16,0.32,0.32,0.4
											c0.51,0.26,1.12,0.26,1.63,0C152.8,181.17,152.93,180.98,152.95,180.77L152.95,180.77z"/>
										<path fill="#DADADA" d="M152.65,158.5c-0.51-0.26-1.12-0.26-1.63,0c-0.26,0.09-0.39,0.38-0.3,0.64
											c0.05,0.14,0.16,0.25,0.3,0.3c0.52,0.26,1.12,0.26,1.64,0c0.26-0.1,0.39-0.39,0.29-0.64
											C152.9,158.66,152.79,158.55,152.65,158.5z"/>
									</g>
								</g>
							</g>
						</g>
						<g>
							<polygon fill="#C9D0D6" points="144.48,87.58 145.31,87.12 145.32,229.88 144.45,230.48 							"/>
							<g>
								<g>
									<polygon fill="#F3FFFF" points="144.73,218.16 107.3,240.16 107.3,109.39 144.48,87.58 									"/>
									<polygon fill="#C6C5B8" points="144.45,230.48 107.3,252.15 107.3,240.16 144.73,218.16 									"/>
								</g>
								<g>
									
										<linearGradient id="SVGID_15_" gradientUnits="userSpaceOnUse" x1="-1549.0205" y1="564.4875" x2="-1547.8513" y2="566.5161" gradientTransform="matrix(-1 0 0 1 -1408.5308 -382.4255)">
                                        <stop  offset="0.16" style={{stopColor:"#B7B7B7"}} />
										<stop  offset="0.32" style={{stopColor:"#DDDDDD"}}/>
										<stop  offset="0.54" style={{stopColor:"#B9B9B9"}} />
										<stop  offset="0.73"  style={{stopColor:"#ADADAD"}}/>
							
									</linearGradient>
									<path fill="url(#SVGID_15_)" d="M137.89,182.62c-0.03,0.2,0.04,0.4,0.2,0.52l3,2v-2.54l-1.8-1.2c0,0,0,0-0.06,0l0,0
										c-0.05,0-0.09,0-0.14,0c-0.11,0.01-0.22,0.04-0.32,0.09C138.31,181.71,137.99,182.12,137.89,182.62z"/>
									
										<linearGradient id="SVGID_16_" gradientUnits="userSpaceOnUse" x1="-1549.0226" y1="550.7388" x2="-1547.8527" y2="552.7586" gradientTransform="matrix(-1 0 0 1 -1408.5308 -382.4255)">
                                        <stop  offset="0.16" style={{stopColor:"#B7B7B7"}} />
										<stop  offset="0.32" style={{stopColor:"#DDDDDD"}}/>
										<stop  offset="0.54" style={{stopColor:"#B9B9B9"}} />
										<stop  offset="0.73"  style={{stopColor:"#ADADAD"}}/>


             


									</linearGradient>
									<path fill="url(#SVGID_16_)" d="M137.89,168.88c-0.03,0.19,0.04,0.38,0.2,0.5l3,2v-2.54l-1.8-1.2h-0.06l0,0
										c-0.05-0.01-0.09-0.01-0.14,0c-0.11,0.01-0.22,0.04-0.32,0.09C138.31,167.95,137.99,168.38,137.89,168.88z"/>
									<g>
										
											<linearGradient id="SVGID_17_" gradientUnits="userSpaceOnUse" x1="-699.2147" y1="558.2213" x2="-696.9147" y2="558.2213" gradientTransform="matrix(1 0 0 1 839.15 -382.4255)">
                                            <stop  offset="0.16" style={{stopColor:"#B7B7B7"}} />
										<stop  offset="0.32" style={{stopColor:"#DDDDDD"}}/>
										<stop  offset="0.54" style={{stopColor:"#B9B9B9"}} />
										<stop  offset="0.73"  style={{stopColor:"#ADADAD"}}/>
										</linearGradient>
										<path fill="url(#SVGID_17_)" d="M142.24,186.37v-21.82l-2.3,0.11v21.68c-0.01,0.03-0.01,0.06,0,0.09l0,0l0,0
											c0.05,0.17,0.16,0.32,0.32,0.4c0.51,0.27,1.13,0.27,1.64,0c0.19-0.09,0.32-0.27,0.34-0.48V186.37z"/>
										<path fill="#DADADA" d="M141.95,164.11c-0.51-0.26-1.12-0.26-1.63,0c-0.46,0.26-0.46,0.68,0,0.94
											c0.51,0.26,1.12,0.26,1.63,0C142.35,164.79,142.36,164.37,141.95,164.11z"/>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path fill="#B4CAED" d="M95.31,119.66l-1,1.81c0.18,0.82,0.27,1.65,0.26,2.49c0,6.32-4.43,14-9.91,17.16
				c-0.72,0.42-1.49,0.76-2.29,1l-1,1.8l-3.2-1.85c-1.79-1-2.9-3.25-2.9-6.41c0-6.32,4.44-14,9.91-17.17c2.74-1.58,5.22-1.73,7-0.69
				L95.31,119.66z"/>
			<path fill="#FFFFFF" d="M88.3,120.36c-5.47,3.14-9.91,10.84-9.91,17.14s4.44,8.89,9.91,5.73s9.91-10.85,9.91-17.17
				C98.21,119.74,93.77,117.2,88.3,120.36z"/>
		</g>
		<g>
			<path fill="#383838" d="M94.07,122.44l0.85,0.49l-0.08,0.12c1.07,1.22,1.62,2.83,1.5,4.45c-0.29,5.47-3.18,10.47-7.77,13.46
				c-1.34,0.9-2.99,1.23-4.58,0.91L83.92,142l-0.85-0.5l0,0c-1.4-0.82-2.27-2.56-2.27-5c0.3-5.47,3.18-10.47,7.77-13.47
				C90.72,121.74,92.66,121.63,94.07,122.44z"/>
			<path fill="#808080" d="M89.42,123.5c-4.59,2.99-7.48,8-7.78,13.47c0,5,3.49,7,7.78,4.48c4.59-2.99,7.48-7.99,7.77-13.46
				C97.19,123,93.71,121,89.42,123.5z"/>
		</g>
		<path fill="#295E87" d="M89.38,123.95c-4.34,2.82-7.06,7.54-7.34,12.71c0,4.68,3.28,6.58,7.34,4.24
			c4.33-2.83,7.06-7.55,7.34-12.71C96.72,123.5,93.43,121.61,89.38,123.95z"/>
		<path fill="#383838" d="M89.44,124.29c-4.16,2.71-6.79,7.25-7.07,12.21c0,4.51,3.16,6.33,7.06,4.08
			c4.18-2.72,6.81-7.26,7.08-12.24C96.5,123.87,93.34,122.04,89.44,124.29z"/>
		<path fill="#808080" d="M90.5,126.15c-3.54,2.31-5.77,6.16-6,10.38c0,3.83,2.69,5.38,6,3.47c3.54-2.31,5.77-6.17,6-10.39
			C96.49,125.79,93.81,124.24,90.5,126.15z"/>
		<g>
			<path fill="#575757" d="M93.27,128.27c0,0-6.3,2.93-5.54,9.6c2.65,1.53,6.04,0.62,7.57-2.03
				C96.83,133.19,95.92,129.8,93.27,128.27L93.27,128.27z"/>
			<path fill="#575757" d="M90.5,128.55c-2.32,1.5-3.77,4.02-3.92,6.78c0,2.51,1.75,3.52,3.92,2.27c2.31-1.51,3.77-4.03,3.92-6.79
				C94.42,128.31,92.66,127.3,90.5,128.55z"/>
		</g>
		<g>
			<path fill="#383838" d="M90.64,136.06c0.03-1.74,0.89-3.37,2.31-4.38c0.49-0.38,1.14-0.48,1.71-0.25l0,0l1.53,0.8l-0.17,0.3
				c0.11,0.27,0.17,0.56,0.17,0.85c-0.02,1.74-0.87,3.37-2.29,4.38c-0.3,0.2-0.64,0.32-1,0.37l-0.12,0.2l-1.49-0.78
				C90.85,137.18,90.61,136.63,90.64,136.06z"/>
			<path fill="#666666" d="M92.05,136.79c0.06,1.57,1.2,2.17,2.53,1.33c1.41-1.01,2.26-2.64,2.28-4.38
				c-0.06-1.58-1.19-2.17-2.51-1.33C92.92,133.42,92.07,135.04,92.05,136.79z"/>
			<path fill="#050505" d="M96.46,134.13c-0.03,1.38-0.7,2.67-1.82,3.48c-0.7,0.44-1.33,0.38-1.69-0.07c0.36,0.01,0.71-0.09,1-0.3
				c1.13-0.8,1.81-2.09,1.83-3.48c0-0.36-0.1-0.71-0.31-1C96.04,132.82,96.43,133.31,96.46,134.13z"/>
			<path fill="#003552" d="M95.8,133.76c-0.02,1.39-0.71,2.69-1.85,3.48c-0.29,0.21-0.64,0.31-1,0.3c-0.22-0.29-0.34-0.64-0.33-1
				c0.02-1.39,0.7-2.69,1.84-3.49c0.29-0.21,0.64-0.32,1-0.3C95.67,133.04,95.79,133.39,95.8,133.76z"/>
			<g>
				<path fill="#EB5C44" d="M93.95,134.25c0.36-0.23,0.66-0.07,0.68,0.36c-0.01,0.46-0.23,0.9-0.61,1.17
					c-0.36,0.23-0.67,0.07-0.68-0.36C93.34,134.95,93.56,134.51,93.95,134.25z"/>
				<path fill="#EB5C44" d="M93.95,133.66c-0.09,0.06-0.16,0-0.2,0c0.12-0.12,0.25-0.24,0.38-0.35l0,0
					C94.12,133.45,94.06,133.57,93.95,133.66z"/>
				<path fill="#EB5C44" d="M94.1,136.5c0.11-0.07,0.2,0,0.21,0.11c0,0.14-0.07,0.28-0.19,0.36c-0.11,0.07-0.2,0-0.21-0.11
					C93.91,136.71,93.98,136.58,94.1,136.5z"/>
				<path fill="#EB5C44" d="M93.05,134.75c0.05-0.11,0.11-0.22,0.17-0.33c0,0.06-0.02,0.12-0.05,0.18
					C93.13,134.65,93.09,134.7,93.05,134.75z"/>
				<path fill="#EB5C44" d="M95.2,135.34c0.08,0,0.09,0.19,0,0.33s-0.19,0.22-0.28,0.18c-0.09-0.04-0.09-0.19,0-0.33
					C95.01,135.38,95.12,135.29,95.2,135.34z"/>
				<path fill="#EB5C44" d="M92.87,135.81c-0.01,0.14-0.08,0.27-0.19,0.36l0,0c0.01-0.17,0.04-0.34,0.08-0.51
					C92.8,135.65,92.86,135.7,92.87,135.81z"/>
				<path fill="#EB5C44" d="M95.41,133.95c0.11-0.07,0.2,0,0.21,0.11c0,0.14-0.07,0.28-0.19,0.36c-0.11,0.07-0.2,0-0.21-0.11
					C95.22,134.16,95.29,134.03,95.41,133.95z"/>
				<path fill="#EB5C44" d="M93.25,136.58c0.08,0,0.09,0.18,0,0.33c-0.09,0.15-0.2,0.21-0.28,0.17c-0.08-0.04-0.08-0.19,0-0.33
					S93.17,136.5,93.25,136.58z"/>
				<path fill="#EB5C44" d="M95.11,133.03c0.07,0,0.08,0.2,0,0.33s-0.2,0.22-0.28,0.18c-0.08-0.04-0.09-0.19,0-0.33
					S95.02,132.99,95.11,133.03z"/>
			</g>
		</g>
		<g>
			
				<linearGradient id="laserPrt1_1_" gradientUnits="userSpaceOnUse" x1="-1932.8311" y1="-243.7179" x2="-1860.9711" y2="-243.7179" gradientTransform="matrix(-0.74 -0.67 -0.67 0.74 -1435.6317 -944.5813)">
                <stop  offset="0"  style={{"stopColor":"#E31B25","stop-opacity":0}}/>
			<stop  offset="0.1"  style={{"stopColor":"#E31B25","stop-opacity":0.03}}/>
			<stop  offset="0.23"  style={{"stopColor":"#E31B25","stop-opacity":0.12}}/>
			<stop  offset="0.4"  style={{"stopColor":"#E31B25","stop-opacity":0.26}} />
			<stop  offset="0.58"  style={{"stopColor":"#E31B25","stop-opacity":0.45}}/>
			<stop  offset="0.79" style={{"stopColor":"#E31B25","stop-opacity":0.7}} />
			<stop  offset="1"style={{"stopColor":"#E31B25"}} />
			</linearGradient>
			<path id="laserPrt1" fill="url(#laserPrt1_1_)" d="M95.68,133.21l70.84,19.43l-13,22l-60.28-38c-0.43-0.7-0.43-1.57,0-2.27
				c0.06-0.13,0.13-0.25,0.2-0.37C93.91,133.25,94.84,132.93,95.68,133.21z"/>
			<g id="linesPrt1">
				<path fill="#FFFFFF" d="M157.02,163.5c-0.05,0.01-0.1,0.01-0.15,0l-1.37-0.62c-0.19-0.08-0.27-0.3-0.19-0.49l0,0
					c0.09-0.19,0.31-0.27,0.5-0.19l1.37,0.62c0.19,0.08,0.28,0.3,0.2,0.49c-0.06,0.14-0.2,0.23-0.36,0.22V163.5z"/>
				<path fill="#FFFFFF" d="M152.83,161.62c-0.05,0.01-0.1,0.01-0.15,0l-2.82-1.28c-0.18-0.07-0.27-0.28-0.2-0.47
					c0-0.01,0.01-0.01,0.01-0.02c0.08-0.19,0.3-0.27,0.49-0.19l0,0l2.79,1.25c0.18,0.08,0.27,0.29,0.19,0.47
					c0,0.01-0.01,0.01-0.01,0.02C153.07,161.52,152.96,161.6,152.83,161.62z"/>
				<path fill="#FFFFFF" d="M147.19,159.07c-0.05,0.01-0.11,0.01-0.16,0l-2.82-1.27c-0.2-0.07-0.31-0.28-0.24-0.48
					s0.28-0.31,0.48-0.24c0.02,0.01,0.05,0.02,0.07,0.03l2.82,1.28c0.19,0.08,0.27,0.3,0.19,0.49c-0.06,0.14-0.19,0.23-0.34,0.22
					V159.07z"/>
				<path fill="#FFFFFF" d="M141.54,156.5c-0.05,0.01-0.1,0.01-0.15,0l-2.82-1.27c-0.19-0.09-0.27-0.31-0.19-0.5
					c0.09-0.19,0.31-0.27,0.5-0.19l2.82,1.28c0.19,0.08,0.28,0.3,0.2,0.49c-0.06,0.14-0.2,0.23-0.36,0.22V156.5z"/>
				<path fill="#FFFFFF" d="M135.95,153.98c-0.05,0.01-0.11,0.01-0.16,0l-2.84-1.31c-0.2-0.07-0.31-0.28-0.24-0.48
					s0.28-0.31,0.48-0.24c0.02,0.01,0.05,0.02,0.07,0.03l2.82,1.28c0.19,0.08,0.28,0.3,0.2,0.48c0,0.01,0,0.01-0.01,0.02
					C136.21,153.88,136.08,153.97,135.95,153.98z"/>
				<path fill="#FFFFFF" d="M130.3,151.43c-0.05,0.01-0.1,0.01-0.15,0l-2.82-1.27c-0.19-0.09-0.27-0.31-0.19-0.5
					c0.09-0.19,0.31-0.27,0.5-0.19l2.82,1.28c0.19,0.09,0.27,0.31,0.19,0.5c-0.09,0.13-0.24,0.2-0.4,0.18L130.3,151.43L130.3,151.43
					z"/>
				<path fill="#FFFFFF" d="M124.66,148.88c-0.05,0.01-0.1,0.01-0.15,0l-2.83-1.27c-0.19-0.08-0.27-0.3-0.18-0.49v-0.01
					c0.09-0.18,0.3-0.27,0.49-0.18l2.82,1.27c0.19,0.09,0.27,0.31,0.19,0.5c-0.08,0.14-0.23,0.21-0.39,0.18H124.66z"/>
				<path fill="#FFFFFF" d="M118.95,146.33c-0.05,0.01-0.11,0.01-0.16,0l-2.84-1.31c-0.19-0.09-0.27-0.31-0.18-0.5
					s0.31-0.27,0.49-0.18l2.82,1.27c0.19,0.09,0.27,0.31,0.19,0.5C119.21,146.24,119.09,146.32,118.95,146.33z"/>
				<path fill="#FFFFFF" d="M113.3,143.78c-0.05,0.01-0.1,0.01-0.15,0l-2.81-1.28c-0.18-0.09-0.27-0.3-0.18-0.49
					c0.08-0.19,0.3-0.27,0.49-0.19l0,0l2.83,1.27c0.19,0.09,0.27,0.31,0.18,0.5c-0.07,0.12-0.2,0.2-0.34,0.19H113.3z"/>
				<path fill="#FFFFFF" d="M107.66,141.23c-0.05,0.01-0.11,0.01-0.16,0l-2.82-1.28c-0.19-0.09-0.27-0.31-0.18-0.49
					s0.31-0.27,0.5-0.18l2.84,1.23c0.19,0.09,0.27,0.31,0.19,0.5c-0.06,0.14-0.19,0.23-0.34,0.23L107.66,141.23z"/>
				<path fill="#FFFFFF" d="M102.01,138.68c-0.05,0.01-0.1,0.01-0.15,0l-2.82-1.28c-0.18-0.07-0.27-0.28-0.2-0.47
					c0-0.01,0.01-0.01,0.01-0.02c0.08-0.19,0.3-0.27,0.49-0.19l0,0l2.83,1.27c0.19,0.08,0.27,0.3,0.18,0.49v0.01
					c-0.06,0.12-0.19,0.19-0.32,0.19H102.01z"/>
				<path fill="#FFFFFF" d="M96.39,136.13c-0.05,0.01-0.11,0.01-0.16,0l-1.36-0.63c-0.19-0.08-0.27-0.3-0.19-0.49l0,0
					c0.08-0.19,0.3-0.28,0.48-0.2c0.01,0,0.01,0,0.02,0.01l1.36,0.62c0.19,0.08,0.27,0.3,0.19,0.49c-0.06,0.14-0.19,0.23-0.34,0.22
					V136.13z"/>
			</g>
		</g>
		<path opacity="0.6" fill="#517FA2" enable-background="new    " d="M94.57,123.43c4.97,2.87,6.68,9.22,3.81,14.19
			s-9.22,6.68-14.19,3.81l0,0c-1.33-0.76-2.15-2.41-2.15-4.75c0.28-5.17,3-9.89,7.34-12.71c2-1.17,3.86-1.28,5.19-0.52
			C94.57,123.45,94.57,123.43,94.57,123.43z"/>
		<path opacity="0.3" fill="#FFFFFF" enable-background="new    " d="M95.58,128.56c-0.15,1.27,0.31,2.54,1.25,3.41
			c0.89,0.75,1.78,0.42,2-0.72c0.15-1.27-0.31-2.54-1.25-3.41C96.66,127.09,95.78,127.42,95.58,128.56z"/>
	</g>
	<g>
		<defs>
			<polygon id="SVGID_18_" points="287,347.26 31.17,347.26 107.3,253.55 107.3,109.39 181.77,66.06 252.79,205.3 			"/>
		</defs>
		<clipPath id="SVGID_19_">
			<use xlinkHref="#SVGID_18_"  overflow="visible"/>
		</clipPath>
		<g clip-path="url(#SVGID_19_)">
			<g id="manPrt1">
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#F2B387" d="M213.85,185.63c0,1.2-0.89,2.73-1.16,3.9
					c-0.25,0.57-0.25,1.22,0,1.79c0.38,1.05,0.38,2.21,0,3.26c-0.22,1.38-0.07,2.79,0.42,4.09c0.29,1,0,1.75,0.93,2.39
					c0.63,0.43,1.94,0.94,2.45,1.21c1.63,0.84,3.61-0.21,5.35-0.8c2.35-0.79,2-5,2.76-6.89c1.36-3.5,0.43-8.44-3.42-10
					c-2.02-0.8-4.25-0.95-6.36-0.41c-0.61,0.24-1,1.47-1,1.47L213.85,185.63z"/>
				<path fill="#896363" d="M213.84,200.15c0,0,1.09,0.53,1,1.11c0,0-1.8,0.14-1.69-2.8L213.84,200.15z"/>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#F2B387" d="M215.82,200.78c0,0,0,2.41,0,4.69c0,0.68,0-0.25,1.91,0.56
					c3,1.3,7,1.37,7.35,0.43c0-1.45,0-3.23,0-8.23C225.08,196.46,215.81,200.78,215.82,200.78L215.82,200.78z"/>
				<path fill-rule="evenodd" clip-rule="evenodd" fill="#F2B387" d="M219.52,195.51c-0.93,1-1.64,2-2.09,1.64
					c-0.57-0.9-0.42-2.08,0.35-2.81c1-1,1.65-2.09,2.23-1.89C221.09,192.85,220.46,194.45,219.52,195.51z"/>
				<path fill="#896363" d="M213.74,187.96c-0.17-0.72-0.26-1.46-0.29-2.2c-0.84-1.54,0.92-4.6,3.2-4c2.33,0.16,3.71-2.06,8.66,0.43
					c3.39,1.7,2.93,3,3.43,3.88c3.61,4-2.94,13-2.94,13s-1.8,1.7-5.6,1c-0.46-0.23-1.83,0.26-2.5-2.85c0.17-0.7,1.23-4.53-1.18-5.09
					c-0.61-0.1-1.48,2.55-1.48,2.55c0.19-0.54,0.25-1.11,0.17-1.68c-0.14-0.58-0.19-1.18-0.15-1.78c-0.27-0.78-0.62-1.53-1.05-2.23
					c-0.1-0.24-0.17-0.49-0.21-0.75c0.93-0.71,8,0.82,8.41,1.56c0.44,0.76,0.78,1.57,1,2.42c-0.23-0.86-0.76-1.6-1-2.45
					c-0.34-0.83-0.92-1.55-1.66-2.06c-0.51-0.4-1.09-0.7-1.71-0.88c-0.61-0.2-1.25-0.26-1.89-0.19c-1.18,0.16-2.3,0.61-3.26,1.31
					L213.74,187.96z"/>
				<path fill="#B08080" d="M223.42,192.62L223.42,192.62c-0.24-1.23-0.77-2.39-1.55-3.38c-0.76-1.16-1.98-1.95-3.35-2.15
					c-1.64-0.34-3.34,0.07-4.64,1.13c0,0-0.14,0.13,0.14,1c0.26,0.7,0.59,1.37,1,2c0,0-0.37,3,0,3.43s0.67,0.39,0.67,0.39l0.5-0.25
					l-0.1-0.17l1.61,2.72c0.04,0.34,0.12,0.68,0.23,1l0,0v0.09c0.13,0.37,0.33,0.71,0.59,1c0.54,0.87,0.84,1,0.84,1
					c2.23,0.62,4.62,0.07,6.35-1.47c2.05-2.22,3.7-6.91,3.7-6.91c-1.91,3.89-6,0.57-6,0.57H223.42z"/>
				<path fill="#F2B387" d="M214.92,194.98c0,0,1.39-3.3,2-2s0.26,2.64-0.12,3.94s-1.91-2-1.91-2L214.92,194.98z"/>
				<g id="legs">
					<g id="legMan1R">
						<g id="legMan1RPred">
							<path fill="#5B230D" d="M235.88,306.09c0,0,0.69,4.01,0.72,4.18c-1.07,1.21-2.62,1.8-4.25,1.62
								c-1.32-0.24-2.44-1.3-2.83-2.68c-0.24-0.39-1.68-0.8-3.3-2.25c-1.41-1.22-2.39-2.91-2.76-4.75
								c-0.03-0.31,0.02-0.61,0.14-0.87c0.58-1.26,4.32,0.74,4.7,0.97l0.15,0.47c1.99,2.03,2.45,2.89,5.09,3.63
								c0.83,0.01,1.57-0.17,2.37-0.33L235.88,306.09z"/>
							<path fill="#2B4057" d="M236.62,286.15v-0.01c-0.08-3.89-0.9-7.74-2.43-11.32l0.05-1.07c-4.04-1.58-9.36-0.41-10.07,3.51
								l3.84,23.32l0.25,2.91c0.82,2.36,4.62,4.25,7,3.47c0.86,0.09,0.43-0.15,0.91-1.02C236.17,305.95,236.74,292.61,236.62,286.15
								z"/>
						</g>
						<path fill="#2B4057" d="M235.2,277.39l-0.95-25.69l-7.63-7.57l-6.15,6.66c0,4.3,2.93,14.41,2.78,22.21l0.91,4.5
							C225.71,280.99,235.41,283.15,235.2,277.39z"/>
					</g>
					<g id="legMan1L">
						<g id="legMan1LPred">
							<path fill="#5B230D" d="M223.71,311.51c0,0,0.14,3.08,0.16,3.32c-0.71,1.4-2.22,2.4-3.78,2.6c-2.05,0.08-3.29-2.21-3.29-2.21
								c-2.28-0.01-4.52-0.64-6.47-1.84c-3.22-1.99-4.31-2.82-3.77-4.41s7.88-0.33,7.88-0.33c1.58,1.52,3.5,2.64,5.6,3.28
								c1.22,0.34,2.31,0.02,3.33-0.74L223.71,311.51z"/>
							<path fill="#456584" d="M212.74,280.54c0.68,3.45,1.15,6.42,1.29,9.67c0.27,6.28,0.79,18.74,0.79,18.74
								c1.42,1.67,3.22,2.98,5.24,3.83c1.18,0.46,2.51,0.35,3.6-0.3c0,0,0.44-6,0.7-11.67c0.15-3.17,0.25-6.25,0.19-8.21l-0.03-0.04
								c-0.19-5.48-0.8-7.87-1.51-9.11c0,0-0.09-1.83-0.03-4.64C219.64,276.91,215.58,277.68,212.74,280.54z"/>
						</g>
						<path fill="#456584" d="M223.33,284.03c0.03-1.65-0.28-8.87-0.23-11.04c0.09-4.58,2.38-13.21,2.46-17.87
							c0.06-3.27-2.08-3.02-2.06-4.79l-4.72,0.68l-0.34,0.05l-8.8,1.27c-0.71,5.58-0.5,11.25,0.64,16.76
							c0.9,4.64,1.71,8.25,2.34,11.45C214.73,286.71,223.42,292.7,223.33,284.03z"/>
					</g>
				</g>
				<path id="rightHandPrt1" fill="#456584" d="M233.13,204.39c-0.93-1.96-3.27-2.8-5.23-1.87c-0.86,0.41-1.55,1.12-1.93,1.99
					c-0.59,1.51-0.55,3.2,0.11,4.68c2.24,6.23,4.03,12.61,5.34,19.1c0.09,1.3-5.58,17.72-5.58,17.72s4.69,3.4,5.36,2.49
					s8.22-17.56,7.9-20.44c-0.22-1.94-3.65-18.84-6-23.67L233.13,204.39L233.13,204.39z"/>
				<path fill="#E9E5D9" d="M225.8,201.67c-0.3-0.53-0.78,0-0.78,0c0.08,0.64-0.41,1.66-2.33,2.25c-3.53,0.83-6.35,0.09-7.09,0.34
					c-0.38,0.13-0.87,2-0.85,2.52c0,0,2.39,2.86,7.9,1.29c2.28-0.64,3.13-1.91,3.34-3.18c0.29-0.95,0.24-1.96-0.13-2.88
					L225.8,201.67z"/>
				<path fill="#0C163F" d="M225.14,260.68c-0.1,0-5.91,0.79-10-0.1c-2.3-0.44-4.35-1.75-5.72-3.66v-2.07l12.12,2l2.12,0.2
					L225.14,260.68z"/>
				<path fill="#354F6B" d="M234.88,210.28c-0.78-5.43-4.14-9-9.51-7.81c0,0,2.66,2.7-2.49,4.66c-3.06,1.16-8,0.17-8,0.17
					s-1,0.59-1.93,1.21c-2.13,1.42-6.22,3.78-5.62,9.48c0.83,7.78,4,10.73,3.69,15.94s-2.12,22.63-2.12,22.63
					c1.2,1.37,2.7,2.43,4.38,3.11c1.9,0.77,3.97,1.04,6,0.78c6.63-0.8,7.25-1.82,7.25-1.82l0.17-14.09l0.8,13.83
					c0,0,6.45-4.46,6.94-5.39s-1.11-12.07-2.17-17.55s3.39-19.74,2.63-25.15L234.88,210.28z"/>
				<g id="leftHandPrt1">
					<path fill="#F0B387" d="M202.85,253.73c-0.16,0.12-3.57,3.39-3.6,4.73s-0.89,6.82,1.82,5.65s2-4.56,2.77-5.19s1.59-0.89,2-1.65
						c0.41-0.76,0.25-1.83,0.48-2.53L202.85,253.73z"/>
					<path fill="#456584" d="M216.94,215.24c-0.33-3.34-6.52-5.23-8.62-2.6c-0.99,1.28-1.43,2.9-1.23,4.51
						c0.55,6.23,0.57,18.78,0.28,20c-0.29,1.22-5.85,16.94-5.85,16.94s4.64,3,5.54,2.37s8-14.16,8.55-17
						c0.35-1.92,2.43-13.35,1.34-24.24L216.94,215.24z"/>
				</g>
			</g>
		</g>
	</g>
	<g id="textPrt1">
		<path d="M59.95,112.28v-9.3l1.23-0.7v7.86l3.1-1.77v1.44L59.95,112.28z"/>
		<path d="M65.05,106c-0.02-2,0.96-3.87,2.61-5c1.63-0.93,2.62,0.11,2.62,2c0.01,2-0.97,3.87-2.62,5
			C66.05,108.97,65.05,107.94,65.05,106z M69.13,103.67c0-1.18-0.52-1.93-1.47-1.39c-0.97,0.72-1.52,1.87-1.47,3.07
			c0,1.2,0.53,1.94,1.47,1.41C68.63,106.04,69.18,104.88,69.13,103.67z"/>
		<path d="M71.08,102.57c-0.02-2,0.96-3.87,2.61-5c1.64-0.93,2.63,0.11,2.63,2c0.01,2-0.97,3.88-2.63,5
			C72.08,105.5,71.08,104.5,71.08,102.57z M75.17,100.24c0-1.18-0.53-1.93-1.48-1.39c-0.97,0.71-1.51,1.86-1.46,3.06
			c0,1.2,0.52,2,1.46,1.41C74.66,102.6,75.22,101.45,75.17,100.24z"/>
		<path d="M80.8,100.4l-1.56-1.9l-0.71,1.36v1.81l-1.1,0.64v-9.3l1.1-0.63v5.81l2.25-4.52l1.37-0.78l-2.12,4.25l2.16,2.45
			L80.8,100.4z"/>
		<path d="M85.53,89.25c0.01-0.52,0.27-1,0.69-1.29c0.38-0.22,0.68,0,0.68,0.51c-0.01,0.52-0.26,1-0.68,1.3
			C85.85,89.98,85.53,89.75,85.53,89.25z M85.67,97.63V90.9l1.11-0.63V97L85.67,97.63z"/>
		<path d="M91.64,94.23v-4.21c0-1-0.39-1.16-1-0.81c-0.59,0.41-1.04,0.98-1.3,1.65v4.7l-1.11,0.63V89.5l1.11-0.63v0.91
			c0.38-0.86,1.01-1.59,1.79-2.1c1.09-0.62,1.64-0.16,1.64,1.22v4.7L91.64,94.23z"/>
		<path d="M94.4,91.1v-3.89l-0.84,0.48v-1.28l0.84-0.48v-1.84l1.11-0.59v1.8l1-0.59V86l-1,0.58v3.53c0,0.46,0.17,0.7,0.48,0.52
			c0.21-0.13,0.38-0.31,0.5-0.52l0.26,1c-0.24,0.42-0.58,0.76-1,1C94.85,92.57,94.4,92.22,94.4,91.1z"/>
		<path d="M97.22,87.68c-0.01-2,0.97-3.87,2.62-5c1.63-0.93,2.62,0.11,2.62,2c0.01,2-0.97,3.87-2.62,5
			C98.22,90.65,97.22,89.62,97.22,87.68z M101.31,85.36c0-1.19-0.53-1.93-1.47-1.4c-0.97,0.72-1.52,1.87-1.47,3.07
			c0,1.2,0.53,1.94,1.47,1.41C100.81,87.72,101.36,86.56,101.31,85.36z"/>
		<path d="M106.5,84.21v-3.88l-0.85,0.48V79.5l0.85-0.47v-1.82l1.1-0.64v1.84l1-0.58v1.28l-1,0.59v3.52c0,0.46,0.17,0.7,0.49,0.52
			c0.21-0.13,0.38-0.31,0.49-0.52l0.27,1c-0.24,0.42-0.58,0.76-1,1C106.95,85.68,106.5,85.33,106.5,84.21z"/>
		<path d="M113.08,82.03v-4.24c0-1-0.41-1.13-1-0.78c-0.59,0.41-1.05,0.98-1.31,1.65v4.7l-1.11,0.63v-9.3l1.11-0.63v3.49
			c0.39-0.86,1.01-1.59,1.8-2.11c1.1-0.63,1.65-0.19,1.65,1.19v4.77L113.08,82.03z"/>
		<path d="M115.3,77.39c-0.02-1.99,0.94-3.86,2.58-5c1.53-0.87,2.51,0.12,2.51,2.23v0.35l-3.93,2.24c0.08,1,0.66,1.48,1.62,0.93
			c0.61-0.38,1.11-0.92,1.44-1.56l0.51,0.67c-0.48,0.9-1.2,1.65-2.08,2.16C116.44,80.28,115.3,79.5,115.3,77.39z M117.88,73.6
			c-0.86,0.59-1.39,1.55-1.43,2.59l2.86-1.63C119.29,73.78,118.88,73.02,117.88,73.6z"/>
		<path d="M123.87,72.5c-0.01-2,0.97-3.88,2.63-5c1-0.57,1.6-0.35,2,0.09l-0.72,1.3c-0.27-0.36-0.78-0.43-1.13-0.16
			c-0.01,0.01-0.02,0.02-0.04,0.03c-1.01,0.71-1.59,1.88-1.54,3.11c0,1.31,0.61,1.89,1.54,1.36c0.53-0.35,0.94-0.85,1.17-1.45
			l0.72,0.48c-0.39,0.97-1.09,1.78-2,2.3C124.95,75.43,123.87,74.56,123.87,72.5z"/>
		<path d="M132.53,70.96v-0.73c-0.35,0.76-0.93,1.4-1.65,1.83c-0.83,0.47-1.77,0.27-1.77-1.22c0-1.3,0.67-2.51,1.77-3.2
			c0.69-0.39,1.28-0.43,1.65-0.07v-1c0-0.78-0.49-1-1.19-0.58c-0.65,0.42-1.18,1.01-1.51,1.71l-0.46-0.76
			c0.49-0.95,1.23-1.75,2.14-2.3c1.13-0.65,2.12-0.58,2.12,1.23v4.46L132.53,70.96z M132.53,69.42v-1c-0.26-0.32-0.73-0.29-1.22,0
			c-0.65,0.38-1.06,1.07-1.08,1.83c0,0.72,0.46,0.93,1.08,0.58C131.85,70.5,132.28,70.01,132.53,69.42z"/>
		<path d="M141.3,65.96v-4.39c0-0.72-0.24-1.07-0.83-0.74c-0.54,0.39-0.95,0.94-1.18,1.57v4.71l-1.11,0.63v-4.39
			c0-0.73-0.24-1.08-0.84-0.74c-0.54,0.4-0.94,0.95-1.17,1.58v4.69l-1.11,0.62v-6.72l1.11-0.63v0.92c0.35-0.81,0.92-1.5,1.65-2
			c0.76-0.43,1.22-0.19,1.39,0.45c0.33-0.91,0.94-1.7,1.74-2.25c0.94-0.54,1.47-0.17,1.47,1.2v4.86L141.3,65.96z"/>
		<path d="M143.51,61.33c-0.01-1.99,0.96-3.86,2.59-5c1.53-0.87,2.51,0.12,2.51,2.24v0.35l-3.94,2.24c0.09,1,0.67,1.47,1.63,0.93
			c0.61-0.4,1.1-0.95,1.44-1.59l0.51,0.67c-0.48,0.89-1.2,1.63-2.07,2.15C144.65,64.22,143.51,63.5,143.51,61.33z M146.1,57.5
			c-0.86,0.59-1.39,1.56-1.44,2.6l2.87-1.6C147.51,57.72,147.1,56.96,146.1,57.5z"/>
		<path d="M149.69,61.18V54.5l1.11-0.63v1c0.33-0.84,0.91-1.57,1.66-2.08v1.38c-0.12,0.04-0.24,0.09-0.35,0.16
			c-0.58,0.41-1.04,0.98-1.31,1.64v4.58L149.69,61.18z"/>
		<path d="M156.46,57.33v-0.72c-0.35,0.76-0.93,1.39-1.65,1.82c-0.83,0.48-1.77,0.27-1.77-1.22c0-1.3,0.67-2.5,1.77-3.19
			c0.69-0.39,1.28-0.44,1.65-0.07v-1c0-0.78-0.49-1-1.19-0.57c-0.65,0.41-1.18,1-1.51,1.7l-0.45-0.76c0.48-0.95,1.23-1.75,2.14-2.3
			c1.12-0.64,2.11-0.58,2.11,1.23v4.45L156.46,57.33z M156.46,55.8v-1c-0.25-0.31-0.73-0.28-1.21,0c-0.65,0.38-1.07,1.07-1.09,1.82
			c0,0.73,0.46,0.94,1.09,0.59C155.79,56.88,156.21,56.38,156.46,55.8z"/>
	</g>
</g>
</svg>

    </div>
}